import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa6';
import { PiCoinsFill } from 'react-icons/pi';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../assets/gif/buttonLoader.gif';
import { useQuizStore } from '../../store/quiz/quiz';
import { useQuizCategoriesStore } from '../../store/quizCategory/quizCategory';
import { Badge } from '../Badge/Badge';
import { QuizLeaderboard } from '../QuizLeaderboard/QuizLeaderboard';
import { QuizProfileCard } from '../QuizProfileCard/QuizProfileCard';
import { Separator } from '../Separator/Separator';

import CanvasJSReact from '@canvasjs/react-charts';
import { MdLock } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';
import { quizLevelModeConstantMapping } from '../../config/constants/quiz';
import { useAnalyticsStore } from '../../store/analytics/analytics';
import { useAuthStore } from '../../store/auth/auth';
import { useQuizProfileStore } from '../../store/quizProfile/quizProfile';
import { NoDataFoundCard } from '../NoDataFoundCard/NoDataFoundCard';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Quiz = (props) => {
  let { account } = useAuthStore();
  let { quizProfile } = useQuizProfileStore();
  let { quizCategories } = useQuizCategoriesStore();
  let { fetchQuizByCategory, quiz, quizCategory } = useQuizStore();
  let { fetchQuizComparisonByCategory, userPerformance, avgUserPerformance } =
    useAnalyticsStore();

  let [selectedCategory, setSelectedCategory] = useState(null);
  let [loading, setLoading] = useState(false);
  let [hasComparativeAnalysisFound, setHasComparativeAnalysisFound] =
    useState(true);
  let [hasResponsesAnalysisFound, setHasResponsesAnalysisFound] =
    useState(true);

  const [chartOptions, setChartOptions] = useState(null);
  const [pieChartOptions, setPieChartOptions] = useState(null);

  let { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    fetchQuizzes();
  }, []);

  let fetchQuizzes = async () => {
    setLoading(true);
    let { UserPerformance, AvgPerformance } =
      await fetchQuizComparisonByCategory(id);

    if (!AvgPerformance.length || !UserPerformance.length) {
      setHasComparativeAnalysisFound(false);
    }

    const chartOptions = {
      backgroundColor: '#21183C',
      animationEnabled: true,
      height: 260,
      // title:{
      //   text: "Number of New Customers"
      // },
      axisY: {
        // title: "Number of Customers"
        labelFontColor: '#ffffff',
      },
      axisX: {
        // title: "Number of Customers"
        labelFontColor: '#ffffff',
        title: 'Quizzes',
        titleFontColor: '#ffffff',
      },
      toolTip: {
        // shared: true
      },
      legend: {
        fontColor: '#ffffff',
      },
      data: [
        {
          type: 'line',
          name: 'Average Performance',
          color: 'gray',
          showInLegend: true,
          dataPoints: AvgPerformance.map((data) => ({
            y: data.average,
            label: data.level,
          })),
        },
        {
          type: 'line',
          name: 'Your Performance',
          color: '#8254F8',
          showInLegend: true,
          dataPoints: UserPerformance.map((data) => ({
            y: data.average,
            label: data.level,
          })),
        },
      ],
    };

    setChartOptions(chartOptions);
    await fetchQuizByCategory(id);

    if (quizProfile?.CorrectAnswers + quizProfile?.InCorrectAnswers === 0) {
      setHasResponsesAnalysisFound(false);
    }

    const pieChartOptions = {
      animationEnabled: true,
      height: 260,
      backgroundColor: '#21183C', // "light1", "dark1", "dark2"
      axisY: {
        // title: "Number of Customers"
        labelFontColor: '#ffffff',
      },
      legend: {
        fontColor: '#ffffff',
      },
      axisX: {
        // title: "Number of Customers"
        labelFontColor: '#ffffff',
      },
      data: [
        {
          type: 'pie',
          labelColor: '#000000',
          indexLabel: '{label}: {y}%',
          startAngle: -90,
          dataPoints: [
            {
              y: (
                (quizProfile?.CorrectAnswers /
                  (quizProfile?.CorrectAnswers +
                    quizProfile?.InCorrectAnswers)) *
                100
              ).toFixed(2),
              label: 'Correct Answers',
              indexLabelFontColor: '#8254F8',
              color: '#8254F8',
            },
            {
              y: (
                (quizProfile?.InCorrectAnswers /
                  (quizProfile?.CorrectAnswers +
                    quizProfile?.InCorrectAnswers)) *
                100
              ).toFixed(2),
              label: 'Incorrect Answers',
              indexLabelFontColor: 'gray',
              color: 'gray',
            },
          ],
        },
      ],
    };

    setPieChartOptions(pieChartOptions);
    setLoading(false);
  };

  let handleNavigation = (id) => {
    navigate(`/questions/${id}`);
  };

  let handleGoBack = () => {
    navigate('/quick-bites');
  };

  let completedQuiz = () => {
    return `${quiz.filter((data) => data.Report).length}/${quiz.length}`;
  };
  return (
    <div className="max-w-[1280px] mx-auto grid grid-cols-3 lg:grid-cols-4 px-3 sm:px-10 gap-5">
      <div className="hidden md:col-span-1 lg:flex flex-col justify-between">
        <div className="h-[300px]">
          <QuizLeaderboard />
        </div>
        {chartOptions && hasComparativeAnalysisFound && (
          <div className="p-3 rounded-xl bg-[#21183C] mt-2">
            <CanvasJSChart
              className=""
              options={chartOptions}
              /* onRef={ref => this.chart = ref} */
            />
          </div>
        )}
        {!hasComparativeAnalysisFound && (
          <div className="h-[300px]">
            <NoDataFoundCard text="Jump in and start tracking! See how your progress measures up against others!" />
          </div>
        )}
      </div>

      {/* Quizzes levels here */}
      <div className="col-span-3 md:col-span-2 bg-[#21183C] w-full h-[85vh] overflow-hidden rounded-xl flex flex-col shadow-lg">
        {!loading ? (
          <>
            <div className="header p-4 flex justify-start items-end">
              <div>
                <div className="text-lg font-semibold text-white">
                  <span
                    onClick={handleGoBack}
                    className="cursor-pointer hover:text-[#8254F8] transition-colors duration-300"
                  >
                    Categories
                  </span>
                  <span className="text-gray-400">
                    {' '}
                    / {quizCategory?.Category}
                  </span>
                </div>
                <div className="text-xs text-gray-400 mt-1">
                  Assessment: {completedQuiz()}
                </div>
              </div>
            </div>
            <Separator />
            <div className="flex-grow overflow-y-auto scroll-bar py-4 gap-y-4">
              {quiz.map((data) => (
                <div key={data._id} className="px-4 relative ">
                  {account.SubscribedPlanType <= 0 && data.Level > 7 && (
                    <div className="absolute top-1 right-2 cursor-pointer text-white text-xs font-bold py-1 px-2 rounded-full">
                      <div
                        className=""
                        data-tooltip-id="get-premium-encouragement"
                        data-tooltip-content="Subscribe to unlock"
                      >
                        <Tooltip id="get-premium-encouragement" />
                        <MdLock className="text-xl md:text-3xl" />
                      </div>
                    </div>
                  )}
                  <div
                    className={`flex justify-between items-start gap-x-4 ${
                      account.SubscribedPlanType <= 0 && data.Level > 7
                        ? 'filter blur-sm opacity-50'
                        : ''
                    }`}
                  >
                    <div className="text-md font-normal mt-1 text-white">
                      {/* Get Access Tag */}

                      <div className="flex items-start gap-x-2">
                        <span
                          className="cursor-pointer hover:text-[#8254F8] transition-colors duration-300"
                          onClick={
                            account.SubscribedPlanType <= 0 && data.Level > 7
                              ? null
                              : () => handleNavigation(data._id)
                          }
                        >
                          <span className="my-3">{data.Heading}</span>
                        </span>
                        <Badge
                          text={quizLevelModeConstantMapping[data.LevelMode]}
                          classes="w-[10px]"
                        />
                      </div>
                      <div className="hidden sm:block text-xs text-gray-400 mt-1">
                        {data.LearningMaterial.length > 60
                          ? data.LearningMaterial.substring(0, 60) + '...'
                          : data.LearningMaterial}
                      </div>
                    </div>
                    {data.Report ? (
                      <div className="flex flex-col justify-end items-center gap-y-1 text-white">
                        <div className="text-xl font-normal flex justify-end items-center gap-x-2">
                          <span className="font-semibold text-3xl">
                            +{data.Report.NumberOfCorrectAnswers}
                          </span>
                          <PiCoinsFill className="text-yellow-500" />
                        </div>
                      </div>
                    ) : (
                      <>
                        {account.SubscribedPlanType <= 0 &&
                        data.Level > 7 ? null : (
                          <div
                            onClick={() => handleNavigation(data._id)}
                            className="hidden w-[30px] h-[30px] cursor-pointer rounded-full sm:flex justify-center items-center bg-[#8254F8] hover:bg-[#6A00F4] transition-colors duration-300"
                          >
                            <FaArrowRight className="text-white" />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="my-4">
                    <Separator />
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <img src={Loader} />
          </div>
        )}
      </div>
      {/*  */}

      <div className="col-span-3 md:col-span-1 md:flex flex-col justify-between">
        <div className="hidden md:block h-[fit-content]">
          <QuizProfileCard />
        </div>
      </div>
    </div>
  );
};

Quiz.propTypes = {};

export { Quiz };
