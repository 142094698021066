import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { toUpper } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useChargesStore } from '../../store/charges/charges';
import { AddPaymentMethod } from '../PricingSubscription/AddPaymentMethod';

const PaymentMethods = (props) => {
  let { getPaymentMethod, paymentMethodInfo } = useChargesStore();
  const [updatePaymentMethod, setUpdatePaymentMethod] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  let fetchPaymentMethods = async () => {
    await getPaymentMethod();
  };

  let toggleStripeComponent = () => {
    setUpdatePaymentMethod((prev) => !prev);
  };

  const promise = loadStripe(
    'pk_live_51PDrCpRpw1gyVqw5dq6e3u87RFWQKBaP8Wfu5KGk3fX9d9yVgJYIXKVwgvsuF3CTDAfF52KjGNsHrvzGKmcRG9HY00BB66r1Vp'
  );

  const handleRoute = () => {
    navigate('/account/billing/plans');
  };

  return (
    <div className="flex justify-between items-end bg-[#181818] font-semibold p-5 rounded-md">
      <div>
        <span>Payment Methods </span>
        <div className="text-xs text-gray-400 font-light mt-3">
          Manage your default payment methods
        </div>
      </div>

      {paymentMethodInfo ? (
        <>
          {!updatePaymentMethod ? (
            <div className="bg-white bg-opacity-5 backdrop-blur-md flex justify-between items-center rounded-lg border-[1px] border-gray-600 p-2 w-[40%]">
              <div className="">
                <span className="font-light italic">
                  {' '}
                  {toUpper(paymentMethodInfo.brand)} CARD **** **** ****{' '}
                  {paymentMethodInfo.last4}
                </span>
              </div>
              <div className="">
                <button
                  onClick={toggleStripeComponent}
                  className="text-xs px-3 py-2 w-fit bg-gradient-to-r from-[#B310FD] to-[#9D0ECB] text-white rounded transition duration-200"
                >
                  Update
                </button>
              </div>
            </div>
          ) : (
            <div className="bg-white bg-opacity-5 backdrop-blur-md grid grid-cols-1 justify-between items-center rounded-lg border-[1px] border-gray-600 p-2 w-[40%]">
              <Elements stripe={promise}>
                <AddPaymentMethod
                  onComplete={() => setUpdatePaymentMethod(false)}
                  updatePaymentMethod={true}
                  buttonText="Update"
                  buttonClass="col-span-1 text-xs font-semibold rounded py-2 mt-0"
                  cardClass="col-span-4"
                  classes="grid grid-cols-5 gap-x-2"
                />
              </Elements>
            </div>
          )}
        </>
      ) : (
        <button
          onClick={handleRoute}
          className="text-xs px-3 py-2 w-fit bg-gradient-to-r from-[#B310FD] to-[#9D0ECB] text-white rounded transition duration-200"
        >
          Get Premium
        </button>
      )}
    </div>
  );
};

PaymentMethods.propTypes = {};

export { PaymentMethods };
