export const RepoEvaluation = {
  EXTRACTING_FILES: 1,
  ANALYZING_CODE: 2,
  EVALUATING_RESPONSE: 3,
  COMPLETED: 4,
};

export const RepoEvaluationMapping = {
  1: 'Extracting Files',
  2: 'Analyzing Code',
  3: 'Evaluating Response',
  4: 'Completed',
};
