import React, { useState } from 'react';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonLoader from '../../assets/gif/IconButtonLoaderSm.gif';
import { useAuthStore } from '../../store/auth/auth';
import { usePlanStore } from '../../store/plans/plans';

const PlanCard = ({ plan, currency, symbol, countryCode }) => {
  let { planInfo, user, getAccountInfo } = useAuthStore();
  let { createPlanSubscription } = usePlanStore();

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const { pathname } = useLocation();

  let handleRoute = async () => {
    if (plan?.Price) {
      if (pathname === '/account/billing/plans') {
        navigate(`/account/billing/subscription/${plan._id}`);
      } else {
        navigate(`/subscription/${plan._id}`);
      }
    } else {
      await navigateToDashboard();
    }
  };

  let navigateToDashboard = async () => {
    let payload = {
      PlanId: plan._id,
    };
    setLoading(true);
    await createPlanSubscription(payload);
    setLoading(false);
    navigate(`/`);
  };
  return (
    <div className="p-6 flex flex-col justify-between items-start gap-y-4 bg-white bg-opacity-5 backdrop-blur-md border-2 border-[#9D0ECB] text-white rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl h-[100%]">
      {plan?._id == planInfo?._id ? (
        <div className="absolute top-4 right-4 bg-[#9D0ECB] text-white text-xs font-semibold py-1 px-2 rounded-full">
          Current Plan
        </div>
      ) : null}

      <div className="flex flex-col items-start gap-y-4">
        <h4 className="font-bold text-white">{plan.Name}</h4>
        <div className="flex items-end">
          <h3 className="text-4xl font-semibold text-white">
            {symbol}
            {plan.Region[countryCode]?.Price?.toLocaleString() ||
              plan.Price.toLocaleString()}
            /
          </h3>
          <span className="text-lg text-gray-300">
            {plan.Price == 0 ? 'Forever' : plan.Type == 1 ? 'Month' : 'Yearly'}
          </span>
        </div>
        <div className="text-white">
          <span className="font-semibold">Features:</span>
          <div className="flex flex-col gap-y-2 mt-2 text-sm text-gray-300">
            {plan.Features.map((data) => (
              <div className="flex items-start gap-x-2" key={data}>
                <IoMdCheckmarkCircleOutline className="text-lg w-fit" />
                <span className="w-fit">{data}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {plan?._id !== planInfo?._id ? (
        <button
          onClick={handleRoute}
          disabled={loading}
          className={`mt-4 w-full flex justify-center items-center gap-x-2 ${
            loading
              ? 'bg-gradient-to-r from-gray-600 to-gray-400'
              : 'bg-gradient-to-r from-[#B310FD] to-[#9D0ECB]'
          } text-white py-2 rounded-lg transition duration-200`}
        >
          <span> Get Started </span>{' '}
          {loading && <img width={20} src={ButtonLoader} />}
        </button>
      ) : (
        <button
          onClick={handleRoute}
          disabled={loading}
          className={`mt-4 w-full flex justify-center items-center gap-x-2 ${
            loading
              ? 'bg-gradient-to-r from-gray-600 to-gray-400'
              : 'bg-gradient-to-r from-[#B310FD] to-[#9D0ECB]'
          } text-white py-2 rounded-lg transition duration-200`}
        >
          <span> Get Started </span>{' '}
          {loading && <img width={20} src={ButtonLoader} />}
        </button>
      )}
    </div>
  );
};

PlanCard.propTypes = {};

export { PlanCard };
