import React from 'react';
import { BuyToken as PlanComponent } from '../../components/Repositories/BuyToken';

const BuyToken = (props) => {
  return (
    <div>
      <PlanComponent />
    </div>
  );
};

BuyToken.propTypes = {};

export { BuyToken };
