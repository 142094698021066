import React, { useState } from "react";
import { Button } from "../../components/Button/ButtonV2";
import { Input } from "../../components/Input/InputV2";
import PasswordEye from "../../assets/svg/PasswordEye.svg";
import { Error } from "../../config/helpers/toast-helper";
import { useAuthStore } from "../../store/auth/auth";
import { Link, useNavigate } from "react-router-dom";
import { AccountStatusConstants } from "../../config/constants/account";
import AuthUserNameIcon from "../../assets/png/authUserNameIcon.png";
import AuthEmailIcon from "../../assets/png/authUserEmailIconV2.png";
import AuthPasswordIcon from "../../assets/png/authPasswordIconV2.png";

const SignUp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);
    if (!handleDisableButton()) {
      setLoading(false);
      return;
    }
    // if (password !== confirmPassword) {
    //   Error("Passwords do not match");
    //   setLoading(false);
    //   return;
    // }
    let data = {
      FirstName: name,
      Email: email,
      Password: password,
    };
    let { user, account } = await useAuthStore.getState().signUp(data);
    setLoading(false);

    if (!account) return;
    navigate("/auth/account/activation");
    // if (account.Status == AccountStatusConstants.INACTIVE) {
    // } else {
    //   navigate("/");
    // }
  };

  let handleDisableButton = () => {
    if (!name.length || !email.length || !password.length) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <div className="mb-[60px]">
        <div className="text-[32px] font-medium leading-[54px] text-white">
          Start by creating an account
        </div>
        <div className="font-normal text-[18px] leading-[24px] text-white">
          Already have an account?{" "}
          <Link
            to={"/auth/login"}
            className="text-[#9B75F9] hover:underline cursor-pointer"
          >
            Login
          </Link>
        </div>
      </div>
      <form handleSubmit={handleSubmit} class="flex flex-col gap-4">
        <div className="mb-10 flex flex-col gap-4 ">
          <Input
            placeholder={"Name"}
            value={name}
            onChange={(e) => setName(e.target.value)}
            classes={"py-3 text-[16px] text-[#9B75F9] rounded-[10px] py-4"}
            preIcon={AuthUserNameIcon}
          />
          <Input
            placeholder={"Email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type={"email"}
            classes={"py-3 text-[16px] text-[#9B75F9] rounded-[10px] py-4"}
            preIcon={AuthEmailIcon}
          />
          <Input
            placeholder={"Password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? "text" : "password"}
            image={PasswordEye}
            onImageClick={() => setShowPassword(!showPassword)}
            classes={"py-3 text-[16px] text-[#9B75F9] rounded-[10px] py-4"}
            preIcon={AuthPasswordIcon}
          />
        </div>

        <Button
          classes={"text-[18px] font-medium px-7 py-3"}
          isDisabled={loading}
          onClick={handleSubmit}
          fullWidth={true}
          isActive={handleDisableButton()}
          buttonText={"Register Now"}
        />
      </form>
    </div>
  );
};

export default SignUp;
