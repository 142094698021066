import React from 'react';
import RepositoryComponent from '../../components/Repository/Repository';

const Repository = (props) => {
  return (
    <div>
      <RepositoryComponent />
    </div>
  );
};

Repository.propTypes = {};

export { Repository };
