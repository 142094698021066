import moment from 'moment';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePlanStore } from '../../store/plans/plans';

const Invoice = (props) => {
  let { getInvoiceInfoById, selectedInvoice } = usePlanStore();

  let { id } = useParams();

  useEffect(() => {
    getInvoiceInfoById(id);
  }, []);

  return (
    <>
      {selectedInvoice ? (
        <div className="max-w-lg mx-auto p-6 bg-gradient-to-r from-[#1E1E2F] to-[#3E1E5F] shadow-lg rounded-lg text-white">
          <div className="text-center mb-6">
            <h2 className="text-2xl font-bold">Invoice</h2>
            <p className="text-sm">
              Invoice Number: {selectedInvoice._id.slice(-7).toUpperCase()}
            </p>
          </div>

          <div className="flex justify-between mb-6">
            <div>
              <h4 className="text-lg font-bold">Billed To</h4>
              <p className="text-sm">{selectedInvoice.User.User.FirstName}</p>
              <p className="text-sm">{selectedInvoice.User.User.Email}</p>
            </div>
            <div className="text-right">
              <h4 className="text-lg font-bold">Payment Details</h4>
              <p className="text-sm">
                Issue Date:{' '}
                {moment(selectedInvoice.StartDate).format('MMM Do YYYY')}
              </p>
              <p className="text-sm">
                Due Date:{' '}
                {moment(selectedInvoice.DueDate).format('MMM Do YYYY')}
              </p>
            </div>
          </div>

          <div className="border-t border-gray-600 pt-4 mb-6">
            <h4 className="text-lg font-bold mb-4">Plan Summary</h4>
            <div className="flex justify-between items-end">
              <p>{selectedInvoice.PlanInfo.Name}</p>
              <p className="text-lg uppercase">
                {selectedInvoice.Amount.toLocaleString()}{' '}
                {selectedInvoice.Currency || 'USD'}
              </p>
            </div>
            <div className="flex justify-between items-end">
              <p>Duration</p>
              <p className="text-lg">
                {selectedInvoice.PlanInfo.Type == 1 ? 'Month' : 'Year'}
              </p>
            </div>
          </div>

          <div className="border-t border-gray-600 pt-4 mb-6">
            <h4 className="text-lg font-bold mb-4">Total</h4>
            <div className="flex justify-between">
              <p className="font-bold">Total Amount</p>
              <p className="font-bold uppercase">
                {selectedInvoice.Amount.toLocaleString()}{' '}
                {selectedInvoice.Currency || 'USD'}
              </p>
            </div>
          </div>

          <div className="border-t border-gray-600 pt-4 text-center">
            <p className="text-sm">
              Thank you for choosing Discoursefy! For any questions or support,
              please contact us at team@discoursefy.com.
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
};

Invoice.propTypes = {};

export { Invoice };
