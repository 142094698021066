import React, { useState } from 'react';
import { FaRegUser } from 'react-icons/fa6';
import CreatorProfilePicture from '../../assets/png/CreatorPicture.png';

const CreatorProfile = ({
  containerWidth,
  padding,
  creatorName,
  profilePicture,
}) => {
  const [state, setState] = useState();
  return (
    <div
      className={`h-[${containerWidth}px] w-[${containerWidth}px] border-[1px] border-solid border-white] rounded-full absolute -bottom-7 right-2`}
    >
      <div className="w-full h-full flex justify-center items-center">
        {profilePicture ? (
          <img
            style={{ objectFit: 'cover' }}
            className={`rounded-full h-[50px] w-[50px] `}
            src={profilePicture || CreatorProfilePicture}
          />
        ) : (
          <div
            style={{ background: '#7E5DEC' }}
            className={`w-[50px] h-[50px] flex justify-center items-center rounded-full `}
          >
            {/* {creatorName.charAt(0).toUpperCase()} */}
            <FaRegUser className="text-[25px]" />
          </div>
        )}
      </div>
      <div className="relative w-full h-full -mt-[40px]">
        {/* <div className="absolute bottom-1 -right-1 rounded-full h-[18px] w-[18px] bg-[#21183C] flex justify-center items-center">
      <div style={{background: '#08A0F7'}} className="rounded-full flex justify-center items-center w-[15px] h-[15px] bg-blue-900">
        <img className='mt-[1px]' src={Check} />
      </div>
    </div> */}
      </div>
    </div>
  );
};

export { CreatorProfile };
