import { Get, Post, baseUrl } from '..';
import { UserCredentials } from '../../config/helpers/local-storage-accessor-helper';

export const SaveQuizReport = async (body) => {
  try {
    let { Authorization } = UserCredentials();
    return await Post(`${baseUrl}/quizAnswer/create/`, body, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const GetStreakCount = async () => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(`${baseUrl}/quizAnswer/getStreakCount/`, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const GetActivityRecord = async () => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(`${baseUrl}/quizAnswer/activityRecord/`, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const GetNextActivities = async () => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(`${baseUrl}/quizAnswer/nextActivities/`, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};
