import { create } from 'zustand';
import { FetchPlatformUsers, UpdateUsers } from '../../service/user/user';
import { useAuthStore } from '../auth/auth';

export const useUserStore = create((set, get) => ({
  users: [],
  usersLabel: [],

  fetchPlatformUsers: async (inputValue) => {
    try {
      let Users = await FetchPlatformUsers(inputValue);
      let userLabels = Users.map((user) => ({
        label: user.FirstName,
        value: user._id,
        meta: { accountId: user.Account._id },
      }));
      set({ users: Users, usersLabel: userLabels });
      return userLabels;
    } catch (error) {
      console.log(error);
    }
  },

  updateUser: async (id, data) => {
    try {
      let { setUser } = useAuthStore.getState();
      let user = await UpdateUsers(id, data);
      setUser(user);
      return user;
    } catch (error) {
      console.log(error);
    }
  },
}));
