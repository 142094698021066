import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Tooltip } from 'react-tooltip';
import GoogleBard from '../../assets/svg/google-bard.svg';
import { DescriptionTabs } from '../../config/constants/problem';
import { useSolutionStore } from '../../store/solution/solution';

const Description = ({ currentProblem, descriptionTab, setDescriptionTab }) => {
  const {
    solutionResponse,
    resetSolutionResponse,
    aiSuggestion,
    getAISuggestion,
    regenerateAISuggestions,
    fetchingAIFeedback,
  } = useSolutionStore();
  const { id } = useParams();

  useEffect(() => {
    resetSolutionResponse();
  }, [id]);

  const handleAiSuggestion = async () => {
    setDescriptionTab(DescriptionTabs.AI_SUGGESTIONS);
    await getAISuggestion();
  };

  return (
    <div className="w-full min-h-40 bg-[#1B2B34] rounded-md shadow-lg overflow-hidden font-normal text-sm relative text-white">
      <div className="h-[54px] flex justify-between items-center font-semibold bg-gray-700 py-3 px-4">
        <div className="flex items-center space-x-6">
          <span
            onClick={() => setDescriptionTab(DescriptionTabs.DESCRIPTION)}
            className={`cursor-pointer ${
              descriptionTab === DescriptionTabs.DESCRIPTION
                ? 'text-white font-semibold'
                : 'text-gray-500'
            }`}
          >
            Description
          </span>
          {solutionResponse && (
            <span
              onClick={() => setDescriptionTab(DescriptionTabs.SOLUTION)}
              className={`cursor-pointer ${
                descriptionTab === DescriptionTabs.SOLUTION
                  ? 'text-white font-semibold'
                  : 'text-gray-500'
              }`}
            >
              Submission
            </span>
          )}
        </div>
        {solutionResponse && solutionResponse?.FailedTestCases === 0 && (
          <div
            className="tooltip cursor-pointer"
            data-tooltip-id="solution-ai-suggestion-star"
            data-tooltip-content="Get Feedback"
          >
            <Tooltip id="solution-ai-suggestion-star" />
            <img
              onClick={handleAiSuggestion}
              className="w-5 h-5 transform transition-transform duration-200 hover:scale-100 hover:animation-none animate-pulse-slow"
              src={GoogleBard}
              alt="Google Bard"
            />
          </div>
        )}
      </div>

      <div className="px-4 h-[calc(91vh-54px)] pt-3 flex flex-col gap-3 overflow-y-auto scroll-bar">
        {descriptionTab === DescriptionTabs.DESCRIPTION ? (
          <div>
            <h2 className="text-2xl font-semibold">{currentProblem?.Title}</h2>
            <div className="text-xs w-fit rounded-full bg-[#3E1E5F] px-4 py-1 mt-2">
              {currentProblem?.Difficulty}
            </div>
            <div className="mt-4">
              <ReactMarkdown
                className="text-gray-300"
                children={currentProblem?.Description}
              />
            </div>
            {currentProblem?.Examples.map((data, i) => (
              <div key={i} className="mt-4">
                <h3 className="text-lg font-bold text-[#8254F8]">
                  Example {i + 1}
                </h3>
                <div className="bg-[#2A3C4D] p-3 rounded-md text-gray-300 mt-2">
                  <div>
                    <span className="font-semibold">Input:</span>{' '}
                    {data.input.join(', ')}
                  </div>
                  <div>
                    <span className="font-semibold">Output:</span> {data.output}
                  </div>
                  {data.explanation && (
                    <div>
                      <span className="font-semibold">Explanation:</span>{' '}
                      {data.explanation}
                    </div>
                  )}
                </div>
              </div>
            ))}
            <div className="mt-4">
              <h3 className="text-lg font-semibold">Constraints</h3>
              <p className="text-gray-300">{currentProblem?.Constraints}</p>
            </div>
          </div>
        ) : descriptionTab === DescriptionTabs.SOLUTION ? (
          <div>
            <div className="flex flex-col gap-x-3 items-start m-3">
              <div
                className={`text-xl font-semibold ${
                  solutionResponse.FailedTestCases === 0
                    ? 'text-green-500'
                    : 'text-red-500'
                }`}
              >
                {solutionResponse.FailedTestCases === 0
                  ? 'Accepted'
                  : 'Rejected'}
              </div>
              <div className="flex gap-x-3 justify-start items-center">
                <span className="text-gray-400">
                  Runtime: {solutionResponse?.Time.toFixed(2)} ms
                </span>
                <span className="text-gray-400">
                  Memory: {solutionResponse?.Memory.toFixed(2)} KB
                </span>
              </div>
            </div>
            <div className="my-5 grid grid-cols-1 gap-4">
              <SolutionStat
                title="Runtime"
                value={`${solutionResponse.Time.toFixed(2)} ms`}
                percentage={solutionResponse.comparison.betterTimePercentage.toFixed(
                  2
                )}
              />
              <SolutionStat
                title="Memory"
                value={`${solutionResponse.Memory.toFixed(2)} KB`}
                percentage={solutionResponse.comparison.betterMemoryPercentage.toFixed(
                  2
                )}
              />
              <CodeOutputSection
                title="Output"
                code={solutionResponse.Code}
                decoded={false}
              />
            </div>
          </div>
        ) : aiSuggestion ? (
          <AISuggestions aiSuggestion={aiSuggestion} />
        ) : fetchingAIFeedback === false ? (
          <ErrorFallback onRetry={regenerateAISuggestions} />
        ) : (
          <LoadingAnimation message="Analyzing Your Code..." />
        )}
      </div>
    </div>
  );
};

const SolutionStat = ({ title, value, percentage }) => (
  <div className="bg-gradient-to-r from-[#2A3C4D] to-[#3E1E5F] py-3 px-6 rounded-lg shadow-lg transform transition-all duration-300">
    <div className="text-gray-400">{title}</div>
    <div className="text-xl font-semibold text-green-400 mt-2">
      {value}{' '}
      {percentage ? (
        <span className="text-sm text-gray-500">Beats {percentage}%</span>
      ) : (
        <></>
      )}
    </div>
  </div>
);

const CodeOutputSection = ({ title, code, decoded = false }) => (
  <div className="bg-gradient-to-r from-[#2A3C4D] to-[#3E1E5F] py-3 px-6 rounded-lg shadow-lg transform transition-all duration-300">
    <h3 className="text-gray-400">{title}</h3>
    <pre className="scroll-bar-hr p-3 rounded-md text-sm overflow-x-auto bg-[#3A4D63] text-gray-200">
      <SyntaxHighlighter
        language="javascript"
        style={vscDarkPlus}
        showLineNumbers
      >
        {decoded ? code : atob(code)}
      </SyntaxHighlighter>
    </pre>
  </div>
);

const AISuggestions = ({ aiSuggestion }) => (
  <>
    <SolutionStat title="Skill Level" value={`${aiSuggestion.rate} / 10`} />
    {aiSuggestion.feedback.length > 0 && (
      <FeedbackSection title="AI Feedback" feedback={aiSuggestion.feedback} />
    )}
    {aiSuggestion.suggestions.length > 0 &&
      aiSuggestion.suggestions.map((suggestion, index) => (
        <CodeSuggestionSection key={index} suggestion={suggestion} />
      ))}
  </>
);

const FeedbackSection = ({ title, feedback }) => (
  <div className="bg-gradient-to-r from-[#2A3C4D] to-[#3E1E5F] py-3 px-6 rounded-lg shadow-lg transform transition-all duration-300">
    <h3 className="text-gray-400">{title}</h3>
    <ul className="text-gray-300 pt-3 space-y-2 list-disc pl-5">
      {feedback.map((point, index) => (
        <li key={index}>{point}</li>
      ))}
    </ul>
  </div>
);

const CodeSuggestionSection = ({ suggestion }) => (
  <div className="bg-gradient-to-r from-[#2A3C4D] to-[#3E1E5F] py-3 px-6 rounded-lg shadow-lg transform transition-all duration-300">
    <h4 className="text-gray-400">Suggested Improvement</h4>
    <CodeOutputSection
      title="Current Code"
      code={suggestion.oldCode}
      decoded={true}
    />
    <CodeOutputSection
      title="Improved Code"
      code={suggestion.improvedCode}
      decoded={true}
    />
  </div>
);

const ErrorFallback = ({ onRetry }) => (
  <div className="h-full flex flex-col items-center justify-center">
    <h2 className="text-2xl text-red-500">Oop's Something Went Wrong</h2>
    <button
      onClick={onRetry}
      className="mt-4 bg-[#8254F8] text-white px-4 py-2 rounded-lg shadow hover:bg-[#703de5]"
    >
      Regenerate
    </button>
  </div>
);

const LoadingAnimation = ({ message }) => (
  <div className="h-full flex items-center justify-center">
    <div className="text-[#8254F8] animate-pulse text-lg">{message}</div>
  </div>
);

export { Description };
