import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Google from '../../assets/svg/Google.svg';
import { Button } from '../../components/Button/ButtonV2';
import { baseUrl } from '../../service';
import FeatureBanner from './FeatureBanner';

let featureInfos = [
  {
    title: 'Peer Programming',
    subheading: 'Learn Together, Win Together',
    description:
      'Collaborate with peers in real time! With Peer Programming, you can strategize, solve Byte Battles, and work on tough challenges side-by-side with friends or new connections. Enjoy huddles and live code-syncing, creating a true community-driven learning experience.',
  },
  {
    title: 'Quick Bites',
    subheading: 'Fast, Focused Learning',
    description:
      'Master complex topics with short, powerful lessons! Quick Bites gives you the chance to dive into Data Science, Python, Big Data, and 18+ other fields, broken into digestible chunks. Each lesson comes with an immediate quiz, so you can test your knowledge and strengthen your foundation on the spot.',
  },
  {
    title: 'Byte Battle',
    subheading: 'Level Up Through Coding Battles',
    description:
      "Put your skills to the test with Byte Battle, where coding challenges push your limits and track your growth. Compete, earn points, and see where you rank. It's all about real-world problem-solving, and winning here means getting sharper and stronger each day.",
  },
  {
    title: 'Weekly Challenges',
    subheading: 'Stay Motivated, Track Your Progress',
    description:
      'Stay on top of your goals with Weekly Challenges that inspire continuous growth. Set new targets each week, review your progress, and adjust to stay on track. Consistency is key, and this feature is here to help you build lasting skills and track your learning curve.',
  },
  {
    title: 'HR Network',
    subheading: 'Global Exposure to Hiring Managers',
    description:
      'Build your future with Discoursefy’s HR Network. Showcase your skills to top recruiters and gain priority access to exclusive opportunities. For our Campus Ambassadors, we offer even greater exposure and fast-track networking to help you stand out to recruiters worldwide.',
  },
];

const Auth = () => {
  // let googleRedirect = useAuthStore.getState().googleRedirect()

  const [text, setText] = useState('');
  const [googleRedirection, setGoogleRedirection] = useState(false);

  const location = useLocation();

  let handleGoogleLogin = async () => {
    try {
      setGoogleRedirection(true);
      window.location.href = `${baseUrl}/auth/google-redirect`;
      // await useAuthStore.getState().GoogleRedirect()
      setTimeout(() => {
        setGoogleRedirection(false);
      }, 5000);
    } catch (error) {
      console.log(error);
    }
  };

  let isActivationScreen = () => {
    return location.pathname.includes('/account/activation') ? true : false;
  };

  const onChange = (e) => setText(e.target.value);
  return (
    <div className="">
      <section class=" min-h-screen flex items-center justify-center">
        <div class="grid grid-cols-2 w-full h-[100vh] shadow-lg p-5">
          {/* I want this component to be render from lg screens */}

          {/* #1F1D2B */}
          {/* <div className="authV2leftBanner hidden md:flex flex-col justify-between items-start rounded-[20px] overflow-hidden md:col-span-1 bg-[#191427] my-auto p-4">
            <div className="m-5">
              <img className="w-[266px]" src={LogoFull} alt="" />
            </div>
            <div className="text-white mb-10 mx-5">
              <div className="text-[28px] font-semibold">
                {featureInfos[0].title}
              </div>
              <div className="text-[30px] font-normal text-[#9B75F9]">
                {featureInfos[0].subheading}
              </div>
              <div className="text-[16px] font-normal text-[#918D9A]">
                {featureInfos[0].description}
              </div>
            </div>
          </div> */}
          <FeatureBanner />
          <div class="col-span-2 md:col-span-1 md:w-full px-4 bg-[#191427] flex justify-center items-center">
            <div className="h-content w-[65%] max-w-[520px]">
              {!isActivationScreen() && (
                <></>
                // <div className={`my-3`}>
                //   <Tabs />
                // </div>
              )}
              <Outlet />
              {!isActivationScreen() && (
                <div
                  class={`my-2 w-1/2 m-auto grid grid-cols-3 items-center text-gray-200 ${
                    isActivationScreen() ? 'hidden' : ''
                  }`}
                >
                  <hr class="border-gray-400" />
                  <p class="text-center text-[11px]">OR</p>
                  <hr class="border-gray-400" />
                </div>
              )}
              <div className={`my-3 ${isActivationScreen() ? 'hidden' : ''}`}>
                <Button
                  onClick={() => handleGoogleLogin()}
                  isImage={true}
                  isDisabled={googleRedirection}
                  disabled={googleRedirection}
                  buttonText={'Connect With Google'}
                  image={Google}
                  isActive={true}
                  classes={'text-[18px] font-medium px-7 py-3 bg-[#2A1C50]'}
                  fullWidth
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Auth;
