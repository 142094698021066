import axios from 'axios';
import { Error } from '../config/helpers/toast-helper';

// export const baseUrl = 'http://localhost:3000';
export const baseUrl = 'https://api.discoursefy.com';

export const Post = async (url, data, credentials) => {
  try {
    const response = await axios.post(url, data, {
      headers: { ...{ Authorization: credentials } },
    });
    return response.data;
  } catch (error) {
    Error(error.response.data.message);
    throw error;
  }
};

export const Get = async (url, credentials, queryParams) => {
  try {
    const response = await axios.get(url, {
      headers: { Authorization: credentials },
      params: queryParams,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    // throw error
  }
};

export const Put = async (url, data, credentials) => {
  try {
    const response = await axios.put(url, data, {
      headers: { ...{ Authorization: credentials } },
    });
    return response.data;
  } catch (error) {
    Error(error.response.data.message);
    throw error;
  }
};

export const Delete = async (url, credentials) => {
  try {
    const response = await axios.delete(url, {
      headers: { ...{ Authorization: credentials } },
    });
    return response.data;
  } catch (error) {
    Error(error.response.data.message);
    throw error;
  }
};
