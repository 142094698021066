import { baseUrl, Get, Post } from '..';
import { UserCredentials } from '../../config/helpers/local-storage-accessor-helper';

export const ConnectAccount = async (body) => {
  try {
    let { Authorization } = UserCredentials();
    return await Post(
      `${baseUrl}/identifiers/registerSecret`,
      body,
      Authorization
    );
  } catch (error) {
    console.log(error);
  }
};

export const IsGithubConnected = async () => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(`${baseUrl}/repositories/isConnected`, Authorization);
  } catch (error) {
    console.log(error);
  }
};

export const FetchAccountRepositories = async () => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(`${baseUrl}/repositories/getAll`, Authorization);
  } catch (error) {
    console.log(error);
  }
};

export const FetchGithubRepositories = async () => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(
      `${baseUrl}/repositories/githubRepositories`,
      Authorization
    );
  } catch (error) {
    console.log(error);
  }
};

export const RegisterRepository = async (body) => {
  try {
    let { Authorization } = UserCredentials();
    return await Post(`${baseUrl}/repositories/register`, body, Authorization);
  } catch (error) {
    console.log(error);
  }
};

export const FetchRepositoryId = async (id) => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(`${baseUrl}/repositories/get/${id}`, Authorization);
  } catch (error) {
    console.log(error);
  }
};

export const AnalyzeCode = async (data) => {
  try {
    let { Authorization } = UserCredentials();
    return await Post(
      `${baseUrl}/repositories/codeReview/`,
      data,
      Authorization
    );
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const GetReviewRegisterId = async (id) => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(`${baseUrl}/repositories/reviews/${id}`, Authorization);
  } catch (error) {
    console.log(error);
  }
};
