import axios from 'axios';

export const fetchRepositories = async (token) => {
  const response = await axios.get('https://api.github.com/user/repos', {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data;
};

export const fetchRootLevelContents = async (owner, repo, token) => {
  const url = `https://api.github.com/repos/${owner}/${repo}/contents/`;
  const headers = token ? { Authorization: `Bearer ${token}` } : {};

  try {
    const response = await axios.get(url, { headers });
    return { rootLevelContent: response.data, url }; // Returns an array of root-level contents
  } catch (error) {
    console.error('Error fetching root-level contents:', error.message);
    throw error;
  }
};

// Fetches files and folders within a specific folder
export const fetchFolderContents = async (url, token) => {
  //   const url = `${url}`;
  const headers = token ? { Authorization: `Bearer ${token}` } : {};

  try {
    const response = await axios.get(url, { headers });
    return response.data; // Returns an array of contents within the folder
  } catch (error) {
    console.error('Error fetching folder contents:', error.message);
    throw error;
  }
};

// Fetches the content of a specific file
export const fetchFileContent = async (fileDownloadUrl, token) => {
  const headers = {};

  try {
    const response = await axios.get(fileDownloadUrl, { headers });

    // Check if the response is an object or a string
    if (typeof response.data === 'object') {
      // Format JSON data to a pretty-printed string
      return JSON.stringify(response.data, null, 2);
    } else if (typeof response.data === 'string') {
      return response.data; // Return plain text as-is
    } else {
      throw new Error('Unexpected content type in file');
    }
  } catch (error) {
    console.error('Error fetching file content:', error.message);
    throw error;
  }
};

export const fetchUserInfo = async (token) => {
  const headers = token ? { Authorization: `Bearer ${token}` } : {};

  try {
    const response = await axios.get('https://api.github.com/user', {
      headers,
    });
    return response.data; // Returns an array of contents within the folder
  } catch (error) {
    console.error('Error fetching folder contents:', error.message);
    throw error;
  }
};
