import { baseUrl, Get, Post } from '..';
import { UserCredentials } from '../../config/helpers/local-storage-accessor-helper';

export const RegisterCred = async (body) => {
  try {
    let { Authorization } = UserCredentials();
    return await Post(
      `${baseUrl}/identifier/registerSecret`,
      body,
      Authorization
    );
  } catch (error) {
    console.log(error);
  }
};

export const IsCredsRegistered = async (queryParams) => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(
      `${baseUrl}/identifier/isSecretRegistered`,
      Authorization,
      queryParams
    );
  } catch (error) {
    console.log(error);
  }
};

export const GetCreds = async (queryParams) => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(
      `${baseUrl}/identifier/getCreds`,
      Authorization,
      queryParams
    );
  } catch (error) {
    console.log(error);
  }
};
