import { FileCode2, Github, Gitlab } from 'lucide-react';
import { useState } from 'react';
import { projectEnvironment } from '../../project.env';

export default function GitConnect() {
  let [connectingToGithub, setConnectingToGithub] = useState(false);

  let connectToGithub = () => {
    setConnectingToGithub(true);
    const githubAuthUrl = 'https://github.com/login/oauth/authorize';
    const clientId = projectEnvironment.GITHUB_CLIENT_ID;
    const scope = 'repo,user';

    window.location.href = `${githubAuthUrl}?client_id=${clientId}&scope=${scope}`;
    setTimeout(() => {
      setConnectingToGithub(false);
    }, 5000);
  };
  return (
    <div className="min-h-[calc(100vh-100px)] flex items-center justify-center p-4">
      <div className="w-full max-w-4xl text-center">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4">
          Connect Your Git Provider
        </h1>
        <p className="text-xl md:text-2xl text-gray-600 mb-8">
          Get a review on your remote repositories with our AI Code Reviewer
        </p>
        <div className="space-y-4 lg:space-y-0 lg:flex lg:space-x-4 justify-center">
          <div
            className={`w-full ${
              !connectingToGithub
                ? 'bg-[#24292e] hover:bg-[#2c3137] hover:scale-105'
                : 'bg-gray-700'
            } cursor-pointer rounded-md flex items-center text-[#fafbfc] lg:w-auto px-8 py-3 text-lg transition-all duration-300 ease-in-out`}
            onClick={connectToGithub}
          >
            <Github className="mr-2 h-5 w-5" />
            <span>Connect with GitHub</span>
          </div>
          <div
            className="w-full bg-gray-700 rounded-md flex items-center lg:w-auto px-8 py-3 text-lg"
            disabled
          >
            <FileCode2 className="mr-2 h-5 w-5" />
            <span>Connect with Bitbucket</span>
          </div>
          <div
            className="w-full bg-gray-700 rounded-md flex items-center lg:w-auto px-8 py-3 text-lg"
            disabled
          >
            <Gitlab className="mr-2 h-5 w-5" />
            <span>Connect with GitLab</span>
          </div>
        </div>
      </div>
    </div>
  );
}
