import { useState } from "react";
import React from "react";
import { Button } from "../../components/Button/ButtonV2";
import { Input } from "../../components/Input/InputV2";
import PasswordEye from "../../assets/svg/PasswordEye.svg";
import { useAuthStore } from "../../store/auth/auth";
import { AccountStatusConstants } from "../../config/constants/account";
import { Link, useNavigate } from "react-router-dom";
import AuthEmailIcon from "../../assets/png/authUserEmailIconV2.png";
import AuthPasswordIcon from "../../assets/png/authPasswordIconV2.png";

const SignIn = () => {
  let { login, account } = useAuthStore();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    // e.preventDefault();
    setLoading(true);
    let data = {
      Email: email,
      Password: password,
    };
    let { user, account } = await login(data);
    setLoading(false);

    if (!account) return;
    if (account.Status == AccountStatusConstants.INACTIVE) {
      navigate("/auth/account/activation");
    } else {
      navigate("/");
    }
  };

  let handleDisableButton = () => {
    if (!email.length || !password.length) {
      return false;
    }
    return true;
  };

  return (
    <div>
      {/* <form action="" class="flex flex-col gap-y-4">
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={"Email"}
          type="email"
          classes={"py-3 text-sm"}
        />
        <Input
          onChange={(e) => setPassword(e.target.value)}
          placeholder={"Password"}
          onImageClick={() => setShowPassword(!showPassword)}
          type={showPassword ? "text" : "password"}
          classes={"py-3 text-sm"}
          image={PasswordEye}
        />
        <div class="relative"></div>
        <Button
          onClick={handleSubmit}
          fullWidth={true}
          isDisabled={loading}
          isActive={handleDisableButton()}
          classes={"text-sm font-semibold px-7"}
          buttonText={"Login"}
        />
      </form> */}
      <div className="mb-[60px]">
        <div className="text-[34px] font-medium leading-[54px] text-white">
          Welcome Back
        </div>
        <div className="font-normal text-[18px] leading-[24px] text-white">
          Don't have an account?{" "}
          <Link
            to={"/auth/signUp"}
            className="text-[#9B75F9] hover:underline cursor-pointer"
          >
            Register Now
          </Link>
        </div>
      </div>
      <form handleSubmit={handleSubmit} class="flex flex-col gap-4">
        <div className="mb-10 flex flex-col gap-4 h-[188px]">
          <Input
            placeholder={"Email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type={"email"}
            classes={"py-3 text-[16px] text-[#9B75F9] rounded-[10px] py-4"}
            preIcon={AuthEmailIcon}
          />
          <Input
            placeholder={"Password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? "text" : "password"}
            image={PasswordEye}
            onImageClick={() => setShowPassword(!showPassword)}
            classes={"py-3 text-[16px] text-[#9B75F9] rounded-[10px] py-4"}
            preIcon={AuthPasswordIcon}
          />
        </div>

        <Button
          classes={"text-[18px] font-medium px-7 py-3"}
          isDisabled={loading}
          onClick={handleSubmit}
          fullWidth={true}
          isActive={handleDisableButton()}
          buttonText={"Login"}
        />
      </form>
    </div>
  );
};

export default SignIn;
