import CanvasJSReact from '@canvasjs/react-charts';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { quizLevelModeConstant } from '../../config/constants/quiz';
import { useAnalyticsStore } from '../../store/analytics/analytics';
import { useQuizReportStore } from '../../store/quizAnswer/quizAnswer';
import { useQuizCategoriesStore } from '../../store/quizCategory/quizCategory';
import { useQuizPerformanceStore } from '../../store/quizPerformance/quizPerformance';
import { CategoryCard } from '../CategoryCard/CategoryCard';
import { NoDataFoundCard } from '../NoDataFoundCard/NoDataFoundCard';
import { PlanningModal } from '../PlanningModal/PlanningModal';
import { QuizLeaderboard } from '../QuizLeaderboard/QuizLeaderboard';
import { QuizProfileCard } from '../QuizProfileCard/QuizProfileCard';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const MotivationCard = ({ setOpenPlanningModal }) => {
  let { isWeekAlreadyTracing, currentWeekStatus } = useQuizPerformanceStore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await isWeekAlreadyTracing();
      setLoading(false);
    })();
  }, []);
  return (
    <>
      {!loading ? (
        <div
          className="col-span-1 rounded-md w-full h-[320px] md:h-[250px] p-6 flex flex-col justify-between shadow-lg overflow-hidden relative"
          style={{
            background: 'linear-gradient(135deg, #8254F8, #A86FF1, #8254F8)',
            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          }}
        >
          {/* Continuous shiny effect */}
          {/* <div
          className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent opacity-20 animate-shine"
          style={{
            transform: "skewX(-20deg)",
          }}
        /> */}

          {!currentWeekStatus ? (
            <div className="text-lg md:text-lg lg:text-xl font-extrabold text-white mb-3 relative">
              Unlock Your Potential in Minutes!
            </div>
          ) : (
            <div className="text-lg md:text-lg lg:text-xl font-extrabold text-white mb-3 relative">
              Every Minute Matters!
            </div>
          )}
          {!currentWeekStatus ? (
            <div className="text-s md:text-base text-white opacity-90 relative leading-5">
              Spend 20 minutes a day learning while we track your progress.
              Discoursefy’s 4-week plan builds your tech and soft skills to help
              you stand out to recruiters.
            </div>
          ) : (
            <div>
              Focus on learning, and we’ll take care of the rest whether it's
              job opportunities or tracking your growth. Discoursefy helps you
              learn, evaluate, and get noticed by top recruiters.
            </div>
          )}
          <>
            {currentWeekStatus ? (
              <div className="mt-4 text-sm text-white  font-semibold relative">
                #EveryMinuteMatters #LearnEvaluateGetNoticed #FutureReady
              </div>
            ) : (
              <div className="flex justify-end">
                <div
                  onClick={() => setOpenPlanningModal(true)}
                  className="w-[150px] cursor-pointer text-center text-sm font-semibold bg-[#fff] text-black py-1 rounded-full shadow-sm hover:shadow-lg transition"
                >
                  {/* No idea */}
                  Plan Your Success
                </div>
              </div>
            )}
          </>
        </div>
      ) : (
        <div className="w-full col-span-1 rounded-md h-[320px] md:h-[250px]">
          <Skeleton width={'100%'} height={250} />
        </div>
      )}
    </>
  );
};

const QuizCategory = (props) => {
  let { fetchQuizCategories, quizCategories } = useQuizCategoriesStore();
  let { fetchQuizAttemptsStats, quizAttempts } = useAnalyticsStore();
  let { getStreakCount, streak } = useQuizReportStore();

  const [state, setState] = useState();
  const [pieChartOptions, setPieChartOptions] = useState(null);
  const [hasResultStats, setResultStats] = useState(false);
  const [quizzesAttempts, setQuizzesAttempts] = useState(null);
  const [openPlanningModal, setOpenPlanningModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  let fetchCategories = async () => {
    setLoading(true);
    let [_, { levelPercentages, levelCounts }] = await Promise.all([
      fetchQuizCategories(),
      fetchQuizAttemptsStats(),
    ]);

    setQuizzesAttempts(levelCounts);

    const dataPoints = [];
    if (levelPercentages[quizLevelModeConstant.EASY]) {
      dataPoints.push({
        y: levelPercentages[quizLevelModeConstant.EASY].toFixed(1),
        label: 'Easy',
        indexLabelFontColor: '#81c784',
        color: '#81c784',
      });
    }
    if (levelPercentages[quizLevelModeConstant.MEDIUM]) {
      dataPoints.push({
        y: levelPercentages[quizLevelModeConstant.MEDIUM].toFixed(1),
        label: 'Medium',
        indexLabelFontColor: '#ffeb3b',
        color: '#ffeb3b',
      });
    }

    if (levelPercentages[quizLevelModeConstant.HARD]) {
      dataPoints.push({
        y: levelPercentages[quizLevelModeConstant.HARD].toFixed(1),
        label: 'Hard',
        indexLabelFontColor: '#ffb74d',
        color: '#ffb74d',
      });
    }

    if (levelPercentages[quizLevelModeConstant.ADVANCE]) {
      dataPoints.push({
        y: levelPercentages[quizLevelModeConstant.ADVANCE].toFixed(1) || 0,
        label: 'Advance',
        indexLabelFontColor: '#9575cd',
        color: '#9575cd',
      });
    }

    if (dataPoints.length === 0) {
      setResultStats(true);
    }

    const pieChartOptions = {
      animationEnabled: true,
      height: 225,
      backgroundColor: '#21183C', // "light1", "dark1", "dark2"
      axisY: {
        // title: "Number of Customers"
        labelFontColor: '#ffffff',
      },
      legend: {
        fontColor: '#ffffff',
      },
      axisX: {
        // title: "Number of Customers"
        labelFontColor: '#ffffff',
      },
      data: [
        {
          type: 'doughnut',
          labelColor: '#000000',
          indexLabel: '{label}: {y}%',
          startAngle: -90,
          showInLegend: false,
          dataPoints: dataPoints,
        },
      ],
    };
    setPieChartOptions(pieChartOptions);
    setLoading(false);

    // fetchQuizAttemptsStats()
    //   await fetchQuizCategories()
  };
  return (
    <div className="max-w-[1280px] relative mx-auto grid grid-cols-3 lg:grid-cols-4 px-4 md:px-10 gap-5">
      <PlanningModal
        handleCloseModal={setOpenPlanningModal}
        isOpen={openPlanningModal}
      />
      <div className="hidden md:flex flex-col gap-y-5 col-span-4 md:col-span-1">
        <div className="overflow-hidden rounded-md bg-[#21183C]">
          <QuizProfileCard />
        </div>
        <div className="h-[280px] overflow-hidden rounded-md bg-[#21183C]">
          <QuizLeaderboard />
        </div>
      </div>

      <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 col-span-3 md:col-span-2 lg:col-span-3 gap-x-5 gap-y-5 items-start">
        <div className="col-span-3 grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-5">
          <div className="col-span-1">
            <MotivationCard setOpenPlanningModal={setOpenPlanningModal} />
          </div>
          {!loading ? (
            <div className="col-span-1">
              <div className="h-full flex justify-between items-center overflow-hidden rounded-md bg-[#21183C]">
                <div className="pb-3 w-[60%] sm:w-[70%]">
                  {pieChartOptions && !hasResultStats && (
                    <CanvasJSChart options={pieChartOptions} />
                  )}
                  {hasResultStats && <NoDataFoundCard />}
                </div>
                {quizzesAttempts && (
                  <div className="flex-grow flex flex-col justify-start items-start gap-y-3 w-[30%]">
                    <div className="rounded-lg py-1 w-[100px] bg-[#2f3142] font-normal text-center text-sm text-white">
                      <div className="text-[#81c784] text-xs"> Easy </div>
                      <div className="text-md font-semibold">
                        {quizzesAttempts[quizLevelModeConstant.EASY]}
                      </div>
                    </div>
                    <div className="rounded-lg bg-[#2f3142] py-1 w-[100px] font-normal text-center text-sm text-white">
                      <div className="text-[#ffeb3b] text-xs"> Medium </div>
                      <div className="text-md font-semibold">
                        {quizzesAttempts[quizLevelModeConstant.MEDIUM]}
                      </div>
                    </div>
                    <div className="rounded-lg bg-[#2f3142] py-1 w-[100px] font-normal text-center text-sm text-white">
                      <div className="text-[#ffb74d] text-xs"> Hard </div>
                      <div className="text-md font-semibold">
                        {quizzesAttempts[quizLevelModeConstant.HARD]}
                      </div>
                    </div>
                    <div className="rounded-lg bg-[#2f3142] py-1 w-[100px] font-normal text-center text-sm text-white">
                      <div className="text-[#9575cd] text-xs"> Advance </div>
                      <div className="text-md font-semibold">
                        {quizzesAttempts[quizLevelModeConstant.ADVANCE]}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="w-full col-span-1">
              <Skeleton width={'100%'} height={250} />
            </div>
          )}
        </div>

        {/* {!loading ? (
          // <div className="hidden sm:grid grid-cols-10 col-span-3 gap-x-2 items-start bg-[#21183C] rounded-md p-2">
          //   <div className="grid grid-cols-1 col-span-10 gap-x-2 items-start bg-[#21183C] rounded-md p-2">
          //     <div className="flex text-xs lg:text-sm justify-between text-white items-center">
          //       <div className="">Current Streak: {streak?.currentStreak} </div>
          //       <div className="hidden md:block text-xs italic text-gray-400">
          //         Visit daily to maintain your streak!{' '}
          //       </div>
          //       <div className="">Maximum Streak: {streak?.maxStreak} </div>
          //     </div>
          //   </div>
          //   <div className="grid grid-cols-10 col-span-10 gap-x-2 gap-y-5 items-start bg-[#21183C] rounded-md p-2">
          //     <StreakCardList />
          //   </div>
          // </div>
        ) : (
          <div className="w-full hidden sm:grid col-span-3">
            <Skeleton width={'100%'} height={148} />
          </div>
        )} */}

        {!loading ? (
          <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 col-span-3 md:col-span-2 lg:col-span-3 gap-x-5 gap-y-5 items-start mb-5">
            {/* <div className="col-span-1"></div> */}
            {quizCategories && quizCategories.length
              ? quizCategories.map((data) => (
                  <div className="col-span-1 h-[fit-content]">
                    <CategoryCard info={data} />
                  </div>
                ))
              : null}
          </div>
        ) : (
          <div className="w-full col-span-3 md:col-span-2 lg:col-span-3 gap-x-5 gap-y-5 items-start mb-5">
            <Skeleton width={'100%'} height={240} />
            <Skeleton width={'100%'} height={240} />
            <Skeleton width={'100%'} height={240} />
          </div>
        )}
      </div>
    </div>
  );
};

QuizCategory.propTypes = {};

export { QuizCategory };
