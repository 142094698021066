import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import {
  FaRegFaceFrown,
  FaRegFaceFrownOpen,
  FaRegFaceGrin,
  FaRegFaceGrinBeam,
  FaRegFaceGrinHearts,
} from "react-icons/fa6";
import { useRatingStore } from "../../store/rating/rating";
import { RatingTypeConstants } from "../../config/constants/rating";
import { useParams } from "react-router-dom";
import { useMeetingStore } from "../../store/meeting/meeting";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    background: "#191427",
    border: "none",
    width: "450px",
    maxWidth: "1260px",
    borderRadius: "10px",
  },
  overlay: {
    background: "rgba(0,0,0,0.2)",
  },
};

const customMobileStyles = {
  content: {
    top: "50%",
    left: "62px",
    right: "auto",
    bottom: "auto",
    transform: "translate(0%, -50%)",
    background: "#191427",
    border: "none",
    width: "calc(100% - 72px)",
    maxWidth: "1260px",
    borderRadius: "10px",
  },
  overlay: {
    background: "rgba(0,0,0,0.2)",
  },
};

const RatingOption = ({ icon, text, rate, afterRating, classes = "" }) => {
  let { analyzeMeeting } = useMeetingStore();
  let { createRating } = useRatingStore();

  const [active, setActive] = useState(false);

  let { id } = useParams();

  let handleRateClick = async () => {
    setActive(true);
    let ratePayload = {
      EntityType: RatingTypeConstants.MEETING,
      EntityId: id,
      CreatorAccount: analyzeMeeting.OrganizerAccount,
      Rate: rate,
    };
    await createRating(ratePayload);
    setActive(false);
    afterRating();
  };
  return (
    <div
      onClick={handleRateClick}
      className={`w-[60px] h-[60px] md:w-[70px] md:h-[70px] text-white flex flex-col rounded justify-center items-center gap-y-1 ${
        active ? "bg-[#7E5DEC]" : "bg-[#353B4B]"
      } pt-1 cursor-pointer ${classes}`}
    >
      <div className="">{icon}</div>
      <div className="text-xs">{text}</div>
    </div>
  );
};

const RatingCard = ({ isOpen, onRequestClose }) => {
  function getScreenWidth() {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }

  let getScreenStyle = () => {
    if (getScreenWidth() > 768) {
      return customStyles;
    } else {
      return customMobileStyles;
    }
  };
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={true}
      style={getScreenStyle()}
      contentLabel="Example Modal"
      parentSelector={() => document.querySelector("#root")}
    >
      <div className="text-white">
        <div className="text-lg font-normal text-center md:text-left">
          Give feedback
        </div>
        <div className="my-3 flex flex-col gap-y-3">
          <div className="text-sm text-center md:text-left">
            How was your meeting?
          </div>
          <div className="flex flex-wrap gap-3 justify-center md:justify-start">
            <RatingOption
              icon={<FaRegFaceFrown className="md:text-[30px]" />}
              text={"Worst"}
              rate={1}
            />
            <RatingOption
              icon={<FaRegFaceFrownOpen className="md:text-[30px]" />}
              text={"Bad"}
              rate={2}
              afterRating={onRequestClose}
              classes={"hidden md:block"}
            />
            <RatingOption
              icon={<FaRegFaceGrin className="md:text-[30px]" />}
              text={"Okay"}
              rate={3}
              afterRating={onRequestClose}
            />
            <RatingOption
              icon={<FaRegFaceGrinBeam className="md:text-[30px]" />}
              text={"Good"}
              rate={4}
              afterRating={onRequestClose}
              classes={"hidden md:block"}
            />
            <RatingOption
              icon={<FaRegFaceGrinHearts className="md:text-[30px]" />}
              text={"Amazing"}
              rate={5}
              afterRating={onRequestClose}
            />
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

RatingCard.propTypes = {};

export { RatingCard };
