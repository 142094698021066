import { create } from 'zustand';
import {
  GetActivityRecord,
  GetNextActivities,
  GetStreakCount,
  SaveQuizReport,
} from '../../service/quizAnswers/quizAnswers';

export const useQuizReportStore = create((set, get) => ({
  QuizReport: [],
  quizRanking: [],
  streak: null,
  activityRecord: [],
  fetchingActivityRecord: false,
  fetchingLastActivities: false,
  nextActivities: null,
  lastWeekActivities: { biteBattle: [], quickBites: [] },

  async saveQuizReport(body) {
    try {
      let Answer = await SaveQuizReport(body);
      // set(()=>({questions: questions}))
      return Answer.Success;
    } catch (error) {
      console.log(error);
    }
  },

  async getStreakCount() {
    try {
      let Streak = await GetStreakCount();
      set((state) => ({ streak: { ...Streak } }));
      return Streak;
    } catch (error) {
      console.log(error);
    }
  },

  async getActivityRecord() {
    try {
      set((state) => ({ fetchingActivityRecord: true }));
      let activityRecord = await GetActivityRecord();

      set((state) => ({
        activityRecord: [...activityRecord?.CombineResponse],
        lastWeekActivities: {
          quickBites: activityRecord.Last30DaysQuizPerformance,
          biteBattle: activityRecord.Last30DaysSolutionPerformance,
        },
        fetchingActivityRecord: false,
      }));
      return activityRecord?.CombineResponse;
    } catch (error) {
      console.log(error);
    }
  },

  async getNextActivities() {
    try {
      set((state) => ({ fetchingLastActivities: true }));
      let nextActivities = await GetNextActivities();
      set((state) => ({
        nextActivities: { ...nextActivities },
        fetchingLastActivities: false,
      }));
      return nextActivities;
    } catch (error) {
      console.log(error);
    }
  },
}));
