import { create } from 'zustand';
import {
  BecomeCreator,
  GenerateLinkedInPost,
  GetCreatorInfo,
  GetLinkedInAuthUrl,
  GetObjectUrl,
  GetTrialTokens,
  GetUploadUrl,
} from '../../service/account/account';

export const useAccountStore = create((set, get) => ({
  creatorInfo: null,

  async getUploadUrl(objectName) {
    try {
      let uploadUrl = await GetUploadUrl(objectName);
      return uploadUrl;
    } catch (error) {
      console.log(error);
    }
  },

  async getObjectUrl(objectName) {
    try {
      let objectUrl = await GetObjectUrl(objectName);
      return objectUrl;
    } catch (error) {
      console.log(error);
    }
  },

  async getCreatorInfo(accountId) {
    try {
      let creatorInfo = await GetCreatorInfo(accountId);
      set({ creatorInfo: creatorInfo });
    } catch (error) {
      console.log(error);
    }
  },

  async becomeCreator() {
    try {
      let Response = await BecomeCreator();
      return Response;
    } catch (error) {
      console.log(error);
    }
  },

  async getLinkedInAuthUrl() {
    try {
      let Response = await GetLinkedInAuthUrl();
      return Response;
    } catch (error) {
      console.log(error);
    }
  },

  async generateLinkedInPost(code, queryParams) {
    try {
      let Response = await GenerateLinkedInPost(code, queryParams);
      return Response;
    } catch (error) {
      console.log(error);
    }
  },

  async getTrialTokens() {
    try {
      await GetTrialTokens();
    } catch (error) {
      console.log(error);
    }
  },
}));
