export const CountryCurrencyMapping = {
  PK: 'pkr',
  IN: 'inr',
  USD: 'usd',
};

export const CurrencyToSymbolMapping = {
  pkr: 'Rs.',
  inr: '₹',
  usd: '$',
};
