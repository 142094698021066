import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { Button } from "../Button/Button";
import CoverPhoto from "../../assets/png/CreatorCoverPhoto.png";
import Avatar from "../../assets/png/CreatorPicture.png";
import Check from "../../assets/svg/Check.svg";
import { IoMdClose } from "react-icons/io";
import { Input } from "../Input/Input";
import { MdModeEdit, MdOutlineCancel } from "react-icons/md";
import DatePicker from "react-datepicker";
import * as moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { Separator } from "../Separator/Separator";

import AsyncSelect from "react-select/async";
import { IoCheckmark } from "react-icons/io5";
import { isValidTimeFormat } from "../../config/constants/regix";
import { Error } from "../../config/helpers/toast-helper";
import { Select } from "../Select/Select";
import { useAuthStore } from "../../store/auth/auth";
import { useDiscussionStore } from "../../store/discussion/discussion";
import { useSkillStore } from "../../store/skill/skills";
import { useParams } from "react-router-dom";

const selectedAttendees = [
  { label: "Docker" },
  { label: "Kubernetes" },
  { label: "Typescript" },
  { label: "Node.JS" },
];

const customStyles = {
  content: {
    top: "80%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -80%)",
    background: "#191427",
    border: "none",
    width: "35%",
    height: "80%",
    maxWidth: "1260px",
    borderRadius: "10px",
  },
  overlay: {
    background: "rgba(0,0,0,0.2)",
  },
};

const customMobileStyles = {
  content: {
    top: "10%",
    left: "62px",
    right: "auto",
    bottom: "auto",
    // transform: "translate(-50%, -80%)",
    background: "#191427",
    border: "none",
    width: "calc(100% - 62px)",
    height: "90%",
    maxWidth: "1260px",
    borderRadius: "10px",
  },
  overlay: {
    background: "rgba(0,0,0,0.2)",
  },
};

const CreateDiscussion = ({ isOpen = true, onRequestClose = () => {} }) => {
  let { account } = useAuthStore();
  let {
    createDiscussions,
    analyzedDiscussions,
    getDiscussionInfo,
    updateDiscussions,
  } = useDiscussionStore();
  let { getAllSkills, fetchedSkills, getCreatorExpertise } = useSkillStore();

  const [descriptionView, setDescriptionView] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState();
  const [title, setTitle] = useState("Discussion Title");
  const [description, setDescription] = useState("");
  const [skills, setSkills] = useState([]);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [selectedAttendee, setSelectedAttendee] = useState("");
  const [loading, setLoading] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    fetchAllSkills();
    return () => {};
  }, []);

  const toggleDescriptionView = () => {
    setDescriptionView((prev) => !prev);
  };

  let handleTime = (e) => {
    const { value } = e.target;
    setStartTime(value);
  };

  const filterColors = (inputValue = "") => {
    if (!inputValue.length) {
      return fetchedSkills;
    }
    return fetchedSkills.filter((data) =>
      data.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const toggleTitleEditing = () => {
    if (!title.length) return;
    setIsEditingTitle((prev) => !prev);
  };

  const loadOptions = (inputValue, callback) => {
    // setTimeout(() => {
    // }, 1000);
    callback(filterColors(inputValue));
  };

  const handleSkills = (skill) => {
    let isSkillExist = skills.find(
      (data) => data.label.toLowerCase() == skill.label.toLowerCase()
    );
    if (!isSkillExist) {
      setSkills((prev) => [...prev, skill]);
    }
  };

  const removeAttendee = (accountId) => {
    setSkills((prev) =>
      prev.filter((attendee) => attendee.value !== accountId)
    );
    setSelectedAttendee("");
  };

  function handleResetStates() {
    setStartDate("");
    setStartTime("");
    setTitle("Discussion Title");
    setDescription("");
    setSkills([]);
    setSelectedAttendee("");
    setIsEditingTitle(false);
  }

  const handleCreateDiscussion = async () => {
    setLoading(true);
    if (!isValidTimeFormat(startTime)) {
      setLoading(false);
      return Error("Invalid Time Format");
    }

    let scheduleDate =
      moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD") + " " + startTime;

    // Format the combined date and time to ISO 8601 string
    let isoString = moment(scheduleDate, "YYYY-MM-DD hh:mm A").toISOString();

    if (!title.length || !description.length) {
      setLoading(false);
      return Error("kindly fill the title and description fields");
    }

    let data = {
      AccountId: account._id,
      Title: title,
      Description: description,
      ScheduleTime: isoString,
      Skills: skills.map((skill) => skill.value),
    };

    try {
      if (id) {
        await updateDiscussions(id, data);
      } else {
        await createDiscussions(data);
      }
      setLoading(false);
      handleResetStates();
      onRequestClose();
    } catch (error) {
      console.log(error);
    }
    return;
  };

  let fetchAllSkills = async () => {
    try {
      if (id) {
        let discussionResp = await getDiscussionInfo(id);
        setTitle(discussionResp.Title);
        setDescription(discussionResp.Description);
        setStartDate(discussionResp.ScheduleTime);
        let tags = discussionResp.Skills.map((data) => ({
          label: data.Label,
          value: data._id,
        }));
        setSkills(tags);
        const formattedDate = moment(discussionResp.ScheduleTime).format(
          "hh:mm A"
        );
        setStartTime(formattedDate);
      }
      let skills = await getCreatorExpertise();
    } catch (error) {
      console.log(error);
    }
  };

  function getScreenWidth() {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }

  let getScreenStyle = () => {
    if (getScreenWidth() > 768) {
      return customStyles;
    } else {
      return customMobileStyles;
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={true}
      style={getScreenStyle()}
      contentLabel="Example Modal"
      parentSelector={() => document.querySelector("#root")}
    >
      <div className="text-white flex flex-col h-full">
        {!isEditingTitle ? (
          <div className="flex justify-between items-start gap-3">
            <div className="text-lg font-normal">{title}</div>
            <div
              onClick={toggleTitleEditing}
              className="p-2 border-[1px] cursor-pointer border-solid border-[#353B4B] rounded-md bg-[#353B4B]"
            >
              <MdModeEdit />
            </div>
          </div>
        ) : (
          <div className="flex justify-between items-center gap-3">
            <Input
              classes={"w-[100%]"}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <div
              onClick={toggleTitleEditing}
              className={`p-2 border-[1px] cursor-pointer border-solid border-[#353B4B] rounded-md ${
                title.length ? "bg-[#8254F8]" : "bg-[#353B4B]"
              } `}
            >
              <IoCheckmark />
            </div>
          </div>
        )}

        <div className="my-5 flex-1">
          <div className="grid grid-cols-3 my-6 items-center">
            <div className="font-normal text-sm text-[#AAAAAA]">
              Meeting Date*
            </div>
            <div className="font-normal col-span-1 text-sm text-black">
              <DatePicker
                className="bg-[#353B4B] rounded text-sm text-white py-1 px-3 border-none active:border-none w-content"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                minDate={new Date()}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 my-6 items-center">
            <div className="font-normal text-sm text-[#AAAAAA]">
              Meeting Time*
            </div>
            <div className="font-normal col-span-2 w-[150px] text-sm text-black">
              <Input
                id={"title"}
                onChange={handleTime}
                placeholder={"08:00 PM"}
                value={startTime}
                classes={"bg-[#353B4B] text-sm py-1 px-3 w-[150px] text-white"}
              />
            </div>
          </div>

          <Separator />

          <div className="my-4">
            <Input
              id={"description"}
              label="Description"
              placeholder={"Meeting Description"}
              classes={
                "bg-[#353B4B] text-sm py-1 px-3 w-full rounded text-white"
              }
              type="textarea"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
          </div>

          <Separator />

          <div className="my-4">
            {/* <Input id={"participants"} label="Invite Participants" placeholder={'Participants Email'} classes={'bg-[#353B4B] text-sm py-1 px-3 w-full rounded'} /> */}
            <div className="font-normal text-sm text-[#AAAAAA] mb-2">Tags</div>
            <Select
              defaultOptions={filterColors("")}
              loadOptions={loadOptions}
              handleAttendees={handleSkills}
            />
          </div>
          <div className="flex items center flex-wrap gap-2">
            {skills.map((data) => (
              <div className="text-sm rounded-full py-1 pl-3 pr-2 cursor-pointer bg-[#353B4B] flex items-center gap-2">
                {data.label}{" "}
                <MdOutlineCancel onClick={() => removeAttendee(data.value)} />{" "}
              </div>
            ))}
          </div>
        </div>
        <div className="py-5 flex justify-end">
          <Button
            buttonText={!id ? "Create" : "Update"}
            isDisabled={loading}
            onClick={handleCreateDiscussion}
            isActive={true}
          />
        </div>
      </div>
      {/* <div className="h-[500px] w-full rounded-lg">
            <Input id={"title"} label={'Title'} placeholder={'Title'} value={''} onChange={()=>{}} />
        </div> */}
    </ReactModal>
  );
};

CreateDiscussion.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export { CreateDiscussion };
