import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { IoMdClose } from "react-icons/io";
import { Separator } from "../Separator/Separator";
import { Input } from "../Input/Input";
import DatePicker from "react-datepicker";
import { Button } from "../Button/Button";
import { useEducationStore } from "../../store/education/education";
import { useAuthStore } from "../../store/auth/auth";
import { Error } from "../../config/helpers/toast-helper";
import { useExperienceStore } from "../../store/experience/experience";

let customStyles = {
  content: {
    top: "80%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -80%)",
    background: "#191427",
    border: "none",
    width: "35%",
    height: "80%",
    maxWidth: "1260px",
    borderRadius: "10px",
  },
  overlay: {
    background: "rgba(0,0,0,0.2)",
  },
};
const customMobileStyles = {
  content: {
    top: "80%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -80%)",
    background: "#191427",
    border: "none",
    width: "85%",
    height: "90%",
    maxWidth: "1260px",
    borderRadius: "10px",
  },
  overlay: {
    background: "rgba(0,0,0,0.2)",
  },
};

const CreateExperienceModal = ({ show, onRequestClose }) => {
  let { account, getAccountInfo } = useAuthStore();
  let {
    createExperience,
    getCreatorExperience,
    experienceCardGoingToUpdate,
    updateExperience,
  } = useExperienceStore();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState("");
  const [title, setTitle] = useState("");
  const [organization, setOrganization] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAccountInfo();
    if (experienceCardGoingToUpdate) {
      setStartDate(experienceCardGoingToUpdate.StartDate);
      setEndDate(experienceCardGoingToUpdate.EndDate);
      setTitle(experienceCardGoingToUpdate.Designation);
      setOrganization(experienceCardGoingToUpdate.Organization);
      setDescription(experienceCardGoingToUpdate.Description);
      setLocation(experienceCardGoingToUpdate.Location);
    } else {
      handleResetValues();
    }
  }, [JSON.stringify(experienceCardGoingToUpdate)]);

  let handleResetValues = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setTitle("");
    setOrganization("");
    setDescription("");
    setLocation("");
  };

  const isISODate = (dateString) => {
    // Regular expression to match ISO 8601 date format
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    return isoDateRegex.test(dateString);
  };

  let handleSubmit = async () => {
    if (!title.length || !organization.length || !startDate.toString().length) {
      Error("title, organization and start date are required fields");
      return;
    }
    setLoading(true);
    let data = {
      AccountId: account._id,
      Designation: title,
      Organization: organization,
      JobDescription: description,
      Location: location,
      IsCurrentJob: endDate && endDate.toString().length ? false : true,
      StartDate: isISODate(startDate) ? startDate : startDate.toISOString(),
      EndDate:
        endDate && endDate.toString().length
          ? isISODate(endDate)
            ? endDate
            : endDate.toISOString()
          : "",
    };

    try {
      if (!experienceCardGoingToUpdate) {
        await createExperience(data);
      } else {
        await updateExperience(experienceCardGoingToUpdate._id, data);
      }
      await getCreatorExperience(account._id);
      setLoading(false);
      handleResetValues();
      onRequestClose();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  function getScreenWidth() {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }

  let getScreenStyle = () => {
    if (getScreenWidth() > 768) {
      return customStyles;
    } else {
      return customMobileStyles;
    }
  };

  return (
    <ReactModal
      isOpen={show}
      onRequestClose={() => {
        onRequestClose();
        handleResetValues();
      }}
      shouldCloseOnOverlayClick={false}
      style={getScreenStyle()}
      contentLabel="Example Modal"
      parentSelector={() => document.querySelector("#root")}
    >
      <div className="text-white">
        <div className="header flex justify-between items-center">
          <div className="">Add Experience</div>
          <div
            onClick={() => {
              onRequestClose();
              handleResetValues();
            }}
            className="cursor-pointer"
          >
            <IoMdClose />
          </div>
        </div>
        <div className="my-3">
          <Separator />
        </div>

        <div className="flex flex-col gap-y-5">
          <Input
            label={"Title"}
            required={true}
            type="text"
            placeholder={"Ex: Retail Sales Manager"}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
          />
          <Input
            required={true}
            label={"Company Name"}
            type="text"
            placeholder={"Ex: Google"}
            onChange={(e) => {
              setOrganization(e.target.value);
            }}
            value={organization}
          />
          <Input
            label={"Location"}
            type="text"
            placeholder={"Ex: Redmond, US"}
            onChange={(e) => {
              setLocation(e.target.value);
            }}
            value={location}
          />

          <div className="flex items-center justify-between flex-col md:flex-row md:justify-between md:items-end gap-y-5">
            <div className="date-section">
              <div className="text-sm mb-2 text-[#AAAAAA] font-light">
                {" "}
                Start Date *{" "}
              </div>
              <DatePicker
                className="bg-[#353440] rounded text-sm text-white py-1 px-3 border-none active:border-none"
                selected={startDate}
                showMonthYearPicker
                onChange={(date) => setStartDate(date)}
                dateFormat="MMM, yyyy"
              />
            </div>
            <div className="date-section">
              <div className="text-sm mb-2 flex flex-col text-[#AAAAAA] font-light">
                {" "}
                End Date{" "}
                <span className="text-[9px]">
                  {" "}
                  (leave it empty if it is your current job){" "}
                </span>{" "}
              </div>
              <DatePicker
                className="bg-[#353440] rounded text-sm text-white py-1 px-3 border-none active:border-none"
                showMonthYearPicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="MMM, yyyy"
              />
            </div>
          </div>
          {/* <Input label={'Start Date'} type='date' placeholder={"Ex: 2020-01-01"} onChange={()=>{}} /> */}
          {/* <Input label={'End Date'} type='date' placeholder={"Ex: 2020-01-01"} onChange={()=>{}} /> */}
          <Input
            label={"Description"}
            type="textarea"
            placeholder={""}
            classes={"text-white"}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            value={description}
          />
          <div className="flex justify-end items-center">
            <Button
              onClick={handleSubmit}
              buttonText={"Save"}
              isDisabled={loading}
              isActive={true}
            />
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

CreateExperienceModal.propTypes = {};

export { CreateExperienceModal };
