import { ChevronDown, ChevronUp, Code, Shield, Zap } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

const customStyles = {
  content: {
    top: 'auto',
    left: '50%',
    right: 'auto',
    bottom: '0%',
    transform: 'translate(-50%, -20%)',
    background: 'transparent',
    border: 'none',
    width: '60%',
    maxWidth: '1260px',
    borderRadius: '10px',
    padding: 0,
  },
  overlay: {
    background: 'rgba(0,0,0,0.2)',
  },
};

const customMobileStyles = {
  content: {
    top: '10%',
    left: '62px',
    right: 'auto',
    bottom: 'auto',
    background: '#191427',
    border: 'none',
    width: 'calc(100% - 62px)',
    height: '90%',
    maxWidth: '1260px',
    borderRadius: '10px',
    padding: 0,
  },
  overlay: {
    background: 'rgba(0,0,0,0.2)',
  },
};

const RatingBar = ({ value }) => {
  const getColorClass = (rating) => {
    if (rating < 5) return 'bg-red-500';
    if (rating < 8) return 'bg-blue-500';
    return 'bg-green-500';
  };

  return (
    <div className="w-full bg-gray-700 rounded-full h-2 mb-2">
      <div
        className={`h-full rounded-full transition-all duration-300 ease-in-out ${getColorClass(
          value
        )}`}
        style={{ width: `${Math.min(Math.max(value, 0), 10) * 10}%` }}
      ></div>
    </div>
  );
};

const ProfileReview = ({ aggregatedResult, isOpen, onRequestClose }) => {
  const [expandedSection, setExpandedSection] = useState(null);
  const [modalStyle, setModalStyle] = useState(customStyles);

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const renderSection = (title, icon, data) => (
    <div className="mb-6 bg-[#2C2144] rounded-lg p-4 shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold flex items-center">
          {icon}
          <span className="ml-2">{title}</span>
        </h3>
        <button
          onClick={() => toggleSection(title)}
          className="text-primary hover:text-primary/80 focus:outline-none"
        >
          {expandedSection === title ? (
            <ChevronUp className="h-5 w-5" />
          ) : (
            <ChevronDown className="h-5 w-5" />
          )}
        </button>
      </div>
      {expandedSection === title && (
        <div className="space-y-4">
          {Object.entries(data).map(([key, value]) => (
            <div key={key} className="mb-4">
              <div className="flex justify-between items-center mb-2">
                <span className="capitalize font-medium">
                  {key.replace(/([A-Z])/g, ' $1').trim()}
                </span>
                <span className="text-sm font-semibold">
                  {value.rate.toFixed(1)}/10
                </span>
              </div>
              <RatingBar value={value.rate} />
              <p className="text-sm text-gray-400 mt-2">{value.reason}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  useEffect(() => {
    const handleResize = () => {
      setModalStyle(
        window.innerWidth > 768 ? customStyles : customMobileStyles
      );
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={true}
      style={modalStyle}
      contentLabel="ProfileReviewModal"
      parentSelector={() => document.querySelector('#root')}
    >
      <div className="w-full max-w-4xl mx-auto text-white bg-[#21183C] rounded-lg shadow-xl flex flex-col h-full">
        <div className="p-6 border-b border-gray-700">
          <h2 className="text-2xl font-bold">Profile Review</h2>
        </div>
        <div className="overflow-y-auto scroll-bar max-h-[60vh] flex-grow p-6">
          {renderSection(
            'Security Issues',
            <Shield className="h-5 w-5" />,
            aggregatedResult.securityIssues
          )}

          {renderSection(
            'Code Quality',
            <Code className="h-5 w-5" />,
            aggregatedResult.codeQuality
          )}

          <div className="bg-[#2C2144] rounded-lg p-4 shadow-lg">
            <h3 className="text-xl font-semibold mb-3 flex items-center">
              <Zap className="h-5 w-5 mr-2" /> Overall Rating
            </h3>
            <div className="flex justify-between items-center mb-2">
              <span className="font-medium">Rating</span>
              <span className="text-sm font-semibold">
                {aggregatedResult.rating.toFixed(1)}/10
              </span>
            </div>
            <RatingBar value={aggregatedResult.rating} />
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default ProfileReview;
