import { Get, Post, baseUrl } from '..';
import { UserCredentials } from '../../config/helpers/local-storage-accessor-helper';

export const FetchPlans = async (data) => {
  try {
    return await Get(`${baseUrl}/plan/get`);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const FetchTokenPlan = async () => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(`${baseUrl}/plan/digital`, Authorization);
  } catch (error) {
    console.log(error.message);
  }
};

export const CreatePlanSubscription = async (data) => {
  try {
    let { Authorization } = UserCredentials();
    return await Post(`${baseUrl}/accountPlan/create`, data, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const CreateDigitalPlanSubscription = async (data) => {
  try {
    let { Authorization } = UserCredentials();
    return await Post(`${baseUrl}/accountPlan/buyTokens`, data, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const UpdateCardInfo = async (data) => {
  try {
    let { Authorization } = UserCredentials();
    return await Post(`${baseUrl}/payment/add-new-card`, data, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const IsPlanSubscribed = async () => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(`${baseUrl}/accountPlan/isPlanSubscribed`, Authorization);
  } catch (error) {
    console.log(error);
  }
};

export const FetchPaidAccountPlans = async () => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(`${baseUrl}/accountPlan/invoices`, Authorization);
  } catch (error) {
    console.log(error.message);
  }
};

export const GetInvoiceInfoById = async (id) => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(`${baseUrl}/accountPlan/invoice/${id}`, Authorization);
  } catch (error) {
    console.log(error.message);
  }
};
