import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  CountryCurrencyMapping,
  CurrencyToSymbolMapping,
} from '../../config/constants/plan';
import { useAuthStore } from '../../store/auth/auth';
import { usePlanStore } from '../../store/plans/plans';
import { useUserStore } from '../../store/user/user';
import { PlanCard } from './PlanCard';

const Plan = (props) => {
  let { fetchPlans, plans } = usePlanStore();
  let { user, account, getAccountInfo } = useAuthStore();
  let { updateUser } = useUserStore();

  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState('usd');
  const [countryCode, setCountryCode] = useState('US');

  useEffect(() => {
    getPricingPlans();
  }, []);

  let getPricingPlans = async () => {
    if (!user) {
      var { user: userInfo } = await getAccountInfo();
    }
    user = user || userInfo;
    if (!user?.RegionInfo?.countryCode?.length) {
      let userRegionInfo = await getUserCountry();
      let payload = {
        ...userRegionInfo,
        countryCode: userRegionInfo.country_code,
      };
      user = await updateUser(user._id, { RegionInfo: payload });
    }

    if (CountryCurrencyMapping[user?.RegionInfo?.countryCode]) {
      setCountryCode(user?.RegionInfo?.countryCode);
      setCurrency(CountryCurrencyMapping[user?.RegionInfo?.countryCode]);
    }
    setLoading(true);
    await fetchPlans();
    setLoading(false);
  };

  const getUserCountry = async () => {
    try {
      const response = await fetch('https://get.geojs.io/v1/ip/geo.json', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching user location:', error);
      return null;
    }
  };

  return (
    <div className="h-[100%] overflow-hidden flex justify-center items-center">
      <div className="max-w-[1280px] m-auto text-white bg-gradient-to-r from-[#1E1E2F] to-[#3E1E5F] h-fit lg:my-[5vh] rounded-lg py-10 lg:py-5 px-5">
        <div className="text-center">
          <h1 className="text-3xl font-bold">Pricing Plan</h1>
          <p className="text-gray-400 text-sm my-3 md:w-[80%] mx-auto">
            Choose the plan that fits your learning and career goals. Whether
            you're just starting out or ready to showcase your skills to
            recruiters, our plans offer tailored features designed to enhance
            your problem-solving, technical knowledge, and personal growth. Get
            started for free, or unlock full access to advanced content,
            personalized reports
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 xl:max-w-[80%] mx-auto my-10">
          {!loading ? (
            plans.map((data) => (
              <div className="max-w-[310px] lg:max-w-[100%] mx-auto">
                <PlanCard
                  plan={data}
                  loading={loading}
                  countryCode={countryCode}
                  currency={currency}
                  symbol={CurrencyToSymbolMapping[currency]}
                />
              </div>
            ))
          ) : (
            <>
              <Skeleton width={'100%'} height={400} />
              <Skeleton width={'100%'} height={400} />
              <Skeleton width={'100%'} height={400} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

Plan.propTypes = {};

export { Plan };
