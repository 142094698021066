import React, { useEffect, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { useProblemStore } from '../../store/problem/problem';
import { Table } from '../Table/Table';

let headers = [
  { label: 'Status', cols: 1, id: 'status' },
  { label: 'Title', cols: 5, id: 'title' },
  { label: 'Acceptance', cols: 2, id: 'acceptance' },
  { label: 'Difficulty', cols: 2, id: 'difficulty' },
  { label: '', cols: 2, id: 'invitePeer' },
];

const RadialChart = ({ difficulty, total, solved, color }) => {
  const percentage = total ? (solved / total) * 100 : 0;
  const data = [
    { name: 'Completed', value: percentage },
    { name: 'Remaining', value: 100 - percentage },
  ];
  const colors = [color, '#4B5563']; // Main color and gray for remaining

  return (
    <div className="bg-[#21183C] shadow-lg rounded-md p-4 mb-4 transition-transform transform hover:scale-105">
      <div className="flex items-center justify-between mb-2">
        <span className={`text-${color}-500 text-lg font-medium`}>
          {difficulty}
        </span>
        <span className="text-white text-2xl font-light">
          {percentage.toFixed(1)}%
        </span>
      </div>
      <div className="flex items-center justify-between">
        <div className="w-20 h-20">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={28}
                outerRadius={35}
                paddingAngle={2}
                dataKey="value"
                startAngle={90}
                endAngle={-270}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index]}
                    strokeWidth={0}
                  />
                ))}
              </Pie>
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div className="bg-[#2A1C50] p-2 rounded shadow text-sm">
                        <p>{`${payload[0].name}: ${payload[0].value.toFixed(
                          1
                        )}%`}</p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="text-gray-300 text-sm">
          <p>Sessions:</p>
          <p className="text-right">
            {solved}/{total}
          </p>
        </div>
      </div>
    </div>
  );
};

const Problems = (props) => {
  let { problems, getProblems, totalPages, getProblemsReport, problemReport } =
    useProblemStore();

  const [loading, setLoading] = useState(false);
  const [pageConfig, setPageConfig] = useState({
    Page: 1,
    Limit: 10,
    Search: '',
  });

  useEffect(() => {
    fetchProblems();
  }, [JSON.stringify(pageConfig)]);

  let fetchProblems = async () => {
    setLoading(true);
    await getProblems(pageConfig);
    await getProblemsReport();
    setLoading(false);
  };

  async function handleNextPage() {
    if (
      Math.max(Math.ceil(totalPages / pageConfig.Limit), 1) == pageConfig.Page
    ) {
      return;
    }
    let nextPage = pageConfig.Page + 1;
    setPageConfig({ ...pageConfig, Page: nextPage });
  }

  async function handlePreviousPage() {
    if (pageConfig.Page == 1) {
      return;
    }
    let previousPage = pageConfig.Page - 1;
    setPageConfig({ ...pageConfig, Page: previousPage });
  }

  return (
    <div className="max-w-[1280px] mx-auto grid grid-cols-3 md:grid-cols-4 lg:grid-cols-4 px-2 md:px-10 gap-5">
      <div className="col-span-3 md:col-span-4 lg:col-span-3 px-5 bg-[#21183C] rounded-md overflow-x-auto">
        <Table
          currentPage={pageConfig.Page}
          headers={headers}
          loading={loading}
          onNextPage={handleNextPage}
          onPreviousPage={handlePreviousPage}
          totalPages={Math.ceil(totalPages / pageConfig.Limit) || 1}
          footer={() => {}}
          meetings={problems}
        />
      </div>
      <div className="hidden lg:block col-span-1 min-w-[240px]">
        <RadialChart
          difficulty="Easy"
          total={problemReport?.TotalEasyProblems || 0}
          solved={problemReport?.AccountEasySolutions || 0}
          color="#10B981" // Tailwind green-500
        />
        <RadialChart
          difficulty="Medium"
          total={problemReport?.TotalMediumProblems || 0}
          solved={problemReport?.AccountMediumSolutions || 0}
          color="#FBBF24" // Tailwind yellow-400
        />
        <RadialChart
          difficulty="Hard"
          total={problemReport?.TotalHardProblems || 0}
          solved={problemReport?.AccountHardSolutions || 0}
          color="#EF4444" // Tailwind red-500
        />
      </div>
    </div>
  );
};

Problems.propTypes = {};

export { Problems };
