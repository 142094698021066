import { format, parseISO, subDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const sampleData = [
  {
    day: '2024-11-14',
    activity: 2,
    timeSpentInMinutes: 14,
    _id: '2024-11-14',
    count: 2,
  },
  {
    day: '2024-11-02',
    activity: 1,
    timeSpentInMinutes: 8,
    _id: '2024-11-02',
    count: 1,
  },
];

const processData = (data, days) => {
  const today = new Date();
  const thirtyDaysAgo = subDays(today, days - 1);

  const filledData = Array.from({ length: days }, (_, i) => {
    const date = format(subDays(today, days - 1 - i), 'yyyy-MM-dd');
    const existingData = data.find((item) => item.day === date);
    return existingData || { day: date, timeSpentInMinutes: 0 };
  });

  return filledData.sort(
    (a, b) => parseISO(a.day).getTime() - parseISO(b.day).getTime()
  );
};

const GraphBarChart = ({
  activities,
  loading,
  days,
  ticks = [0, 15, 30, 45],
  title = 'Activity Chart - Last 30 Days',
  onClick,
  showButton = false,
  graphClass = 'h-[100%]',
  buttonText = 'Challenge For You',
}) => {
  // let { activities, loading } = useQuizReportStore();

  const [interval, setInterval] = useState(0); // Default to showing all ticks
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    // In a real scenario, you'd fetch data here
    const processedData = processData(activities, days);
    setChartData(processedData);
  }, [activities.length]);

  useEffect(() => {
    const handleResize = () => {
      const chartWidth = window.innerWidth;

      if (chartWidth < 600) {
        setInterval(4); // Show one tick every 5 items for small screens
      } else if (chartWidth < 1000) {
        setInterval(2); // Show one tick every 3 items for medium screens
      } else {
        setInterval(0); // Show all ticks for larger screens
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial interval based on current width

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {!loading && activities.length && chartData.length > 0 ? (
        <div className="w-full h-[250px] p-3 bg-[#21183C] rounded-lg shadow-lg text-white">
          <h2 className="text-[16px] font-normal mb-4">{title}</h2>
          <div className={`${graphClass}`}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={chartData}
                margin={{
                  top: 10,
                  right: 0,
                  left: 10,
                  bottom: 0,
                }}
              >
                <XAxis // make it respopnsive mean when donot overlap legends if tehre is o space then just show with a gap of 5
                  dataKey="day"
                  tickFormatter={(value) => format(parseISO(value), 'MMM dd')}
                  angle={-45}
                  textAnchor="end"
                  height={80}
                  interval={interval}
                  tick={{ fill: 'white', fontSize: 12 }}
                />
                <YAxis
                  dataKey="timeSpentInMinutes"
                  axisLine={false}
                  tickLine={false}
                  tick={{ fill: 'white', fontSize: 12 }}
                  width={50}
                  ticks={ticks}
                  tickFormatter={(value) => `${value} min`}
                />
                <Tooltip
                  cursor={false}
                  contentStyle={{
                    backgroundColor: '#21183C',
                    border: '1px solid #fff',
                    borderRadius: '4px',
                    padding: '10px',
                  }}
                  labelStyle={{
                    color: 'white',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                  itemStyle={{ color: 'white' }}
                  formatter={(value) => [`${value} minutes`, 'Time Spent']}
                  labelFormatter={(label) =>
                    format(parseISO(label), 'MMMM dd, yyyy')
                  }
                />
                <Bar
                  dataKey="timeSpentInMinutes"
                  fill="#5B21B6"
                  radius={[6, 6, 6, 6]}
                  onMouseEnter={(data, index) => {
                    const bars = document.querySelectorAll(
                      '.recharts-bar-rectangle'
                    );
                    bars[index].setAttribute('fill', '#4C1D95');
                  }}
                  onMouseLeave={(data, index) => {
                    const bars = document.querySelectorAll(
                      '.recharts-bar-rectangle'
                    );
                    bars[index].setAttribute('fill', '#5B21B6');
                  }}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
          {showButton ? (
            <div className="flex justify-end m-0 mt-[-25px]">
              <div
                onClick={onClick}
                className="bg-[#5B21B6] hover:bg-[#4C1D95] px-4 py-2  cursor-pointer rounded-md font-semibold text-sm"
              >
                {buttonText}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : loading ? (
        <div className="flex justify-center items-center">
          <p className="role-text animate-pulse">
            Fetching your activity data...
          </p>
        </div>
      ) : (
        <div className="w-full h-[250px] bg-gradient-to-r from-[#21183C] to-[#31245C] text-white p-6 rounded-lg shadow-2xl flex flex-col justify-center items-center space-y-6 transform transition-all">
          <div className="flex flex-col items-center justify-center flex-grow space-y-4">
            <p className="text-center text-lg font-semibold leading-relaxed max-w-md mb-4">
              Start your learning journey today! Track your progress and see
              your growth over time. The future of learning begins now.
            </p>

            <Link
              to="problems"
              className="bg-[#5B21B6] hover:bg-[#4C1D95] px-4 py-2 rounded-md font-semibold text-sm"
            >
              Explore Peer Programming
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export { GraphBarChart };
