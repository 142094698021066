import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect } from 'react';
import { IoMdClose } from 'react-icons/io';
import ReactModal from 'react-modal';
import { useChargesStore } from '../../store/charges/charges';
import { Separator } from '../Separator/Separator';
import CheckoutForm from './CheckoutForm';
const customStyles = {
  content: {
    top: '80%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -80%)',
    background: '#191427',
    border: 'none',
    width: '35%',
    // height: "80%",
    maxWidth: '1260px',
    borderRadius: '10px',
  },
  overlay: {
    background: 'rgba(0,0,0,0.2)',
  },
};
const customMobileStyles = {
  content: {
    top: '10%',
    left: '62px',
    right: 'auto',
    bottom: 'auto',
    // transform: "translate(-50%, -80%)",
    background: '#191427',
    border: 'none',
    width: 'calc(100% - 62px)',
    height: '90%',
    maxWidth: '1260px',
    borderRadius: '10px',
  },
  overlay: {
    background: 'rgba(0,0,0,0.2)',
  },
};

// prod:
// pk_live_51PDrCpRpw1gyVqw5dq6e3u87RFWQKBaP8Wfu5KGk3fX9d9yVgJYIXKVwgvsuF3CTDAfF52KjGNsHrvzGKmcRG9HY00BB66r1Vp
// dev:
// pk_test_51Ooj13E9g5lMqEuarYDdI92qB6JthN2lgeWYmkLDtX6mjdHDlee8Kd2dh8R79J9Xyt533XkSAuJIZFAeNIXuGqtz00bmnkO5Ma
const promise = loadStripe(
  'pk_live_51PDrCpRpw1gyVqw5dq6e3u87RFWQKBaP8Wfu5KGk3fX9d9yVgJYIXKVwgvsuF3CTDAfF52KjGNsHrvzGKmcRG9HY00BB66r1Vp'
);

const PaymentCard = ({ show, onRequestClose }) => {
  let { getCurrentBalance, walletBalance } = useChargesStore();

  useEffect(() => {
    getCurrentBalance();
  }, []);

  function getScreenWidth() {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }

  let getScreenStyle = () => {
    if (getScreenWidth() > 768) {
      return customStyles;
    } else {
      return customMobileStyles;
    }
  };

  return (
    <ReactModal
      isOpen={show}
      onRequestClose={() => {
        onRequestClose();
      }}
      shouldCloseOnOverlayClick={false}
      style={getScreenStyle()}
      contentLabel="Example Modal"
      parentSelector={() => document.querySelector('#root')}
    >
      <div className="text-white">
        <div className="flex justify-between items-center">
          <div className="">Wallet</div>
          <div
            onClick={() => {
              onRequestClose();
            }}
            className="cursor-pointer"
          >
            <IoMdClose />
          </div>
        </div>
        <div className="my-3">
          <Separator />
        </div>
        <div className="">
          <div className="">
            <div className="text-sm">Current Balance</div>
            <div className="text-[60px] flex items-end">
              <span className="text-[40px] mr-0 mb-2"> $</span>{' '}
              <span className="leading-1">
                {' '}
                {walletBalance?.Balance?.toFixed(2)}
              </span>
            </div>
          </div>
          <Elements stripe={promise}>
            <CheckoutForm />
          </Elements>
        </div>
      </div>
    </ReactModal>
  );
};

PaymentCard.propTypes = {};

export { PaymentCard };
