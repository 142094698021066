import CanvasJSReact from '@canvasjs/react-charts';
import React, { useEffect, useState } from 'react';
import {
  quizLevelModeColorMapping,
  quizLevelModeConstantMapping,
} from '../../config/constants/quiz';
import { useQuizPerformanceStore } from '../../store/quizPerformance/quizPerformance';
import { QuizProfileCard } from '../QuizProfileCard/QuizProfileCard';

import { IoMdArrowRoundBack } from 'react-icons/io'; //
import Skeleton from 'react-loading-skeleton';
import { useLocation, useNavigate, useParams } from 'react-router-dom'; //

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function createChartConfig({
  type, // "doughnut", "column", "pie"
  height = 225,
  minimum = 0,
  maximum = 20,
  intervals = 4,
  backgroundColor = '#21183C',
  axisYLabelFontColor = '#ffffff',
  axisXLabelFontColor = '#ffffff',
  legendFontColor = '#ffffff',
  dataPoints = [],
  stripLines = [],
  indexLabel = '{label}: {y}%',
  startAngle = -90,
  showInLegend = false,
  exploded = false,
  color = '#000000',
  legendText = '',
  secondaryAxisYType = null,
  toolTipContent = '',
  innerRadius = null,
  showLegend = true,
  legendMarkerColor = 'grey',
  dataSeriesName = '',
  labelPlacement = 'inside',
  labelAlign = 'center',
  lineThickness = 2,
  lineDashType = 'dash',
  titleFontColor = '#ffffff',
  yAxisTitle = '',
  xAxisTitle = '',
  ...additionalOptions // Additional options can be passed as needed
}) {
  const commonConfig = {
    animationEnabled: true,
    height,
    backgroundColor,
    axisY: {
      labelFontColor: axisYLabelFontColor,
      stripLines,
      minimum,
      maximum,
      intervals,
      title: yAxisTitle,
      titleFontColor,
    },
    legend: {
      fontColor: legendFontColor,
    },
    axisX: {
      labelFontColor: axisXLabelFontColor,
      title: xAxisTitle,
      titleFontColor,
    },
    data: [
      {
        type,
        innerRadius,
        labelColor: color,
        indexLabel,
        startAngle,
        showInLegend,
        exploded,
        toolTipContent,
        indexLabelPlacement: labelPlacement,
        legendText,
        legendMarkerColor,
        axisYType: secondaryAxisYType,
        name: dataSeriesName,
        dataPoints,
      },
    ],
    ...additionalOptions,
  };

  return commonConfig;
}

// Usage examples

// Doughnut chart
const doughnutChartConfig = createChartConfig({
  type: 'doughnut',
  dataPoints: [
    { y: 67, label: 'Inbox' },
    { y: 28, label: 'Archives' },
    { y: 10, label: 'Labels' },
    { y: 7, label: 'Drafts' },
  ],
});

// Column chart with targets
const columnChartConfig = createChartConfig({
  type: 'column',
  dataPoints: [
    { label: 'Saudi', y: 266.21 },
    { label: 'Venezuela', y: 302.25 },
    { label: 'Iran', y: 157.2 },
    { label: 'Iraq', y: 148.77 },
  ],
  stripLines: [
    {
      value: 15,
      color: '#ff5722',
      label: 'Target Time',
      labelFontColor: '#ff5722',
      labelAlign: 'center',
      labelPlacement: 'outside',
      lineThickness: 2,
      lineDashType: 'dash',
    },
  ],
});

// Pie chart
const pieChartConfig = createChartConfig({
  type: 'pie',
  dataPoints: [
    { y: 26, name: 'React', exploded: true, color: '#8254F8' },
    { y: 20, name: 'Docker', color: 'gray' },
  ],
  showInLegend: true,
  toolTipContent: '{name}: <strong>{y}%</strong>',
  indexLabel: '{name} - {y}%',
});

// Another column chart
const anotherColumnChartConfig = createChartConfig({
  type: 'column',
  dataPoints: [
    { y: 77, label: 'React', color: '#FF6F61' },
    { y: 88, label: 'Node.JS', color: '#6B5B95' },
  ],
  legendText: 'MMbbl = one million barrels',
});

// Custom chart with additional options
const customChartConfig = createChartConfig({
  type: 'column',
  dataPoints: [
    { y: 15, label: 'Sep 12', color: '#FF6F61' },
    { y: 20, label: 'Sep 13', color: '#6B5B95' },
    { y: 12, label: 'Sep 14', color: '#88B04B' },
    { y: 7, label: 'Sep 15', color: '#F7CAC9' },
    { y: 23, label: 'Sep 16', color: '#92A8D1' },
    { y: 30, label: 'Sep 17', color: '#F2C6C6' },
    { y: 0, label: 'Sep 18', color: '#E5E5E5' },
    { y: 12, label: 'Sep 19', color: '#F6D743' },
  ],
  stripLines: [
    {
      value: 15,
      color: '#ff5722',
      label: 'Target Time',
      labelFontColor: '#ff5722',
      labelAlign: 'center',
      labelPlacement: 'outside',
      lineThickness: 2,
      lineDashType: 'dash',
    },
  ],
});

function explodePie(e) {
  if (
    typeof e.dataSeries.dataPoints[e.dataPointIndex].exploded === 'undefined' ||
    !e.dataSeries.dataPoints[e.dataPointIndex].exploded
  ) {
    e.dataSeries.dataPoints[e.dataPointIndex].exploded = true;
  } else {
    e.dataSeries.dataPoints[e.dataPointIndex].exploded = false;
  }
  e.chart.render();
}

let chartFour = {
  animationEnabled: true,
  height: 225,
  backgroundColor: '#21183C', // "light1", "dark1", "dark2"
  axisY: {
    // title: "Number of Customers"
    labelFontColor: '#ffffff',
  },
  legend: {
    fontColor: '#ffffff',
  },
  axisX: {
    // title: "Number of Customers"
    labelFontColor: '#ffffff',
  },
  data: [
    {
      type: 'doughnut',
      innerRadius: 50,
      labelColor: '#000000',
      indexLabel: '{label}: {y}%',
      startAngle: -90,
      showInLegend: false,
      dataPoints: [
        { y: 67, label: 'Inbox' },
        { y: 28, label: 'Archives' },
        { y: 10, label: 'Labels' },
        { y: 7, label: 'Drafts' },
      ],
    },
  ],
};

let chartThree = {
  animationEnabled: true,
  height: 225,
  backgroundColor: '#21183C', // "light1", "dark1", "dark2"
  axisY: {
    // title: "Number of Customers"
    labelFontColor: '#ffffff',
    stripLines: [
      {
        value: 15,
        color: '#ff5722', // Line color
        label: 'Target Time',
        labelFontColor: '#ff5722', // Line label color
        labelAlign: 'center',
        labelPlacement: 'outside',
        lineThickness: 2,
        lineDashType: 'dash', // Line style
      },
    ],
  },
  legend: {
    fontColor: '#ffffff',
  },
  axisX: {
    // title: "Number of Customers"
    labelFontColor: '#ffffff',
  },
  data: [
    {
      type: 'column',
      name: 'Proven Oil Reserves (bn)',
      legendText: 'Proven Oil Reserves',
      showInLegend: true,
      dataPoints: [
        { label: 'Saudi', y: 266.21 },
        { label: 'Venezuela', y: 302.25 },
        { label: 'Iran', y: 157.2 },
        { label: 'Iraq', y: 148.77 },
      ],
    },
    {
      type: 'column',
      name: 'Oil Production (million/day)',
      legendText: 'Oil Production',
      axisYType: 'secondary',
      showInLegend: true,
      dataPoints: [
        { label: 'Saudi', y: 10.46 },
        { label: 'Venezuela', y: 2.27 },
        { label: 'Iran', y: 3.99 },
        { label: 'Iraq', y: 4.45 },
        { label: 'Kuwait', y: 2.92 },
        { label: 'UAE', y: 3.1 },
      ],
    },
  ],
};

var chartTwo = {
  animationEnabled: true,
  height: 225,
  backgroundColor: '#21183C', // "light1", "dark1", "dark2"
  axisY: {
    // title: "Number of Customers"
    labelFontColor: '#ffffff',
  },
  legend: {
    fontColor: '#ffffff',
  },
  axisX: {
    // title: "Number of Customers"
    labelFontColor: '#ffffff',
  },
  data: [
    {
      type: 'pie',
      showInLegend: true,
      toolTipContent: '{name}: <strong>{y}%</strong>',
      indexLabel: '{name} - {y}%',
      indexLabelPlacement: 'inside',
      dataPoints: [
        { y: 26, name: 'React', exploded: true, color: '#8254F8' },
        { y: 20, name: 'Docker', color: 'gray' },
      ],
    },
  ],
};

var chart5Five = {
  animationEnabled: true,
  height: 225,
  backgroundColor: '#21183C', // "light1", "dark1", "dark2"
  axisY: {
    // title: "Number of Customers"
    labelFontColor: '#ffffff',
    stripLines: [
      {
        value: 85,
        color: '#ff5722', // Line color
        label: 'Target Time',
        labelFontColor: '#ff5722', // Line label color
        labelAlign: 'center',
        labelPlacement: 'outside',
        lineThickness: 2,
        lineDashType: 'dash', // Line style
      },
    ],
  },
  legend: {
    fontColor: '#ffffff',
  },
  axisX: {
    // title: "Number of Customers"
    labelFontColor: '#ffffff',
  },
  data: [
    {
      type: 'column',
      showInLegend: true,
      legendMarkerColor: 'grey',
      legendText: 'MMbbl = one million barrels',
      dataPoints: [
        { y: 77, label: 'React', color: '#FF6F61' }, // Coral color
        { y: 88, label: 'Node.JS', color: '#6B5B95' }, // Purple color
      ],
    },
  ],
};

var chartOne = {
  animationEnabled: true,
  height: 225,
  backgroundColor: '#21183C', // "light1", "dark1", "dark2"
  axisY: {
    // title: "Number of Customers"
    labelFontColor: '#ffffff',
    stripLines: [
      {
        value: 15,
        color: '#ff5722', // Line color
        label: 'Target Time',
        labelFontColor: '#ff5722', // Line label color
        labelAlign: 'center',
        labelPlacement: 'outside',
        lineThickness: 2,
        lineDashType: 'dash', // Line style
      },
    ],
  },
  legend: {
    fontColor: '#ffffff',
  },
  axisX: {
    // title: "Number of Customers"
    labelFontColor: '#ffffff',
  },
  data: [
    {
      type: 'column',
      showInLegend: true,
      legendMarkerColor: 'grey',
      legendText: 'MMbbl = one million barrels',
      dataPoints: [
        { y: 15, label: 'Sep 12', color: '#FF6F61' }, // Coral color
        { y: 20, label: 'Sep 13', color: '#6B5B95' }, // Purple color
        { y: 12, label: 'Sep 14', color: '#88B04B' }, // Green color
        { y: 7, label: 'Sep 15', color: '#F7CAC9' }, // Pink color
        { y: 23, label: 'Sep 16', color: '#92A8D1' }, // Light blue color
        { y: 30, label: 'Sep 17', color: '#F2C6C6' }, // Light pink color
        { y: 0, label: 'Sep 18', color: '#E5E5E5' }, // Light grey color
        { y: 12, label: 'Sep 19', color: '#F6D743' }, // Yellow color
      ],
    },
  ],
};

const WeeklyInsights = (props) => {
  let { getQuizPerformanceWeeklyInsights } = useQuizPerformanceStore();
  let { currentWeekReport } = useQuizPerformanceStore();

  const [chart1, setChart1Data] = useState(null);
  const [chart2, setChart2Data] = useState(null);
  const [chart3, setChart3Data] = useState(null);
  const [chart4, setChart4Data] = useState(null);
  const [chart5, setChart5Data] = useState(null);
  const [chart6Data, setChart6Data] = useState(null);
  const [quizCategories, setQuizCategories] = useState();
  const [totalTimeSpentOnCategories, setTotalTimeSpentOnCategories] =
    useState(0);
  const [totalAnswers, setTotalAnswers] = useState(0);
  const [loading, setLoading] = useState(false);

  let { id } = useParams();
  let location = useLocation();
  let navigate = useNavigate(); //

  useEffect(() => {
    handleFetchData();
  }, []);

  const transformData1 = (data, categoriesMapping) => {
    const result = [];

    for (const [categoryId, values] of Object.entries(data)) {
      result.push({
        type: 'column',
        name: categoriesMapping[categoryId], // Assuming categoryId as name, adjust as needed
        showInLegend: true,
        dataPoints: values.map(({ day, totalCorrectAnswers }) => ({
          label: day,
          y: totalCorrectAnswers,
        })),
      });
    }

    return result;
  };

  const transformData2 = (data) => {
    // Map the data to the format required for the chart
    const dataPoints = data.map((item) => ({
      label: item._id, // Using _id as label (date)
      y: item.totalTimeTaking, // Using count as y-value
    }));

    return [
      {
        type: 'column',
        name: 'Quiz Count per Day', // Adjust name as needed
        legendText: 'Daily Quiz Count',
        showInLegend: true,
        dataPoints: dataPoints,
      },
    ];
  };

  const transformDataToPie = (data, categoriesMapping) => {
    const total = data.reduce((sum, item) => sum + item.totalTimeTaking, 0); // Sum of all totalTimeTaking

    return [
      {
        type: 'pie',
        showInLegend: true,
        toolTipContent: '{name}: <strong>{totalTime} minutes</strong>', // Shows total minutes instead of %
        indexLabel: '{name}: {y}%',
        // labelFontColor: "#ffffff", // Set label color to white
        indexLabelPlacement: 'outside',
        dataPoints: data.map((item) => ({
          name: categoriesMapping[item.categoryId], // Use the category name
          y: ((item.totalTimeTaking / total) * 100).toFixed(2), // Percentage calculation
          exploded: false,
          indexLabelFontColor: '#ffffff', // Set label color to white
          // color:
          //   item.categoryId === "66af3bb0cbd52eeb2f96e96a" ? "#8254F8" : "gray", // Customize colors as needed
          totalTime: (item.totalTimeTaking / 60).toFixed(2), // Convert total time to minutes and round to 2 decimal places
        })),
      },
    ];
  };

  const transformDataToDoughnut = (data) => {
    const total = data.reduce((sum, item) => sum + item.count, 0); // Sum of all counts

    return [
      {
        type: 'doughnut',
        innerRadius: 50,
        toolTipContent: '{label}: <strong>{total} Submissions</strong>', // Shows total minutes instead of %
        labelColor: '#ffffff',
        indexLabel: '{label}: {y}%',
        startAngle: -90,
        showInLegend: false,
        dataPoints: data.map((item) => ({
          label: `Level ${quizLevelModeConstantMapping[item.levelMode]}`, // Using levelMode as the label
          y: ((item.count / total) * 100).toFixed(2), // Calculate percentage
          indexLabelFontColor: '#ffffff', // Use the color mapping
          color: quizLevelModeColorMapping[item.levelMode], // Use the color mapping
          total: item.count,
        })),
      },
    ];
  };

  const transformDataToPie2 = (data) => {
    // Calculate total correct and incorrect answers
    const totalCorrect = data.reduce(
      (sum, item) => sum + item.correctAnswers,
      0
    );
    const totalIncorrect = data.reduce(
      (sum, item) => sum + item.incorrectAnswers,
      0
    );
    const totalAnswers = totalCorrect + totalIncorrect;

    // Calculate percentage of correct and incorrect answers
    const correctPercentage = ((totalCorrect / totalAnswers) * 100).toFixed(2);
    const incorrectPercentage = ((totalIncorrect / totalAnswers) * 100).toFixed(
      2
    );

    // Return the pie chart data format
    return [
      {
        type: 'pie',
        showInLegend: true,
        toolTipContent: '{name}: <strong>{answers}</strong>', // Shows total minutes instead of %
        indexLabel: '{name} - {y}%',
        indexLabelPlacement: 'outside',
        dataPoints: [
          {
            y: parseFloat(correctPercentage),
            name: 'Correct Answers',
            color: '#32C732',
            indexLabelFontColor: '#ffffff', // Set label color to white
            answers: totalCorrect,
          }, // Green for correct
          {
            y: parseFloat(incorrectPercentage),
            name: 'Incorrect Answers',
            color: '#D8581C',
            indexLabelFontColor: '#ffffff', // Set label color to white
            answers: totalIncorrect,
          }, // Red for incorrect
        ],
      },
    ];
  };

  const handleFetchData = async () => {
    setLoading(true);
    const startDate = new URLSearchParams(location.search).get('startDate');
    const endDate = new URLSearchParams(location.search).get('endDate');

    let resp = await getQuizPerformanceWeeklyInsights(id, startDate, endDate);

    setQuizCategories(resp.quizCategoriesMapping);
    const totalAnswers = resp.CorrectAndIncorrectAnswersByCategory.reduce(
      (sum, item) => sum + item.correctAnswers + item.incorrectAnswers,
      0
    );

    setTotalAnswers(totalAnswers);

    let totalTime =
      resp.TimeSpentOnEachCategory.reduce(
        (sum, item) => sum + item.totalTimeTaking,
        0
      ) / 60;
    setTotalTimeSpentOnCategories(totalTime.toFixed(1));

    // Assuming resp contains the raw data for charts

    setChart1Data(
      createChartConfig({
        type: 'column',
        minimum: 0, // Start Y-axis from 0
        maximum: 16, // Ensure Y-axis extends at least to 16
        interval: 4, // Optional: set an interval for grid lines
        stripLines: [
          {
            value: 10,
            color: '#ff5722',
            label: 'Target Score',
            labelFontColor: '#ff5722',
            labelAlign: 'center',
            labelPlacement: 'outside',
            lineThickness: 2,
            lineDashType: 'dash',
          },
        ],
        yAxisTitle: 'Correct Answers',
        xAxisTitle: 'Date',
        data: transformData1(
          resp.ComparativeAnalysisByCategory,
          resp.quizCategoriesMapping
        ),
      })
    );

    setChart3Data(
      createChartConfig({
        type: 'pie',
        height: 225,
        backgroundColor: '#21183C',
        data: transformDataToPie(
          resp.TimeSpentOnEachCategory,
          resp.quizCategoriesMapping
        ),
      })
    );

    setChart5Data(
      createChartConfig({
        type: 'pie',
        height: 225,
        backgroundColor: '#21183C',
        data: transformDataToPie2(resp.CorrectAndIncorrectAnswersByCategory),
      })
    );

    let maxTime = currentWeekReport?.TimeCommittedOnLearning / 7 + 5; ////
    setChart2Data(
      createChartConfig({
        type: 'column',
        minimum: 0, // Start Y-axis from 0
        // maximum: 15 + 5, // Ensure Y-axis extends at least to 16
        maximum: maxTime, // Ensure Y-axis extends at least to 16 //
        interval: 10, // Optional: set an interval for grid lines //
        stripLines: [
          {
            value: currentWeekReport?.TimeCommittedOnLearning / 7, //
            color: '#ff5722',
            label: `Target: ${
              currentWeekReport?.TimeCommittedOnLearning / 7 //
            } min`, //
            labelFontColor: '#ff5722',
            labelAlign: 'center',
            labelPlacement: 'outside',
            lineThickness: 2,
            lineDashType: 'dash',
          },
        ],
        data: transformData2(resp.WeeklyAppearanceAndTimeCommitment),
      })
    );

    setChart4Data({
      type: 'doughnut',
      height: 225,
      backgroundColor: '#21183C',
      data: transformDataToDoughnut(resp.CountQuizzesSolvedByLevelMode),
    });
    setLoading(false);
  };
  return (
    <div className="max-w-[1280px] relative mx-auto grid grid-cols-3 lg:grid-cols-4 overflow-x-hidden px-4 md:px-10 gap-5">
      <div className="hidden md:flex flex-col gap-y-5 col-span-4 md:col-span-1">
        <div className="overflow-hidden rounded-md bg-[#21183C]">
          {/* type={QuizProfileCardType.REPORT} */}
          <QuizProfileCard />
        </div>
      </div>
      <div className="grid grid-cols-2 col-span-3 md:col-span-2 lg:col-span-3 gap-x-5 gap-y-5 items-start px-8 bg-[#191427] min-h-screen mb-5">
        {/* Progress Bar UI Left */}
        {!loading ? (
          <div className="col-span-2 bg-[#21183C] p-6 rounded-lg shadow-md flex flex-col">
            <div className="flex justify-between items-end">
              <h2 className="flex justify-center items-center gap-x-2 text-xl font-bold text-white mb-2">
                <span
                  class="cursor-pointer bg-[#B312FD] text-white w-[25px] h-[25px] rounded-full flex justify-center items-center"
                  onClick={() => navigate('/')}
                >
                  <IoMdArrowRoundBack /> {/*  */}
                </span>{' '}
                <span>Progress Overview</span> {/*  */}
              </h2>
              <div className="text-5xl font-bold text-[#B312FD] mb-2">
                {currentWeekReport?.Efficiency}
              </div>
            </div>
            <div className="flex flex-col items-center mb-4">
              <div className="relative w-full h-6 bg-[#2E2A39] rounded-full overflow-hidden">
                <div
                  className="absolute top-0 left-0 h-full bg-[#B312FD] transition-all duration-300"
                  style={{ width: `${currentWeekReport?.Efficiency}` }}
                >
                  {currentWeekReport?.Efficiency !== '0%' ? (
                    <div className="flex items-center justify-center h-full text-white font-semibold text-xs">
                      {currentWeekReport?.Efficiency}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full col-span-2">
            <Skeleton width={'100%'} height={144} />
          </div>
        )}

        {/* Sessions Committed and Completed */}
        {!loading ? (
          <div className="col-span-2 xl:col-span-1 h-[100%] bg-[#21183C] p-6 rounded-lg shadow-lg border border-[#333] flex flex-col">
            <h3 className="text-lg font-semibold text-white mb-4 flex items-start">
              <span className="mr-2 text-[#B312FD]">📊</span> {/* Icon */}
              Sessions Committed vs Completed
            </h3>
            <div className="flex flex-col flex-grow">
              <div className="flex justify-between items-center text-white bg-[#191427] p-4 rounded-lg shadow-sm mb-4">
                <div className="font-medium flex items-center">
                  <span className="mr-2 text-green-400">✔️</span> {/* Icon */}
                  <span className="font-normal">Committed:</span>{' '}
                  <span className="font-bold text-xl">
                    {currentWeekReport?.TotalSessions}
                  </span>
                </div>
                <div className="font-medium flex items-center">
                  <span className="mr-2 text-blue-400">✅</span> {/* Icon */}
                  <span className="font-normal">Completed:</span>{' '}
                  <span className="font-bold text-xl">
                    {currentWeekReport?.SessionsCompleted}
                  </span>
                </div>
              </div>
              <div className="relative h-2 bg-[#191427] rounded-full overflow-hidden mb-4">
                <div
                  className="absolute top-0 left-0 h-full bg-[#B312FD] transition-all duration-300"
                  style={{
                    width: `${
                      (currentWeekReport?.SessionsCompleted /
                        currentWeekReport?.TotalSessions) *
                      100
                    }%`,
                  }}
                ></div>
              </div>
              <div className="text-white text-xs text-center mb-4">
                <span className="font-semibold">Completion Rate:</span>{' '}
                {`${(
                  (currentWeekReport?.SessionsCompleted /
                    currentWeekReport?.TotalSessions) *
                  100
                ).toFixed(2)}%`}
              </div>
              {/* Divider Line */}
              <div className="text-white text-center text-xs">
                {(() => {
                  const completionRate =
                    (currentWeekReport?.SessionsCompleted /
                      currentWeekReport?.TotalSessions) *
                    100;
                  if (completionRate >= 100) {
                    return (
                      <p className="text-green-400 font-normal">
                        🎉 Awesome job! You've achieved your target this week!
                        Keep up the great work!
                      </p>
                    );
                  } else {
                    return (
                      <p className="text-yellow-400 font-normal">
                        Keep pushing! You are making great progress. Finish
                        strong to meet your target!
                      </p>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full col-span-2 xl:col-span-1">
            <Skeleton width={'100%'} height={257} />
          </div>
        )}

        {/* Time Committed and Completed */}
        {/* <div className="col-span-1 bg-[#21183C] p-4 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold text-white mb-2">
            Time Committed vs Completed
          </h3>
          <p className="text-gray-300">Chart or Details here...</p>
        </div> */}

        {/* Knockouts */}
        {!loading ? (
          <div className="col-span-2 xl:col-span-1 bg-[#21183C] h-[100%] p-6 rounded-lg shadow-lg border border-[#333]">
            <h3 className="text-xl font-bold text-white mb-4 flex items-center">
              <span className="mr-2 text-[#B312FD] text-2xl">🏆</span>{' '}
              {/* Icon */}
              Knockouts Overview
            </h3>
            <div className="flex flex-col space-y-4">
              <div className="flex items-center justify-center bg-[#191427] p-6 rounded-lg shadow-sm">
                <div className="text-3xl font-semibold text-[#B312FD]">
                  {currentWeekReport?.KnockOuts}
                </div>
              </div>
              <div className="text-white text-center text-sm">
                <span className="font-semibold">Total Knockouts:</span>{' '}
                {currentWeekReport?.KnockOuts}
              </div>
              <div className="text-gray-300 text-center text-xs">
                {currentWeekReport?.KnockOuts > 0
                  ? 'Great job on achieving knockouts! Keep up the good work!'
                  : 'No knockouts yet. Try to improve your performance.'}
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full col-span-2 xl:col-span-1">
            <Skeleton width={'100%'} height={257} />
          </div>
        )}

        {/* Bar Chart for Weekly and Daily Performance */}
        {!loading ? (
          <div className="col-span-2 bg-[#21183C] p-4 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-white mb-2">
              Weekly and Daily Performance
            </h3>
            {chart1 && (
              <div className="">
                <CanvasJSChart options={chart1} />
              </div>
            )}
          </div>
        ) : (
          <div className="w-full col-span-2">
            <Skeleton width={'100%'} height={293} />
          </div>
        )}

        {/* Daily Time Commitment (Bar Chart) */}
        {!loading ? (
          <div className="col-span-2 xl:col-span-1 bg-[#21183C] p-4 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-white mb-2">
              Daily Time Commitment
            </h3>
            {chart2 && (
              <div className="">
                <CanvasJSChart options={chart2} />
              </div>
            )}
          </div>
        ) : (
          <div className="w-full col-span-2 xl:col-span-1">
            <Skeleton width={'100%'} height={293} />
          </div>
        )}

        {/* Time Spent on Each Category */}
        {!loading ? (
          <div className="col-span-2 xl:col-span-1 bg-[#21183C] p-4 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-white mb-2">
              Time Spent on Each Category
            </h3>
            {chart3?.data[0]?.dataPoints?.length > 0 ? (
              <div className="flex items-center gap-x-2">
                {chart3 && (
                  <div className="w-[70%]">
                    <CanvasJSChart options={chart3} />
                  </div>
                )}
                <div className="flex justify-center items-center flex-col">
                  <div className="text-5xl font-bold">
                    {totalTimeSpentOnCategories}
                  </div>
                  <div className="text-sm">Minutes</div>
                </div>
              </div>
            ) : (
              <div class="h-[225px] flex justify-center items-center text-xl font-bold">
                {' '}
                No Data Found!
              </div>
            )}
          </div>
        ) : (
          <div className="w-full col-span-2 xl:col-span-1">
            <Skeleton width={'100%'} height={293} />
          </div>
        )}

        {/* Difficulty Levels (Donut Chart) */}
        {!loading ? (
          <div className="col-span-2 xl:col-span-1 bg-[#21183C] p-4 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-white mb-2">
              Difficulty Levels
            </h3>
            {chart4 && chart4?.data[0]?.dataPoints?.length > 0 ? (
              <div className="">
                <CanvasJSChart options={chart4} />
              </div>
            ) : (
              <div class="h-[225px] flex justify-center items-center text-xl font-bold">
                {' '}
                No Data Found!
              </div>
            )}
          </div>
        ) : (
          <div className="w-full col-span-2 xl:col-span-1">
            <Skeleton width={'100%'} height={293} />
          </div>
        )}

        {/* Total Correct and Incorrect Answers (Wheel Chart) */}
        {!loading ? (
          <div className="col-span-2 xl:col-span-1 bg-[#21183C] p-4 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-white mb-2">
              Total Correct and Incorrect Answers
            </h3>
            {chart5 &&
            chart5?.data[0]?.dataPoints[0].answers &&
            chart5?.data[0]?.dataPoints[1].answers ? (
              <div className="flex items-center gap-x-2">
                {chart5 && (
                  <div className="w-[70%]">
                    <CanvasJSChart options={chart5} />
                  </div>
                )}
                <div className="flex justify-center items-center flex-col">
                  <div className="text-5xl font-bold">{totalAnswers}</div>
                  <div className="text-sm">Total Answers</div>
                </div>
              </div>
            ) : (
              <div class="h-[225px] flex justify-center items-center text-xl font-bold">
                {' '}
                No Data Found!
              </div>
            )}
          </div>
        ) : (
          <div className="w-full col-span-2 xl:col-span-1">
            <Skeleton width={'100%'} height={293} />
          </div>
        )}

        {/* Pie Chart for Efficiency by Category */}
        {/* <div className="col-span-2 bg-[#21183C] p-4 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold text-white mb-2">
            Efficiency by Category
          </h3>
          <div className="">
            <CanvasJSChart options={chart5} />
          </div>
        </div>*/}
      </div>
    </div>
  );
};

WeeklyInsights.propTypes = {};

export { WeeklyInsights };
