import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  CountryCurrencyMapping,
  CurrencyToSymbolMapping,
} from '../../config/constants/plan';
import { useAuthStore } from '../../store/auth/auth';
import { usePlanStore } from '../../store/plans/plans';
import { AddPaymentMethod } from '../PricingSubscription/AddPaymentMethod';

const BuyToken = (props) => {
  let { fetchTokenPlan } = usePlanStore();
  let { user, getAccountInfo } = useAuthStore();

  const [loading, setLoading] = useState();
  const [plan, setPlan] = useState();
  const [currency, setCurrency] = useState('usd');
  const [countryCode, setCountryCode] = useState('US');

  let navigate = useNavigate();
  const { pathname } = useLocation();
  let { id } = useParams();

  useEffect(() => {
    fetchUserInfo();
    getPricingPlans();
  }, []);

  let fetchUserInfo = async () => {
    if (!user) {
      var { user: userInfo } = await getAccountInfo();
    }
    user = user || userInfo;

    if (CountryCurrencyMapping[user?.RegionInfo?.countryCode]) {
      setCountryCode(user?.RegionInfo?.countryCode);
      setCurrency(CountryCurrencyMapping[user?.RegionInfo?.countryCode]);
    }
  };

  let getPricingPlans = async () => {
    setLoading(true);
    let plan = await fetchTokenPlan();
    setPlan(plan);
    setLoading(false);
  };

  let handleOnComplete = () => {
    navigate(`/repositories`);
  };

  const promise = loadStripe(
    'pk_live_51PDrCpRpw1gyVqw5dq6e3u87RFWQKBaP8Wfu5KGk3fX9d9yVgJYIXKVwgvsuF3CTDAfF52KjGNsHrvzGKmcRG9HY00BB66r1Vp'
  );
  const cardStyle = {
    style: {
      base: {
        color: '#ffffff',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        border: '1px solid #FFFFFF',
        fontSize: '16px',
        '::placeholder': {
          color: '#ffffff',
        },
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  return (
    <div className="h-[100vh] lg:overflow-hidden flex justify-center items-center">
      <div className="w-[640px] mx-auto text-white bg-gradient-to-r from-[#1E1E2F] to-[#3E1E5F] rounded-lg py-8 px-6 shadow-lg">
        {/* Plan Header */}
        <div className="flex justify-between items-center">
          <div>
            <h4 className="font-extrabold text-xl mb-2">{plan?.Name}</h4>
            <h3 className="text-4xl font-semibold">
              {CurrencyToSymbolMapping[currency]}
              {plan?.Region[countryCode]?.Price?.toLocaleString() ||
                plan?.Price?.toLocaleString()}
            </h3>
          </div>

          {/* Tokens */}
          <div className="text-right">
            <div className="flex items-center gap-2 mb-4">
              {/* <Coins className="text-yellow-500 w-5 h-5" /> */}
              <span className="text-gray-200">
                <div className="font-extrabold text-xl">Tokens</div>
                <span className="font-semibold text-4xl">
                  {plan?.Tokens?.toLocaleString()}
                </span>{' '}
              </span>
            </div>
          </div>
        </div>

        {/* Plan Description */}
        {/* <div className="mb-6 text-gray-300">
          <p>{plan?.Description}</p>
        </div> */}

        {/* Payment Details */}
        <div className="mb-8">
          <h4 className="font-semibold text-white mb-3">Payment Details</h4>
          <div className="p-4 bg-[#252836] rounded-lg">
            <Elements stripe={promise}>
              <AddPaymentMethod
                digitalPlan={true}
                onComplete={handleOnComplete}
              />
            </Elements>
          </div>
        </div>
      </div>
    </div>
  );
};

BuyToken.propTypes = {};

export { BuyToken };
