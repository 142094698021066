import React, { useEffect } from 'react';
import { useQuizReportStore } from '../../store/quizAnswer/quizAnswer';
import { useQuizProfileStore } from '../../store/quizProfile/quizProfile';

const QuizLeaderboard = (props) => {
  let {} = useQuizReportStore();
  let { getQuizRanking, quizRanking } = useQuizProfileStore();

  useEffect(() => {
    fetchQuizRanking();
  }, []);

  let fetchQuizRanking = async () => {
    await getQuizRanking();
  };

  let handleQuizRankBg = (rank) => {
    switch (rank) {
      case 1:
        return 'bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600';
      case 2:
        return 'bg-gradient-to-r from-gray-300 via-gray-400 to-gray-500';
      case 3:
        return 'bg-gradient-to-r from-yellow-800 via-yellow-700 to-yellow-600';
      default:
        return 'bg-gray-700';
    }
  };
  return (
    <div className="rounded-xl bg-[#21183C] w-full h-full overflow-hidden flex flex-col">
      {/* Header */}
      <div className="header p-3 border-b border-gray-700 text-center">
        <div className="text-lg font-semibold text-white">Leaderboard</div>
      </div>

      {/* Scrollable Content */}
      <div className="flex-grow flex flex-col gap-y-2 overflow-y-auto scroll-bar py-3 px-4">
        {quizRanking.map((data, i) => (
          <div
            key={i}
            className="flex justify-between items-center py-2 border-b border-gray-700"
          >
            <div className="flex items-center">
              <span
                className={`px-3 py-1 text-xs rounded-full ${handleQuizRankBg(
                  i + 1
                )} text-white mr-2`}
              >
                #{i + 1}
              </span>
              <span className="text-xs font-medium text-white">
                {data?.Account?.User?.FirstName}
              </span>
            </div>
            {/* <div className="text-xl font-bold text-white">
              {data?.CorrectAnswers} <span className="text-xs">pts</span>
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

QuizLeaderboard.propTypes = {};

export { QuizLeaderboard };
