import React, { useState } from "react";
import PropTypes from "prop-types";

const Input = (props) => {
  let {
    id,
    disabled = false,
    required = false,
    label,
    type = "text",
    placeholder,
    value,
    onChange,
    classes,
    rows,
    cols,
    focus = false,
    preIcon = null,
    min,
    max,
    image,
    onImageClick,
  } = props;
  const [state, setState] = useState();
  return (
    <div className="w-full">
      {type !== "textarea" ? (
        <div>
          {label && (
            <label
              className="block text-[#AAAAAA] text-sm font-light mb-2"
              htmlFor={id}
            >
              {label} {required ? "*" : null}
            </label>
          )}
          <div
            className={`flex gap-x-2 shadow appearance-none border rounded w-full px-3 text-gray-700 bg-[#21183C] leading-tight border-none focus:outline-none focus:shadow-outline ${classes}`}
          >
            {preIcon ? (
              <span>
                <img className="w-[22px] h-[20px]" src={preIcon} />
              </span>
            ) : null}
            <input
              className="w-full border-none focus:outline-none focus:shadow-outline bg-transparent no-spinners"
              id={id}
              type={type}
              placeholder={placeholder ? placeholder : ""}
              value={value}
              autoFocus={focus}
              onChange={onChange}
              disabled={disabled}
              {...props}
            />

            {/* <div onClick={onImageClick} className="pl-2 cursor-pointer">
            <img src={image} />
        </div> */}
          </div>
        </div>
      ) : (
        <div>
          {label && (
            <label
              className="block text-[#AAAAAA] text-sm font-light mb-2"
              htmlFor={id}
            >
              {label}
            </label>
          )}
          <textarea
            id="w3review"
            onChange={onChange}
            value={value}
            name="w3review"
            rows={rows ? rows : "6"}
            cols={cols ? cols : "50"}
            placeholder={placeholder ? placeholder : ""}
            className={`shadow appearance-none border text-[#FFFFFF] rounded w-full py-2 px-3 text-gray-700 bg-[#353440] leading-tight border-none focus:outline-none focus:shadow-outline scroll-bar ${classes}`}
          />
        </div>
      )}
    </div>
  );
};

Input.propTypes = {};

export { Input };
