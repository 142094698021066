import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Error, Success } from '../../config/helpers/toast-helper';
import { usePlanStore } from '../../store/plans/plans';

const AddPaymentMethod = ({
  onComplete = () => {},
  updatePaymentMethod = false,
  classes = 'flex flex-col gap-y-4',
  cardClass = '',
  buttonClass = 'mt-4  rounded-lg',
  buttonText = 'Checkout',
  digitalPlan = false,
}) => {
  let {
    createPlanSubscription,
    updateCardInfo,
    createDigitalPlanSubscription,
  } = usePlanStore();

  let [loading, setLoading] = useState(false);

  let { id } = useParams();
  let navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    // setDisabled(event.empty);
    // setError(event.error ? event.error.message : "");
  };
  const cardStyle = {
    style: {
      base: {
        color: '#ffffff',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        border: '1px solid #FFFFFF',
        fontSize: '16px',
        '::placeholder': {
          color: '#ffffff',
        },
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${'test'} ${'last name'}`,
          email: 'hemmo@gmail.com',
        },
      });

      if (error) {
        setLoading(false);
        Error(`Payment failed ${error.message}`);
      }

      let payload = {
        PaymentMethod: paymentMethod.id,
        CardInfo: paymentMethod.card,
        ...(!updatePaymentMethod && { PlanId: id }),
      };

      if (!updatePaymentMethod) {
        let Response;
        if (!digitalPlan) {
          Response = await createPlanSubscription(payload);
        } else {
          Response = await createDigitalPlanSubscription(payload);
        }
        Success(Response.message);
      } else {
        await updateCardInfo(payload);
      }

      setLoading(false);
      if (error) {
        setLoading(false);
        Error(`Payment failed ${error.message}`);
      } else {
        setLoading(false);
      }

      if (!updatePaymentMethod) {
        navigate('/');
      }

      onComplete();
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={`${classes}`}>
      <div
        className={`rounded bg-white w-full bg-opacity-5 backdrop-blur-lg py-2 px-1 border-2 border-white h-fit ${cardClass}`}
      >
        <CardElement
          className="w-[100%]"
          id="card-element"
          options={cardStyle}
          onChange={handleChange}
        />
      </div>
      <button
        disabled={loading}
        onClick={handleSubmit}
        className={`w-full ${
          loading
            ? 'bg-gradient-to-r from-gray-400 to-gray-500'
            : 'bg-gradient-to-r from-[#B310FD] to-[#9D0ECB]'
        } text-white py-2 transition duration-200 ${buttonClass}`}
      >
        {buttonText}
      </button>
    </div>
  );
};

AddPaymentMethod.propTypes = {};

export { AddPaymentMethod };
