import React, { useState } from 'react';
import Loader from '../../assets/gif/buttonLoader.gif';

const Button = ({
  isActive,
  onClick,
  buttonText,
  classes = '',
  fullWidth,
  isImage,
  disabled = false,
  isDisabled = false,
  image,
  type = 'filled', // outlined | filled
}) => {
  const [state, setState] = useState();
  return (
    <div>
      <button
        disabled={isDisabled}
        onClick={() => {
          !disabled && onClick();
        }}
        className={
          type === 'filled'
            ? `
        ${isActive ? 'bg-[#703BF7] text-white' : 'bg-[#353440] text-[#f6f6f6]'} 
        px-4 py-2 text-sm rounded-md  duration-300 font-medium
        ${
          disabled ? 'bg-[#353440] text-white cursor-default' : 'cursor-pointer'
        }
        ${fullWidth ? 'w-full' : ''} ${classes}`
            : 'outline-border rounded-lg border-2 px-10 h-9'
        }
      >
        {isImage && !isDisabled && (
          <img
            src={image}
            alt="Button Image"
            className={`${buttonText && `inline-block mr-2`} w-5 h-5 m-auto `}
          />
        )}
        {!isDisabled ? buttonText : null}

        {isDisabled && (
          <div
            style={{ height: '22px' }}
            className={`w-full overflow-hidden flex justify-center items-center`}
          >
            <img width={60} height={30} src={Loader} />
          </div>
        )}
      </button>
    </div>
  );
};

Button.propTypes = {};

export { Button };
