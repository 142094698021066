import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParticipantsStore } from "../../store/participants/participants";
import ReactModal from "react-modal";
import { useZoomStore } from "../../store/zoom/zoom";
import { Button } from "../Button/Button";

const customStyles = {
  content: {
    top: "70px",
    left: "50%",
    right: "auto",
    bottom: "0%",
    transform: "translate(-50%, -10%)",
    background: "#191427",
    border: "none",
    width: "70%",
    maxWidth: "1260px",
    borderRadius: "10px",
  },
  overlay: {
    background: "rgba(0,0,0,0.2)",
  },
};

const MeetingPreviewModal = ({ handleNext }) => {
  let {} = useParticipantsStore();
  let { client, uitoolkit } = useZoomStore();

  useEffect(() => {
    let previewContainer = document.getElementById("preview");

    uitoolkit.openPreview(previewContainer);
    return () => {
      uitoolkit.closePreview(previewContainer);
    };
  }, [uitoolkit]);

  return (
    <div className="">
      <div className="flex justify-end">
        <Button
          onClick={handleNext}
          isActive={true}
          classes={"w-[100px] cursor-pointer"}
          buttonText={"Next"}
        />
      </div>
      <div
        style={{ height: "600px", width: "100%" }}
        className="relative flex justify-center items-center"
      >
        <div id="preview"></div>
      </div>
    </div>
  );
};

MeetingPreviewModal.propTypes = {};

export { MeetingPreviewModal };
