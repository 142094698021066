import React, { useEffect, useState } from 'react';

import { Book, Github, Lock, Unlock } from 'lucide-react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IdentifierType } from '../../config/constants/identifiers';
import { useAccountStore } from '../../store/account/account';
import { useAuthStore } from '../../store/auth/auth';
import { useIdentifierStore } from '../../store/identifier/identifier';
import { usePlanStore } from '../../store/plans/plans';
import { useRepositoriesStore } from '../../store/repositories/repositories';
import { Select } from '../Select/Select';
import ConnectRepositories from './ConnectRepository';

const dummyRepos = [
  {
    id: 1,
    name: 'awesome-project',
    description: 'A really cool project that does amazing things',
    language: 'JavaScript',
    isPrivate: false,
  },
  {
    id: 2,
    name: 'secret-sauce',
    description: 'Our proprietary algorithm for making things awesome',
    language: 'Python',
    isPrivate: true,
  },
  {
    id: 3,
    name: 'website',
    description: 'Our company website',
    language: 'TypeScript',
    isPrivate: false,
  },
];

let githubRepositories = [
  { label: 'repository one' },
  { label: 'repository two' },
  { label: 'repository three' },
];

const Repositories = (props) => {
  let { fetchTokenPlan } = usePlanStore();
  let {
    fetchAccountRepositories,
    fetchGithubRepositories,
    fetchingAccountRepositories,
    accountRepositories,
    registerRepository,

    userGithubRepositories,
    userGithubRepositoriesMapped,
  } = useRepositoriesStore();

  let { registerCred, isCredsRegistered } = useIdentifierStore();
  let { account } = useAuthStore();
  let { getTrialTokens } = useAccountStore();

  const [isRepositoryConnected, setIsRepositoryConnected] = useState(true);
  const [hasRepos, setHasRepos] = useState(false);
  const [selectedRepository, setSelectedRepository] = useState(null);
  const [registeringRepository, setRegisteringRepository] = useState(false);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    fetchRepositoryInfo();
  }, []);

  let fetchRepositoryInfo = async () => {
    setLoading(true);
    let code = searchParams.get('code');
    if (code) {
      let Payload = {
        IdentifierType: IdentifierType.GITHUB,
        Secret: code,
      };
      await registerCred(Payload);
      getTrialTokens();
      navigate('/repositories');
      setLoading(false);
      // return;
    }
    await fetchGithubRepositories();
    let isConnectedWithRepository = await isCredsRegistered({
      identifierType: IdentifierType.GITHUB,
    });
    if (!isConnectedWithRepository) {
      setIsRepositoryConnected(false);
      setLoading(false);
      return;
    }

    await fetchAccountRepositories();
    setLoading(false);
  };

  const filterColors = (inputValue = '') => {
    if (!inputValue.length) {
      return userGithubRepositoriesMapped;
    }
    return userGithubRepositoriesMapped.filter((data) =>
      data.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptions = (inputValue, callback) => {
    // setTimeout(() => {
    // }, 1000);
    callback(filterColors(inputValue));
  };

  let handleSelectRepository = (selectedRepo) => {
    setSelectedRepository(() =>
      userGithubRepositories.find((data) => data.Name === selectedRepo.label)
    );
  };

  let handleRegisterRepository = async () => {
    if (!selectedRepository || registeringRepository) {
      return;
    }
    setRegisteringRepository(true);
    let payload = {
      AccountId: account._id,
      Name: selectedRepository.Name,
      FullName: selectedRepository.Name,
      IsPrivate: selectedRepository.Private,
      Description: selectedRepository.Description,
      Language: selectedRepository.Language,
    };

    await registerRepository(payload);
    setRegisteringRepository(false);
    setSelectedRepository(null);

    await fetchRepositoryInfo();
  };

  let navigateToCreditsPage = async () => {
    let plan = await fetchTokenPlan();
    navigate(`/buyTokens/${plan._id}`);
  };

  let LoadingRepositories = () => {
    return (
      <>
        <div className="min-h-[calc(100vh-100px)] bg-[#191427] p-8">
          <div className="max-w-6xl mx-auto">
            <div className="space-y-6">
              <div className="flex justify-between w-[100%]">
                <div className="flex justify-start gap-x-4 items-center w-[600px]">
                  {/* <div className="flex-grow bg-red-400 h-[37px] w-[5/7] text-left"></div> */}
                  <Skeleton width={350} height={37} />
                  <Skeleton width={120} height={37} />
                </div>
                <Skeleton width={150} height={37} />
              </div>

              <h2 className="text-3xl font-bold text-white">
                <Skeleton width={270} height={37} />
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                <Skeleton width={'100%'} height={112} />
                <Skeleton width={'100%'} height={112} />
                <Skeleton width={'100%'} height={112} />
                {/* <div className="cursor-pointer h-[112px] bg-[#21183C] rounded-md p-6 text-white shadow-lg transition duration-300 hover:shadow-xl hover:bg-[#2a1e4d]"></div> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {!isRepositoryConnected ? (
        <ConnectRepositories />
      ) : loading ? (
        <LoadingRepositories />
      ) : (
        <div className="min-h-[calc(100vh-100px)] bg-[#191427] p-8">
          <div className="max-w-6xl mx-auto">
            {!accountRepositories?.length ? (
              <div className="rounded-lg p-8 text-center">
                {/* <GitBranch className="w-16 h-16 mx-auto mb-4 text-white" /> */}
                <Github className="w-[160px] h-[160px] mx-auto mb-4 text-[#fafbfc]" />
                <h1 className="text-2xl md:text-5xl lg:text-4xl font-bold mb-4">
                  Connect Your Repository And Get Started!
                </h1>
                <p className="text-xl md:text-2xl text-gray-600 mb-8">
                  Get a review on your repositories with our AI Code Reviewer
                </p>
                <div className="flex justify-center gap-x-4 items-center max-w-[600px] mx-auto">
                  <div className="flex-grow w-[5/7] text-left">
                    <Select
                      handleAttendees={handleSelectRepository}
                      defaultOptions={userGithubRepositoriesMapped}
                      loadOptions={loadOptions}
                    />
                  </div>
                  <button
                    onClick={handleRegisterRepository}
                    className={`${
                      selectedRepository && !registeringRepository
                        ? 'bg-[#703BF7] text-white hover:scale-105'
                        : 'bg-[#353440] text-[#f6f6f6]'
                    } px-6 h-[37.5px] py-auto rounded-md ease-in-out transition duration-300`}
                  >
                    Add a Repository
                  </button>
                </div>
              </div>
            ) : (
              <div className="space-y-6">
                <div className="flex justify-between w-[100%]">
                  <div className="flex justify-start gap-x-4 items-center w-[600px]">
                    <div className="flex-grow w-[4/7] text-left">
                      <Select
                        handleAttendees={handleSelectRepository}
                        defaultOptions={userGithubRepositoriesMapped}
                        loadOptions={loadOptions}
                      />
                    </div>
                    <button
                      onClick={handleRegisterRepository}
                      className={`${
                        selectedRepository && !registeringRepository
                          ? 'bg-[#703BF7] text-white hover:scale-105'
                          : 'bg-[#353440] text-[#f6f6f6]'
                      } px-6 h-[37.5px] py-auto rounded-md ease-in-out transition duration-300`}
                    >
                      Add a Repository
                    </button>
                  </div>
                  <button
                    onClick={navigateToCreditsPage}
                    className={`${'bg-[#703BF7] text-white hover:scale-105'} px-6 h-[37.5px] py-auto rounded-md ease-in-out transition duration-300`}
                  >
                    Buy Credits
                  </button>
                </div>

                <h2 className="text-3xl font-bold text-white">
                  Your Repositories
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {accountRepositories.map((repo) => (
                    <div
                      onClick={() => navigate(`${repo._id}`)}
                      key={repo._id}
                      className="cursor-pointer bg-gradient-to-r from-[#21183C] to-[#2a1e4d] rounded-lg p-6 text-white shadow-lg transition-transform duration-300 hover:scale-105 hover:shadow-xl"
                    >
                      {/* Header Section */}
                      <div className="flex justify-between items-center mb-4">
                        <div className="flex items-start">
                          <Book className="w-6 h-6 mr-2 text-indigo-400" />
                          <h3 className="text-lg font-semibold leading-tight">
                            {repo.Name}
                          </h3>
                        </div>
                        <div>
                          {repo.Private ? (
                            <Lock className="w-5 h-5 text-gray-400" />
                          ) : (
                            <Unlock className="w-5 h-5 text-green-400" />
                          )}
                        </div>
                      </div>

                      {/* Description */}
                      {/* <p className="text-gray-300 mb-4 text-sm leading-relaxed">
                        {repo.Description}
                      </p> */}

                      {/* Footer Section */}
                      <div className="flex items-center text-sm text-gray-400">
                        <div className="w-3 h-3 rounded-full bg-yellow-400 mr-2"></div>
                        <span>{repo.Language}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

Repositories.propTypes = {};

export { Repositories };
