import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ByteBattleActiveV2 from '../../assets/png/ByteBattleIconActiveV2.png';
import ByteBattleV2 from '../../assets/png/ByteBattleV2.png';

import DashboardIconActiveV2 from '../../assets/png/DashboardIconActiveV2.png';
import DashboardIconV2 from '../../assets/png/DashboardIconV2.png';

import EducatorsIconV2 from '../../assets/png/EducatorsIconV2.png';
import EducatorsIconActiveV2 from '../../assets/png/ProfessionalIconActiveV2.png';

import LogoBg from '../../assets/png/icon.png';
import PremiumIconV2 from '../../assets/png/PremiumIconV2.png';

import QuickByteIconActiveV2 from '../../assets/png/QuickBiteIconActiveV2.png';
import QuickByteIconV2 from '../../assets/png/QuickByteIconV2.png';

import SettingIconV2 from '../../assets/png/SettingIconV2.png';
import { useAuthStore } from '../../store/auth/auth';

import { Github } from 'lucide-react';

const scaleFactor = 0.75;

const Items = [
  {
    name: 'DASHBOARD',
    tabName: 'Dashboard',
    width: `${30 * scaleFactor}px`, // Results in 18px
    height: `${30 * scaleFactor}px`, // Results in 18px
    route: '/',
    icon: false,
    img: DashboardIconV2,
    activeImage: DashboardIconActiveV2,
    new: false,
    tooltipContent: 'Dashboard',
    id: 'sidebar-dashboard-icon',
  },
  {
    name: 'QUICK_BITES',
    tabName: 'Sessions',
    width: `${19 * scaleFactor}px`, // Results in 11.4px
    height: `${31 * scaleFactor}px`, // Results in 18.6px
    new: false,
    route: '/quick-bites',
    icon: false,
    img: QuickByteIconV2,
    activeImage: QuickByteIconActiveV2,
    tooltipContent: 'Quick Bites',
    id: 'sidebar-quick-bites-icon',
  },
  {
    name: 'BITE_BATTLE',
    tabName: 'Problems',
    width: `${31 * scaleFactor}px`, // Results in 18.6px
    height: `${28 * scaleFactor}px`, // Results in 16.8px
    new: false,
    route: '/problems',
    icon: false,
    img: ByteBattleV2,
    activeImage: ByteBattleActiveV2,
    tooltipContent: 'Byte Battle',
    id: 'sidebar-byte-battle-icon',
  },
  {
    name: 'CREATORS',
    tabName: 'Educators',
    width: `${32 * scaleFactor}px`, // Results in 19.2px
    height: `${34 * scaleFactor}px`, // Results in 20.4px
    route: '/creators',
    icon: false,
    img: EducatorsIconV2,
    activeImage: EducatorsIconActiveV2,
    new: false,
    tooltipContent: 'Professionals',
    id: 'sidebar-settings-icon',
  },

  {
    name: 'CODE_REVIEW',
    tabName: 'AI Analyzer',
    width: `${32 * scaleFactor}px`, // Results in 19.2px
    height: `${34 * scaleFactor}px`, // Results in 20.4px
    route: '/repositories',
    icon: true,
    img: (
      <div className="text-[22px]">
        {' '}
        <Github className={`text-[#918D9A]`} />{' '}
      </div>
    ),
    activeImage: (
      <div className="text-[22px]">
        {' '}
        <Github className={`text-[#9B75F9]`} />{' '}
      </div>
    ),
    new: false,
    tooltipContent: 'Professionals',
    id: 'sidebar-settings-icon',
  },
];
const ItemLower = [
  {
    name: 'PREMIUM',
    width: `${30 * scaleFactor}px`,
    height: `${30 * scaleFactor}px`,
    route: '/account/billing/plans',
    icon: false,
    img: PremiumIconV2,
    new: false,
    tooltipContent: 'Get Premium',
    id: 'sidebar-settings-icon',
  },
  {
    name: 'ACCOUNT_SETTING',
    width: `${30 * scaleFactor}px`,
    height: `${27 * scaleFactor}px`,
    route: '/account/meeting',
    icon: false,
    img: SettingIconV2,
    new: false,
    tooltipContent: 'Settings',
    id: 'sidebar-settings-icon',
  },
];

const MobileItems = [
  {
    name: 'DASHBOARD',
    tabName: 'Dashboard',
    width: `${30 * scaleFactor}px`, // Results in 18px
    height: `${30 * scaleFactor}px`, // Results in 18px
    route: '/',
    icon: false,
    img: DashboardIconV2,
    activeImage: DashboardIconActiveV2,
    new: false,
    tooltipContent: 'Dashboard',
    id: 'sidebar-dashboard-icon',
  },
  {
    name: 'QUICK_BITES',
    tabName: 'Sessions',
    width: `${19 * scaleFactor}px`, // Results in 11.4px
    height: `${31 * scaleFactor}px`, // Results in 18.6px
    new: false,
    route: '/quick-bites',
    icon: false,
    img: QuickByteIconV2,
    activeImage: QuickByteIconActiveV2,
    tooltipContent: 'Quick Bites',
    id: 'sidebar-quick-bites-icon',
  },
  {
    name: 'BITE_BATTLE',
    tabName: 'Problems',
    width: `${31 * scaleFactor}px`, // Results in 18.6px
    height: `${28 * scaleFactor}px`, // Results in 16.8px
    new: false,
    route: '/problems',
    icon: false,
    img: ByteBattleV2,
    activeImage: ByteBattleActiveV2,
    tooltipContent: 'Byte Battle',
    id: 'sidebar-byte-battle-icon',
  },
  {
    name: 'CREATORS',
    tabName: 'Educators',
    width: `${32 * scaleFactor}px`, // Results in 19.2px
    height: `${34 * scaleFactor}px`, // Results in 20.4px
    route: '/creators',
    icon: false,
    img: EducatorsIconV2,
    activeImage: EducatorsIconActiveV2,
    new: false,
    tooltipContent: 'Professionals',
    id: 'sidebar-settings-icon',
  },
];

let quickBitesActiveTabs = ['quick-bites', 'quiz', 'questions'];
let reportActiveTabs = ['weekly-performance'];

export default function Sidebar(props) {
  const [activeTab, setActiveTab] = useState();
  let navigate = useNavigate();
  const location = useLocation();

  let { planInfo } = useAuthStore();

  useEffect(() => {
    getScreenStyle();
    return () => {};
  });

  function handleNavigator(route) {
    navigate(route);
  }

  let getScreenStyle = () => {
    if (getScreenWidth() > 768) {
      return handleActiveTab();
    } else {
      // return handleMobileActiveTab();
      return handleActiveTab();
    }
  };

  let handleMobileActiveTab = () => {
    let accountRoute = location.pathname.includes('/account');
    if (quickBitesActiveTabs.includes(location.pathname.split('/')[1])) {
      setActiveTab('QUICK_BITES');
    } else if (
      reportActiveTabs.includes(location.pathname.split('/')[1]) ||
      location.pathname === '/'
    ) {
      setActiveTab('DASHBOARD');
    } else if (accountRoute) {
      if (location.pathname.includes('/account/meeting')) {
        setActiveTab('MEETINGS');
        return;
      }
      setActiveTab('DISCUSSIONS');
    } else if (
      ['creator', 'creators'].includes(location.pathname.split('/')[1])
    ) {
      setActiveTab('CREATORS');
    } else if (
      ['repositories', 'repositories'].includes(location.pathname.split('/')[1])
    ) {
      setActiveTab('CODE_REVIEW');
    }
  };

  let handleActiveTab = () => {
    let accountRoute = location.pathname.includes('/account');
    if (quickBitesActiveTabs.includes(location.pathname.split('/')[1])) {
      setActiveTab('QUICK_BITES');
    } else if (['problems'].includes(location.pathname.split('/')[1])) {
      setActiveTab('BITE_BATTLE');
    } else if (
      reportActiveTabs.includes(location.pathname.split('/')[1]) ||
      location.pathname === '/'
    ) {
      // setActiveTab("REPORT");
      setActiveTab('DASHBOARD');
    } else if (accountRoute) {
      setActiveTab('ACCOUNT_SETTING');
      // now I need to add another check for creator, creators route
    } else if (
      ['creator', 'creators'].includes(location.pathname.split('/')[1])
    ) {
      setActiveTab('CREATORS');
    } else if (
      ['repositories', 'repositories'].includes(location.pathname.split('/')[1])
    ) {
      setActiveTab('CODE_REVIEW');
    }
  };

  function getScreenWidth() {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }

  function handleItems() {
    if (getScreenWidth() > 768) {
      return Items;
    } else {
      return MobileItems;
    }
  }

  return (
    <>
      <div className="sidebar fixed top-0 left-0 flex justify-between items-center flex-col h-[100vh] w-[62px] md:w-[70px] py-10">
        <div className="flex flex-col items-center w-[100%] h-fit pt-4 gap-y-2 md:gap-y-[15px]">
          <div className="">
            <img
              style={{
                height: `${50 * scaleFactor}px`,
                width: `${50 * scaleFactor}px`,
              }}
              src={LogoBg}
              alt="Logo"
            />
          </div>

          <div className="flex flex-col items-center w-[100%] h-fit pt-4 gap-y-2 md:gap-y-1">
            <div className="flex flex-col items-center h-fit pt-4 w-[100%] gap-y-[25px] md:gap-y-[18px]">
              {handleItems().map((item) => (
                <div
                  onClick={() => handleNavigator(item.route)}
                  className="w-[100%] flex flex-col items-center gap-y-1"
                >
                  <div
                    data-tooltip-id={item.id}
                    data-tooltip-content={item.tooltipContent}
                    className={`relative sidebar-icon mx-auto p-2 rounded-lg h-[36px] md:w-[42px] md:h-[42px] cursor-pointer flex justify-center items-center transition-transform duration-300 ease-in-out transform ${
                      item.name === 'DASHBOARD' ? '' : ''
                    } ${
                      activeTab === item.name
                        ? 'bg-[#2A1C50] scale-110'
                        : 'hover:scale-125'
                    }`}
                  >
                    {/* <Tooltip id={item.id} /> */}
                    {!item.icon ? (
                      <img
                        style={{ height: item.height, width: item.width }}
                        src={
                          activeTab === item.name ? item.activeImage : item.img
                        }
                        alt="Logo"
                      />
                    ) : activeTab === item.name ? (
                      item.activeImage
                    ) : (
                      item.img
                    )}
                    {item.new ? (
                      <div className="absolute top-1 right-0 bg-gradient-to-r from-purple-500 to-indigo-500 text-white text-xs font-normal px-2 py-0.5 rounded-lg transform translate-x-1/2 -translate-y-1/2 shadow-lg">
                        New
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={`text-[9px] font-semibold cursor-pointer ${
                      activeTab === item.name ? 'text-white' : 'text-[#918D9A]'
                    }`}
                  >
                    {item.tabName}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="hidden md:flex flex-col items-center h-fit pt-4 w-[100%] gap-y-2 md:gap-y-[18px]">
          {ItemLower.map((item) => (
            <>
              {item.name !== 'PREMIUM' || planInfo?.PlanCategory == 0 ? (
                <div
                  onClick={() => handleNavigator(item.route)}
                  className="w-[100%] flex"
                >
                  {/* <div
                className={`active-section w-[4px] h-full ${
                  activeTab == item.name
                    ? "block bg-gradient-to-b from-purple-500 via-[#8254F8] to-purple-500 bg-clip-border"
                    : "hidden"
                }`}
              ></div> */}
                  <div
                    data-tooltip-id={item.id}
                    data-tooltip-content={item.tooltipContent}
                    className={`relative sidebar-icon mx-auto p-2 rounded-lg h-[36px] md:w-[48px] md:h-[48px] cursor-pointer flex justify-center items-center ${
                      item.name == 'DASHBOARD' ? '' : ''
                    } ${activeTab == item.name ? 'bg-[#2A1C50]' : ''}`}
                  >
                    {/* <Tooltip id={item.id} /> */}
                    {!item.icon ? (
                      <img
                        style={{ height: item.height, width: item.width }}
                        src={item.img}
                        alt="Logo"
                      />
                    ) : (
                      item.img
                    )}
                    {item.new ? (
                      <div className="absolute top-1 right-0 bg-gradient-to-r from-purple-500 to-indigo-500 text-white text-xs font-normal px-2 py-0.5 rounded-lg transform translate-x-1/2 -translate-y-1/2 shadow-lg">
                        New
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          ))}
        </div>
      </div>
    </>
  );
}

// export { Sidebar };
