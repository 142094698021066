import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import KnockOuts from '../../assets/png/knockOuts.png';
import SessionsBadge from '../../assets/png/SessionsBadge.png';

import { ArrowRight, Zap } from 'lucide-react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { Tooltip } from 'react-tooltip';
import { PostTypeEnum } from '../../config/constants/linkedIn';
import {
  inMinutes,
  monthsAndDaysFormat,
} from '../../config/helpers/moment-helper';
import { useAccountStore } from '../../store/account/account';
import { useAuthStore } from '../../store/auth/auth';
import { useQuizReportStore } from '../../store/quizAnswer/quizAnswer';
import { useQuizPerformanceStore } from '../../store/quizPerformance/quizPerformance';
import { useQuizProfileStore } from '../../store/quizProfile/quizProfile.js';
import { GraphBarChart } from '../Charts/BarChart/BarChart.jsx';
import { PlanningModal } from '../PlanningModal/PlanningModal';
import { QuizProfileCard } from '../QuizProfileCard/QuizProfileCard';
import StreakCardList from '../StreakList/StreakList';
import { Tag } from '../Tag/Tag';
const WeekPerformance = (props) => {
  let {
    activityRecord,
    getNextActivities,
    fetchingActivityRecord,
    getStreakCount,
    streak,
    getActivityRecord,
  } = useQuizReportStore();
  let { challengesReport, getQuizChallenges } = useQuizPerformanceStore();
  let { account } = useAuthStore();
  let { generateLinkedInPost } = useAccountStore();
  let { quizProfile } = useQuizProfileStore();

  const [openPlanningModal, setOpenPlanningModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activityLoading, setActivityLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    let code = searchParams.get('code');
    if (code && code.length) {
      let quizId = localStorage.getItem('QuizId');
      let postType = localStorage.getItem('PostType');
      let queryParams = {
        QuizId: 0,
        PostType: PostTypeEnum.BecomeACreator,
      };
      if (quizId && postType) {
        queryParams = {
          QuizId: quizId,
          PostType: postType,
        };
      }
      localStorage.removeItem('QuizId');
      localStorage.removeItem('PostType');
      generateLinkedInPost(code, queryParams);
      navigate(`/`);
    }
  }, []);

  useEffect(() => {
    (async () => {
      setActivityLoading(true);
      setLoading(true);
      try {
        await getActivityRecord();
        await fetchLastActivities();
        await fetchQuizChallenges();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        setTimeout(() => {
          setActivityLoading(false);
        }, 2000);
      }
    })();
  }, []);

  let fetchLastActivities = async () => {
    await getNextActivities();
  };

  let fetchQuizChallenges = async () => {
    await getQuizChallenges();
  };

  let colorCustomization = {
    activity0: '#161B22',
    activity1: '#9d33e3',
    activity2: '#953ae9',
    activity3: '#8b4ce0',
    activity4: '#B210FD',
  };

  const sampleData = [
    {
      day: '2024-07-27',
      activity: 5,
    },
    {
      day: '2024-08-02',
      activity: 1,
    },
  ];

  let totalActivities = () => {
    return activityRecord?.reduce((acc, curr) => {
      return acc + curr.count;
    }, 0);
  };
  return (
    <div className="max-w-[1280px] relative mx-auto grid grid-cols-3 lg:grid-cols-4 overflow-x-hidden overflow-y-hidden px-4 md:px-10 gap-5">
      <PlanningModal
        handleCloseModal={setOpenPlanningModal}
        isOpen={openPlanningModal}
      />

      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 col-span-3 md:col-span-2 lg:col-span-3 gap-x-5 gap-y-5 items-start">
        {/* Activity bar here. */}
        <div className="col-span-2 sm:col-span-2 lg:col-span-3">
          {/* xl:w-[775px]  max-w-[775px] */}
          <div className="rounded-md mx-auto overflow-x-auto w-[100%] scroll-bar">
            <div className="flex justify-center flex-col">
              <div className="flex justify-between items-center">
                {!loading ? (
                  <div className="hidden sm:block w-[100%] gap-x-2 items-start bg-[#21183C] rounded-md p-2">
                    <div className="grid grid-cols-1 col-span-10 gap-x-2 items-start bg-[#21183C] rounded-md p-2">
                      <div className="flex text-xs lg:text-sm justify-between text-white items-center">
                        <div className="text-xs font-light">
                          {!loading ? (
                            `${totalActivities()} sessions completed in the last 30 days. `
                          ) : (
                            <Skeleton width={180} />
                          )}
                        </div>

                        <div className="text-xs font-light bg-[#191427] py-1 px-3 rounded-xl">
                          {!loading ? (
                            <>
                              Total Appearances:{' '}
                              <span className="font-semibold">
                                {' '}
                                {activityRecord.length}{' '}
                              </span>{' '}
                            </>
                          ) : (
                            <Skeleton width={180} />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-10 col-span-10 gap-x-2 gap-y-5 items-start bg-[#21183C] rounded-md p-2">
                      <StreakCardList />
                    </div>
                  </div>
                ) : (
                  <div className="w-full">
                    <Skeleton width={'100%'} height={120} />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* <div className="grid grid-cols-1   md:grid-cols-2 my-4 gap-x-2 gap-y-3">
            <div className="">
              <ByteBattleDashboardCard />
            </div>
            <div className="">
              <QuicKBitesDashboardCard />
            </div>
          </div> */}

          <div className="grid grid-cols-1 lg:grid-cols-3 my-3 gap-y-3 gap-x-2">
            <div className="hidden md:block md:col-span-1 lg:col-span-2 h-[250px]">
              {/*   let { activityRecord, fetchingActivityRecord } = useQuizReportStore();
               */}
              <div className="bg-[#21183C] h-[100%] text-white p-6 rounded-lg shadow-lg flex items-center justify-between">
                <div className="flex-1">
                  <h2 className="text-2xl font-bold mb-2">
                    Evaluate Your Repositories
                  </h2>
                  <p className="text-gray-300 mb-4">
                    Unlock <span className="font-bold"> 15,000 </span> tokens by
                    connecting your remote repository now and supercharge your
                    potential!
                  </p>
                  <button
                    onClick={() => navigate('/repositories')}
                    className="bg-[#703BF7] text-white py-2 px-4 rounded-full font-semibold flex items-center hover:bg-opacity-90 transition-all duration-300"
                  >
                    Evaluate Now
                    <ArrowRight className="ml-2 h-5 w-5" />
                  </button>
                </div>
                <div className="flex flex-col items-center ml-6">
                  <Zap className="h-8 w-8 text-yellow-400 mb-2" />
                  <span className="text-lg font-semibold">
                    {account?.DigitalTokens || '00'}
                  </span>
                  <span className="text-sm text-gray-300">AI Credits</span>
                </div>
              </div>
            </div>
            <div className="col-span-1 bg-[#21183C] text-white p-3 rounded-md">
              <div className="text-xl font-normal text-[16px] flex justify-between items-center gap-x-2">
                <span> Knockouts </span>
                <span
                  data-tooltip-id="knockout-info2"
                  data-tooltip-content="Sessions with 100% efficiency"
                >
                  <Tooltip id="knockout-info2" />
                  <IoIosInformationCircleOutline className="text-md cursor-pointer" />
                </span>
              </div>
              <div className="h-full flex justify-center items-center">
                <div className="w-[85%] mx-auto bg-[#2A1C50] mb-10 py-5 rounded-full flex items-center justify-center gap-x-4">
                  <img className="h-[70px] w-[95px]" src={KnockOuts} />
                  <div className="flex items-center justify-center flex-col font-normal">
                    <div className="text-4xl">
                      {quizProfile?.KnockOuts || '00'}
                    </div>
                    <div className="text-xs">Knockouts</div>
                  </div>
                </div>
              </div>
            </div>

            {/* second row */}

            <div className="col-span-1 lg:col-span-2">
              {/*   let { activityRecord, fetchingActivityRecord } = useQuizReportStore();
               */}
              <GraphBarChart
                title="Daily Performance"
                activities={activityRecord}
                loading={fetchingActivityRecord}
                days={15}
                ticks={[]}
              />
            </div>
            <div className="col-span-1 bg-[#21183C] text-white p-3 rounded-md">
              <div className="text-xl font-normal text-[16px] ">Sessions</div>
              <div className="h-full flex justify-center items-center">
                <div className="w-[85%] mx-auto bg-[#2A1C50] mb-10 py-5 rounded-full flex items-center justify-center gap-x-4">
                  <img className="h-[70px] w-[95px]" src={SessionsBadge} />
                  <div className="flex items-center justify-center flex-col font-normal">
                    <div className="text-4xl">
                      {!loading ? `${totalActivities()}` : '00'}
                    </div>
                    <div className="text-xs">Sessions</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!loading ? (
            <>
              {challengesReport?.length > 0 ? (
                challengesReport.map((data) => (
                  <>
                    <div className="rounded-lg mx-auto my-4 p-4 bg-[#21183C] w-full  shadow-lg flex flex-col justify-between">
                      {/* Header with Date Range and Efficiency */}
                      <div className="flex justify-between items-baseline mb-3">
                        <div className="">
                          <div className="text-white text-lg sm:text-2xl font-semibold">
                            {monthsAndDaysFormat(data.StartDate)} -{' '}
                            {monthsAndDaysFormat(data.EndDate)}
                          </div>
                          <div className="flex items-center gap-x-2 mt-2 text-xs sm:text-sm text-white font-semibold mb-1">
                            {data.QuizCategoriesTitle.map((data) => (
                              <Tag
                                text={data}
                                active={true}
                                classes="text-xs"
                              />
                            ))}
                          </div>
                        </div>
                        <div className="flex justify-end items-center">
                          <div className="text-3xl sm:text-5xl font-bold text-[#B310FD]">
                            {data.Efficiency}
                          </div>
                        </div>
                      </div>

                      {/* Stats */}
                      <div className="grid grid-cols-2 sm:grid-cols-3 gap-x-6 gap-y-2">
                        {/* Time Spent */}
                        <div className="hidden sm:flex col-span-1 flex-col items-start">
                          <div className="text-sm text-gray-400">
                            Time Spent
                          </div>
                          <div className="text-lg font-semibold text-white">
                            {inMinutes(data.TotalTimeOnLearning)} Minutes
                          </div>
                        </div>

                        {/* Sessions Completed */}
                        <div className="col-span-1 flex flex-col items-start">
                          <div className="text-sm text-gray-400">
                            Sessions Completed
                          </div>
                          <div className="text-lg font-semibold text-white">
                            {data.SessionsCompleted} / {data.TotalSessions}
                          </div>
                        </div>

                        {/* Knockouts */}
                        <div className="col-span-1 flex flex-col items-start">
                          <div className="text-sm text-gray-400">Knockouts</div>
                          <div className="text-lg font-semibold text-white">
                            {data.KnockOuts}
                          </div>
                        </div>
                      </div>

                      <div className="flex items-end gap-x-2">
                        {/* Progress Bar */}
                        <div className="flex-grow">
                          <div className="text-sm text-gray-400">Progress</div>
                          <div className="w-full bg-gray-700 rounded-full h-3 mt-2">
                            <div
                              className="h-3 rounded-full"
                              style={{
                                width: data.Efficiency,
                                background:
                                  'linear-gradient(to right, #D69CFF, #B310FD)',
                              }}
                            ></div>
                          </div>
                        </div>

                        {/* Updated "View More" Button */}
                        <div className="hidden md:flex justify-end mt-4">
                          <Link
                            to={`/weekly-performance/${account?._id}?startDate=${data?.StartDate}&endDate=${data?.EndDate}`}
                            className="text-sm bg-gradient-to-r from-[#B310FD] to-[#9D0ECB] text-white px-5 py-2 rounded-lg shadow-lg hover:scale-105 transform transition duration-300 ease-in-out"
                          >
                            View More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <></>
                // <div className="mt-5 flex justify-center flex-col items-center xl:w-[775px] max-w-[775px] mx-auto">
                //   <div className="text-2xl font-bold text-center">
                //     Your Journey Starts with Weekly Challenges!
                //   </div>
                //   <div className="text-medium my-4 text-center">
                //     Plan your week and set yourself up for success. Our 4-week
                //     plan sharpens both your tech and soft skills from coding to
                //     communication, leadership to problem-solving. With every
                //     challenge, you’ll track your growth, stay accountable, and
                //     build a profile that recruiters can’t ignore. It’s more than
                //     learning; it’s about showcasing your future potential to
                //     employers.
                //   </div>
                //   <div
                //     onClick={() => setOpenPlanningModal(true)}
                //     className="w-[150px] cursor-pointer text-center text-sm font-semibold bg-[#fff] text-black py-1 rounded-full shadow-sm hover:shadow-lg transition"
                //   >
                //     Plan Your Success!
                //   </div>
                // </div>
              )}
            </>
          ) : (
            <>
              <div className="w-full my-4">
                <Skeleton width={'100%'} height={190} />
              </div>
              <div className="w-full my-4">
                <Skeleton width={'100%'} height={190} />
              </div>
            </>
          )}
        </div>
      </div>

      <div className="hidden md:flex flex-col gap-y-5 col-span-4 md:col-span-1">
        <div className="overflow-hidden rounded-md bg-[#21183C]">
          {/* type={QuizProfileCardType.REPORT} */}
          <QuizProfileCard />
        </div>
      </div>
    </div>
  );
};

export const ByteBattleDashboardCard = () => {
  let { lastWeekActivities, fetchingActivityRecord, nextActivities } =
    useQuizReportStore();

  let navigate = useNavigate();
  return (
    <>
      {lastWeekActivities.biteBattle.length == 0 ? (
        <div className="bg-gradient-to-r from-[#2A1F48] to-[#1B1534] h-[100%] p-6 rounded-lg shadow-lg text-white space-y-4">
          <div className="text-2xl font-extrabold">Byte Battle</div>
          <div className="text-sm leading-relaxed">
            Byte Battle offers a series of challenging problems designed to
            <strong> improve your coding skills</strong>. It features{' '}
            <strong>Peer Programming </strong> for collaborative problem-solving
            and <strong>Real-Time Code Reviews</strong> to help you become a
            better problem solver—<strong>improving 95% faster</strong> than
            other platforms.
          </div>
          <div className="flex justify-end">
            <Link
              to="problems"
              className="bg-[#5B21B6] hover:bg-[#4C1D95] px-4 py-2 rounded-md font-semibold text-sm"
            >
              Join the Battle
            </Link>
          </div>
        </div>
      ) : (
        <GraphBarChart
          activities={lastWeekActivities.biteBattle}
          loading={fetchingActivityRecord}
          ticks={[]}
          title="Byte Battle Last Week Progress"
          days={7}
          showButton={true}
          graphClass="h-[200px]"
          onClick={() => navigate(`problems/${nextActivities.ProblemForUser}`)}
        />
      )}
    </>
  );
};

export const QuicKBitesDashboardCard = () => {
  let { lastWeekActivities, fetchingActivityRecord, nextActivities } =
    useQuizReportStore();

  let navigate = useNavigate();
  return (
    <>
      {lastWeekActivities.quickBites.length == 0 ? (
        <div className="bg-gradient-to-r from-[#1B1534] to-[#2A1F48] h-[100%] p-6 rounded-lg shadow-lg text-white space-y-4">
          <div className="text-2xl font-extrabold">Quick Bites</div>
          <div className="text-sm leading-relaxed">
            Enjoy a streamlined learning experience:{' '}
            <strong>grasp essential concepts</strong> in engaging sessions,{' '}
            <strong>test your understanding with a quiz </strong>, and proudly{' '}
            <strong> share achievements with your network </strong>. Impress
            recruiters with a
            <strong> profile that highlights your progress and skills </strong>{' '}
            for global opportunities.
          </div>
          <div className="flex justify-end">
            <Link
              to="quick-bites"
              className="bg-[#5B21B6] hover:bg-[#4C1D95] px-4 py-2 rounded-md font-semibold text-sm"
            >
              Explore Now
            </Link>
          </div>
        </div>
      ) : (
        <GraphBarChart
          activities={lastWeekActivities?.quickBites}
          loading={fetchingActivityRecord}
          ticks={[]}
          title="Quick Bites Last Week Progress"
          days={7}
          showButton={true}
          graphClass="h-[200px]"
          onClick={() =>
            navigate(
              nextActivities?.NextQuickBiteSession
                ? `questions/${nextActivities?.NextQuickBiteSession}`
                : `quick-bites`
            )
          }
          buttonText={
            nextActivities?.NextQuickBiteSession
              ? 'Continue Your Journey'
              : 'Start Your Journey'
          }
        />
      )}
    </>
  );
};

WeekPerformance.propTypes = {};

export { WeekPerformance };
