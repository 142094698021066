import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import OTPInput from "react-otp-input";
import { Button } from "../../components/Button/ButtonV2";
import { useAuthStore } from "../../store/auth/auth";
import { useNavigate } from "react-router-dom";
import { AccountStatusConstants } from "../../config/constants/account";
import { Error } from "../../config/helpers/toast-helper";
import { useReferralStore } from "../../store/referral/referral";
import { usePlanStore } from "../../store/plans/plans";

const AccountActivation = (props) => {
  const { getAccountVerification, verifyOTPCode } = useAuthStore();
  let { isReferralExist, isAccountHasReferral } = useReferralStore();
  let { isPlanSubscribed } = usePlanStore();

  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabledResetButton, setDisabledResetButton] = useState(true);

  let navigate = useNavigate();

  useEffect(() => {
    // sendCode()
    setTimeout(() => {
      setDisabledResetButton(false);
    }, 60000);
  }, []);

  let sendCode = async () => {
    try {
      await getAccountVerification();
    } catch (error) {
      console.log(error);
    }
  };

  let resendCode = async () => {
    if (!disabledResetButton) return;
    await sendCode();
  };

  let handleSubmit = async () => {
    try {
      if (code.toString().length !== 4) return;
      setLoading(true);
      let payload = {
        Code: code,
      };
      await verifyOTPCode(payload);
      setLoading(false);
      let planSubscribed = await isPlanSubscribed();
      if (!planSubscribed) {
        navigate("/plans");
        return;
      }
      navigate("/");
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    // <div className={`text-white flex flex-col max-w-[325px]`}>
    //   <div className="text-center my-4">
    //     We've sent a verification code to your email. Please check your inbox.
    //   </div>
    //   <div className="flex-grow">
    //     <OTPInput
    //       className={``}
    //       inputStyle={{
    //         background: "#191427",
    //         border: "0.5px solid white",
    //         borderRadius: "10px",
    //         width: "100%",
    //         height: "60px",
    //         margin: "10px",
    //       }}
    //       value={code}
    //       onChange={setCode}
    //       numInputs={4}
    //       // renderSeparator={<span>-</span>}
    //       renderInput={(props) => <input {...props} />}
    //     />
    //   </div>
    //   <div className="">
    //     <Button
    //       onClick={handleSubmit}
    //       fullWidth={true}
    //       isDisabled={loading}
    //       isActive={code.length === 4}
    //       buttonText={"Verify"}
    //     />
    //   </div>
    //   <div className="mt-3">
    //     <Button
    //       onClick={resendCode}
    //       fullWidth={true}
    //       isActive={!disabledResetButton}
    //       buttonText={"Resend code"}
    //     />
    //   </div>
    // </div>
    <div className={`text-white flex flex-col`}>
      <div className="mb-[60px]">
        <div className="text-[34px] font-medium leading-[54px] text-white">
          Verify Your Email
        </div>
        <div className="font-normal text-[18px] leading-[24px] text-white">
          A verification code has been sent to your email. Please check your
          inbox, and don’t forget to look in your{" "}
          <span className="text-[#9B75F9] hover:underline cursor-pointer">
            spam folder
          </span>{" "}
          if you don’t see it.
        </div>
      </div>
      {/* max-w-[325px] */}
      <div className="flex-grow mb-10  mx-auto">
        <OTPInput
          className={``}
          inputStyle={{
            background: "#191427",
            border: "0px solid white",
            borderRadius: "10px",
            width: "100%",
            height: "90px",
            margin: "10px",
            fontSize: "32px",
            fontWeight: "500",
            backgroundColor: "#21183C",
          }}
          value={code}
          onChange={setCode}
          numInputs={4}
          renderInput={(props) => <input {...props} />}
        />
      </div>
      <div className="">
        <Button
          onClick={handleSubmit}
          fullWidth={true}
          isDisabled={loading}
          isActive={code.length === 4}
          buttonText={"Verify"}
          classes={"text-[18px] font-medium px-7 py-3"}
        />
      </div>
      <div className="mt-3">
        <Button
          onClick={resendCode}
          fullWidth={true}
          isActive={!disabledResetButton}
          disabled={disabledResetButton}
          buttonText={"Resend Code"}
          classes={`text-[18px] font-medium px-7 py-3 ${
            !disabledResetButton ? "bg-[#2A1C50]" : ""
          }`}
        />
      </div>
    </div>
  );
};

AccountActivation.propTypes = {};

export { AccountActivation };
