import { create } from 'zustand';
import { RepoEvaluation } from '../../config/constants/repositoryReviewer';
import {
  AnalyzeCode,
  ConnectAccount,
  FetchAccountRepositories,
  FetchGithubRepositories,
  FetchRepositoryId,
  GetReviewRegisterId,
  RegisterRepository,
} from '../../service/repositories/repositories';

export const useRepositoriesStore = create((set, get) => ({
  accountRepositories: [],
  fetchingAccountRepositories: false,
  userGithubRepositories: [],
  userGithubRepositoriesMapped: [],
  analyzedResponse: null,
  analyzingCode: false,

  async connectAccount(payload) {
    try {
      let connected = await ConnectAccount(payload);
      return connected;
    } catch (error) {
      console.log(error);
    }
  },

  async fetchAccountRepositories() {
    try {
      set({ fetchingAccountRepositories: true });
      let Repositories = await FetchAccountRepositories();
      set({
        accountRepositories: Repositories,
        fetchingAccountRepositories: false,
      });
    } catch (error) {
      console.log(error);
    }
  },

  async fetchGithubRepositories() {
    try {
      let Repositories = await FetchGithubRepositories();
      set({
        userGithubRepositories: Repositories,
        userGithubRepositoriesMapped: Repositories.map((data) => ({
          label: data.Name,
        })),
      });
      return Repositories;
    } catch (error) {
      console.log(error);
    }
  },

  async registerRepository(payload) {
    try {
      let registered = await RegisterRepository(payload);
      return registered;
    } catch (error) {
      console.log(error);
    }
  },

  async fetchRepositoryId(payload) {
    try {
      let repositoryInfo = await FetchRepositoryId(payload);
      return repositoryInfo;
    } catch (error) {
      console.log(error);
    }
  },

  async analyzeCode(payload) {
    try {
      set({ analyzingCode: true });
      let CodeReview = await AnalyzeCode(payload);
      set({
        analyzingCode: false,
      });
      return CodeReview;
    } catch (error) {
      console.log(error);
      set({ analyzingCode: false });
    }
  },

  async getReviewRegisterId(id) {
    try {
      let ReviewStatus = await GetReviewRegisterId(id);
      if (ReviewStatus.Status == RepoEvaluation.COMPLETED) {
        set({ analyzedResponse: ReviewStatus.Response });
      }
      return ReviewStatus;
    } catch (error) {
      console.log(error);
    }
  },

  resetAnalyzedResponse() {
    set({ analyzedResponse: null });
  },
}));
