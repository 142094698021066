import React, { useEffect, useState } from "react";
import { FaRegCopy } from "react-icons/fa6";
import { Link, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import Logo from "../../assets/png/logo-h-w.png";

import { usePeerSessionStore } from "../../store/peerSession/peerSession";

const PeerInvitation = ({ session }) => {
  const { getSessionInfo, currentSession } = usePeerSessionStore();

  const mockSessionData = {
    creator: "Alice Johnson",
    problemTitle: "Two Sum",
    problemDescription:
      "Given an array of integers, return indices of the two numbers such that they add up to a specific target.",
    problemLink: "/problems/two-sum",
    date: new Date(),
    instructions: [
      "Plan the Solution: Discuss the problem and plan how to approach it.",
      "Driver and Navigator: One writes the code while the other reviews and suggests.",
      "Switch Roles: Periodically switch roles to give both a chance.",
      "Communicate: Keep communication open throughout the session.",
    ],
  };

  const { date, instructions } = mockSessionData;

  const [copySuccess, setCopySuccess] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    fetchSessionInfo();
  }, []);

  let fetchSessionInfo = async () => {
    await getSessionInfo(id);
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(window.location.origin + "/peerInvitation/" + id)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      })
      .catch((err) => console.error("Error copying text: ", err));
  };

  const getRandomStyle = () => {
    const randomX = Math.random() * 100;
    const randomY = Math.random() * 100;
    const randomRotate = Math.random() * 360;

    return {
      transform: `translate(-50%, -50%) rotate(${randomRotate}deg)`,
      left: `${randomX}%`,
      top: `${randomY}%`,
    };
  };

  const handleStartHuddle = () => {
    // Redirect to the peer session room in a new tab
    window.open(window.location.origin + "/peerSession/" + id, "_blank");
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-[#191427] p-4 md:p-6">
      {/* Full-width header */}
      <header className="w-full bg-[#21183C] p-4 mb-4 shadow-md flex rounded justify-between items-center">
        <div className="flex items-center">
          <img src={Logo} alt="Logo" className="h-10" />
        </div>
      </header>

      {/* Main content */}
      <div className="flex-grow flex items-center justify-center">
        {currentSession ? (
          <div className="relative max-w-4xl w-full bg-gradient-to-r from-[#1E1E2F] to-[#3E1E5F] rounded-lg shadow-2xl p-6 overflow-hidden">
            {/* Background SVGs */}
            {[...Array(6)].map((_, index) => (
              <svg
                key={index}
                className="absolute opacity-20"
                style={getRandomStyle()}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
              >
                <circle
                  cx="50"
                  cy="50"
                  r="10"
                  fill="rgba(255, 255, 255, 0.1)"
                />
              </svg>
            ))}

            {/* Remaining content... */}
            <div className="flex flex-col md:flex-row justify-between items-center mb-4 relative z-10">
              <div className="text-white flex flex-col items-center mb-4 md:mb-0">
                <h1 className="text-4xl md:text-5xl">
                  {date.toLocaleDateString("en-US", { day: "numeric" })}
                </h1>
                <p className="text-sm">
                  {date.toLocaleDateString("en-US", { month: "long" })}
                </p>
              </div>
              <div className="flex flex-col items-end">
                <h3 className="text-2xl md:text-3xl font-semibold text-white">
                  {currentSession.Problems[0].Title}
                </h3>
                <p className="text-gray-300 italic capitalize">
                  Created by:{" "}
                  {`${currentSession.User.FirstName} ${
                    currentSession.User?.LastName || ""
                  }`}
                </p>
                <div className="flex items-center mt-2">
                  <input
                    type="text"
                    readOnly
                    value={window.location.origin + "/peerInvitation/" + id}
                    className="bg-transparent border border-gray-400 text-white rounded-md px-2 py-1 w-40 sm:w-60 mr-2"
                    aria-label="Copyable link"
                  />
                  <button
                    onClick={handleCopyLink}
                    className="text-white hover:opacity-80 transition duration-300"
                    aria-label="Copy link"
                  >
                    <FaRegCopy />
                  </button>
                </div>
                {/* Conditional visibility for the link copied message */}
                <span
                  className={`text-green-400 text-sm mt-1 transition-all duration-300 ${
                    copySuccess
                      ? "max-h-4 opacity-100"
                      : "max-h-0 opacity-0 overflow-hidden"
                  }`}
                >
                  Link copied!
                </span>
              </div>
            </div>

            <div className="mb-4">
              <h4 className="text-xl font-semibold text-white">
                Problem Description:
              </h4>
              <p className="text-gray-200">
                <ReactMarkdown
                  children={
                    currentSession?.Problems[0]?.Description.length > 150
                      ? currentSession?.Problems[0]?.Description.substring(
                          0,
                          150
                        ) + "..."
                      : currentSession?.Problems[0]?.Description
                  }
                />
              </p>
            </div>

            <div className="mb-6">
              <h4 className="text-xl font-semibold text-white">
                How Peer Programming Works:
              </h4>
              <ol className="list-decimal list-inside text-gray-200">
                {instructions.map((instruction, index) => (
                  <li key={index}>{instruction}</li>
                ))}
              </ol>
            </div>

            <div className="flex justify-end mt-auto relative z-10 gap-x-2">
              <Link
                to={
                  "/problems/" +
                  currentSession.Problems[0]._id +
                  "/?sessionId=" +
                  id
                }
                className="inline-flex items-center justify-center px-4 py-2 text-white bg-gradient-to-r from-[#B310FD] to-[#9D0ECB] rounded-md transition duration-300 shadow-lg transform hover:scale-105 hover:opacity-80"
              >
                Go to Problem
              </Link>
              <button
                onClick={handleStartHuddle}
                className="inline-flex items-center justify-center px-4 py-2 text-white bg-gradient-to-r from-[#B310FD] to-[#9D0ECB] rounded-md transition duration-300 shadow-lg transform hover:scale-105 hover:opacity-90 border-2 border-[#9D0ECB]"
              >
                Start Huddle
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default PeerInvitation;
