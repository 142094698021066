import React, { useEffect, useState } from 'react';
import LogoFull from '../../assets/png/logo-h-w.png';

const FeatureBanner = () => {
  const featureInfos = [
    {
      title: '1. Peer Programming _',
      subheading: 'Learn Together, Win Together',
      description:
        'Collaborate with peers in real time! With Peer Programming, you can strategize, solve Byte Battles, and work on tough challenges side-by-side with friends or new connections. Enjoy huddles and live code-syncing, creating a true community-driven learning experience.',
    },
    {
      title: '2. Realtime Code Review _',
      subheading: 'Instant Feedback for Instant Growth',
      description:
        'Transform your coding skills with Realtime Code Review. Get immediate insights from AI on your solutions, identify gaps, and sharpen your techniques. Collaborate, learn, and grow into a confident programmer 95% faster than traditional methods.',
    },
    {
      title: '3. Quick Bites _',
      subheading: 'Fast, Focused Learning',
      description:
        'Master complex topics with short, powerful lessons! Quick Bites gives you the chance to dive into Data Science, Python, Big Data, and 18+ other fields, broken into digestible chunks. Each lesson comes with an immediate quiz, so you can test your knowledge and strengthen your foundation on the spot.',
    },
    {
      title: '4. Byte Battle _',
      subheading: 'Level Up Through Coding Battles',
      description:
        "Put your skills to the test with Byte Battle, where coding challenges push your limits and track your growth. Compete, earn points, and see where you rank. It's all about real-world problem-solving, and winning here means getting sharper and stronger each day.",
    },
    {
      title: '5. Weekly Challenges _',
      subheading: 'Stay Motivated, Track Your Progress',
      description:
        'Stay on top of your goals with Weekly Challenges that inspire continuous growth. Set new targets each week, review your progress, and adjust to stay on track. Consistency is key, and this feature is here to help you build lasting skills and track your learning curve.',
    },
    {
      title: '6. HR Network _',
      subheading: 'Global Exposure to Hiring Managers',
      description:
        'Build your future with Discoursefy’s HR Network. Showcase your skills to top recruiters and gain priority access to exclusive opportunities. For our Campus Ambassadors, we offer even greater exposure and fast-track networking to help you stand out to recruiters worldwide.',
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % featureInfos.length);
        setFade(false);
      }, 500); // Duration of the fade out
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="authV2leftBanner hidden md:flex flex-col justify-between items-start rounded-[20px] overflow-hidden md:col-span-1 bg-[#191427] my-auto p-4">
      <div className="m-5">
        <img className="w-[266px]" src={LogoFull} alt="" />
      </div>
      <div
        className={`text-white mb-10 mx-5 transition-opacity duration-500 ${
          fade ? 'opacity-0' : 'opacity-100'
        }`}
      >
        <div className="text-[28px] font-semibold">
          {featureInfos[currentIndex].title}
        </div>
        <div className="text-[30px] font-normal text-[#9B75F9]">
          {featureInfos[currentIndex].subheading}
        </div>
        <div className="text-[16px] font-normal text-[#918D9A]">
          {featureInfos[currentIndex].description}
        </div>
      </div>
    </div>
  );
};

export default FeatureBanner;
