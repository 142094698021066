import { create } from 'zustand';
import {
  GetCreds,
  IsCredsRegistered,
  RegisterCred,
} from '../../service/identifier/identifier';

export const useIdentifierStore = create((set, get) => ({
  accessTokens: null,

  async registerCred(body) {
    await RegisterCred(body);
  },

  async isCredsRegistered(payload) {
    try {
      let IsRepositoryConnected = await IsCredsRegistered(payload);
      return IsRepositoryConnected?.Connected;
    } catch (error) {
      console.log(error);
    }
  },

  async getCreds(payload) {
    try {
      let IsRepositoryConnected = await GetCreds(payload);

      set((state) => ({
        accessTokens: {
          ...state.accessTokens,
          [payload['identifier']]: atob(
            IsRepositoryConnected.EncodedAccessToken
          ),
        },
      }));
      return atob(IsRepositoryConnected.EncodedAccessToken);
    } catch (error) {
      console.log(error);
    }
  },
}));
