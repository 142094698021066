import axios from 'axios';
import { Laptop } from 'lucide-react';
import React, { useCallback, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import CodeEditorWindow from './CodeEditor';
// import { classnames } from "../utils/general";
import { languageOptions } from '../../config/constants/languageOptions.js';

import { ToastContainer, toast } from 'react-toastify';
// import "react-toastify/dist/ReactToastify.css";

import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';
import {
  DescriptionTabs,
  SupportedCodeLanguagesMapping,
} from '../../config/constants/problem.js';
import { defineTheme } from '../../config/helpers/monacoTheme.js';
import { useQuery } from '../../hooks/useQuery.jsx';
import { baseUrl } from '../../service/index.js';
import { useAuthStore } from '../../store/auth/auth.js';
import { usePeerSessionStore } from '../../store/peerSession/peerSession.js';
import { useProblemStore } from '../../store/problem/problem.js';
import { useSolutionStore } from '../../store/solution/solution.js';
import { Description } from './Description.jsx';
import OutputWindow from './OutputWindow.jsx';
import useKeyPress from './useKeyPress.jsx';

const Problem = () => {
  let { account } = useAuthStore();
  let { getProblemById, currentProblem, setLanguageOptions, numberOfInputs } =
    useProblemStore();
  let { createSolution, currentSolution, getProblemsSolutions } =
    useSolutionStore();
  let { getSessionInfo, currentSession } = usePeerSessionStore();

  const [code, setCode] = useState();
  const [customInput, setCustomInput] = useState('');
  const [outputDetails, setOutputDetails] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [theme, setTheme] = useState('cobalt');
  const [language, setLanguage] = useState(null);
  const [defaultCode, setDefaultCode] = useState('');
  const [numberOfExecutions, setNumberOfExecutions] = useState(0);
  const [time, setTime] = useState(0);
  const [socket, setSocket] = useState(null);
  const [sessionId, setSessionId] = useState('');
  const [isDriver, setIsDriver] = useState(false);

  const [languages, setLanguages] = useState([]);
  const [customTestCases, setCustomTestCases] = useState([]);
  const [testCasesInput, setTestCasesInput] = useState([]);
  const [outputWidowTab, setOutputWidowTab] = useState(0);
  const [descriptionTab, setDescriptionTab] = useState(
    DescriptionTabs.DESCRIPTION
  );
  // const [testCases2, setTestCases2] = useState([
  //   { id: 1, inputs: [] },
  //   { id: 2, inputs: [] },
  // ]);

  let { id } = useParams();
  let query = useQuery();

  const enterPress = useKeyPress('Enter');
  const ctrlPress = useKeyPress('Control');

  const onSelectChange = (sl) => {
    setLanguage(sl);

    let boilerplate =
      currentProblem?.BoilerplateCode[SupportedCodeLanguagesMapping[sl.id]];
    setDefaultCode(boilerplate);
  };

  useEffect(() => {
    let isSessionOngoing = query.get('sessionId');
    setSessionId(isSessionOngoing);
    if (isSessionOngoing) {
      const newSocket = io(baseUrl);
      setSocket(newSocket);

      newSocket.on('connect', () => {
        newSocket.emit('message', { content: 'Hello from React Client' });
      });

      newSocket.on('syncCode', (data) => {
        setCode(() => data.code);
        setDefaultCode(() => data.code);
      });

      newSocket.on('switchRole', (data) => {
        setIsDriver((prev) => !prev);
        setSessionId(data.sessionId);
      });

      newSocket.emit('joinRoom', { sessionId: isSessionOngoing });

      // Cleanup function to prevent memory leaks and socket reconnections
      return () => {
        newSocket.disconnect();
        console.log('Socket disconnected');
      };
    }
  }, []);

  useEffect(() => {
    if (enterPress && ctrlPress) {
      handleCompile();
    }
  }, [ctrlPress, enterPress]);

  useEffect(() => {
    fetchProblem();

    return () => {};
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);

    return () => clearInterval(timer); // Cleanup on component unmount
  }, []);

  useEffect(() => {
    (async () => {
      if (query.get('sessionId') && account?._id) {
        let { Session } = await getSessionInfo(query.get('sessionId'));

        if (Session.CreatorId == account._id) {
          setIsDriver(true);
        }
      }
    })();
  }, [JSON.stringify(account)]);

  let fetchProblem = async () => {
    // let Problem = await getProblemById("6700051b62fff74ec809cada");
    //  670040fa62fff74ec809cb67
    let Problem = await getProblemById(id);
    let Solution = await getProblemsSolutions(id);

    setLanguageOptions(
      languageOptions.filter((data) =>
        Problem.LanguageIds.map((data) => parseInt(data)).includes(data.id)
      )
    );

    if (Solution) {
      let languageId = Solution.LanguageId;
      setLanguage(languageOptions.find((l) => l.id === languageId));
      setDefaultCode(atob(Solution?.Code));
      setCode(atob(Solution?.Code));
    } else {
      let initialLanguage = languageOptions.filter((data) =>
        Problem.LanguageIds.map((data) => parseInt(data)).includes(data.id)
      )[0];

      setLanguage(initialLanguage);
      setDefaultCode(Problem.BoilerplateCode.JavaScript);
      setCode(Problem.BoilerplateCode.JavaScript);
    }
  };

  const handleSwitchRole = () => {
    if (!sessionId) return;
    socket.emit('switchRole', {
      sessionId: sessionId,
    });
    setIsDriver((prev) => !prev);
  };

  const debouncedCodeUpdate = useCallback(
    debounce((data) => {
      socket.emit('codeUpdate', { code: data, sessionId: sessionId });
    }, 500),
    [socket, id]
  );

  const onChange = (action, data) => {
    switch (action) {
      case 'code': {
        setCode(data);
        if (sessionId) {
          debouncedCodeUpdate(data); // Use the debounced function
          // socket.emit("codeUpdate", {
          //   code: data,
          //   sessionId: id,
          // });
        }
        break;
      }
      default: {
        console.warn('case not handled!', action, data);
      }
    }
  };

  function handleInput(input) {
    try {
      // If the input is a string that looks like an array, fix the format and parse it
      if (typeof input === 'string') {
        // Replace single quotes with double quotes to make it valid JSON
        input = input.replace(/'/g, '"');
        return JSON.parse(input);
      }
      // If input is already an array, object, or number, return it as it is
      return input;
    } catch (e) {
      // If parsing fails, return the original string (it's not a JSON string)
      console.error('Failed to parse input:', input, e);
      return input;
    }
  }

  const testCasesOutput = () => {
    // Custom test cases are assumed to be present as an array of objects
    let inputs = customTestCases.map((testCase) => Object.values(testCase)); // Get the values of the object as an array

    setTestCasesInput(inputs);

    let codeBody = currentProblem.Code;
    // "{const maxProduct = words => {let bitMasks = []; for (let word of words) {let bitMask = 0; for (let char of word) {bitMask |= 1 << (char.charCodeAt(0) - 'a'.charCodeAt(0));} bitMasks.push(bitMask);} let maxProduct = 0; for (let i = 0; i < words.length; i++) {for (let j = i + 1; j < words.length; j++) {if ((bitMasks[i] & bitMasks[j]) === 0) {maxProduct = Math.max(maxProduct, words[i].length * words[j].length);}}} return maxProduct;}; return maxProduct(args[0]);}";
    // let codeBody = '';

    let fn = new Function('args', codeBody);

    let expectedOutputSequence = [];

    inputs.forEach((input) => {
      let response = input.map((data) => handleInput(data)); // Correctly handle and parse input
      let output = fn(response); // Call the function with parsed inputs
      expectedOutputSequence.push(output);
    });

    return expectedOutputSequence;
  };

  function addSpacesAroundArray(input) {
    // If the input is not an array, return it as is
    if (!Array.isArray(input)) {
      return input;
    }
    if (input.length === 0) return '[]';

    // Recursive formatting for arrays
    const formatElement = (element) => {
      return Array.isArray(element) ? addSpacesAroundArray(element) : element;
    };

    // Map each element in the array using the recursive formatter
    return `[ ${input.map(formatElement).join(', ')} ]`;
  }

  function extractFunctionName(code, language) {
    let functionName = null;

    if (language === 63) {
      // Regular expression to match function names in JavaScript (both function declarations and expressions)
      const functionRegex = /function\s+([a-zA-Z0-9_]+)\s?\(/;
      const arrowFunctionRegex = /([a-zA-Z0-9_]+)\s?=\s?\(.*\)\s?=>/;

      // Try matching a function declaration (function myFunction() { ... })
      const match = code.match(functionRegex);
      if (match) {
        functionName = match[1];
      } else {
        // If no match, check for arrow functions like myFunction = () => { ... }
        const arrowMatch = code.match(arrowFunctionRegex);
        if (arrowMatch) {
          functionName = arrowMatch[1];
        }
      }
    } else if (language === 71) {
      // Regular expression to match function names in Python (def my_function():)
      const functionRegex = /def\s+([a-zA-Z0-9_]+)\s?\(/;

      // Try matching a function definition in Python
      const match = code.match(functionRegex);
      if (match) {
        functionName = match[1];
      }
    }

    return functionName;
  }

  function getPrintFunction(language) {
    if (language === 63) {
      return 'console.log';
    } else if (language === 71) {
      return 'print';
    } else {
      throw new Error('Unsupported language');
    }
  }

  const handleCompile = (submission = false) => {
    let testCases;
    if (submission) {
      setProcessing(true);
      setTestCasesInput([]);
      testCases = currentProblem.ExecutionCode[
        SupportedCodeLanguagesMapping[language.id]
      ].map((data, index) => {
        let expectedOutput =
          currentProblem.TestCases[index].Expected[
            SupportedCodeLanguagesMapping[language.id]
          ];
        let runtimeCode;
        if (language.id !== 96) {
          runtimeCode = `${data.Prior}\n${code}\n${data.Post}`;
        } else {
          const index = code.length - 1;

          // Create the new string by slicing and concatenating
          runtimeCode = `\n${code.slice(0, index)}\n\n${
            data.Post
          }\n\n${code.slice(index)}`;
        }

        currentProblem.TestCases.forEach((data) => {
          setTestCasesInput((prev) => [...prev, data.Input]);
        });
        return {
          language_id: language.id,
          source_code: btoa(`${data.Prior}\n${code}\n${data.Post}`),
          expected_output: btoa(expectedOutput),
        };
      });
    } else {
      let inputs = customTestCases.map((testCase) => Object.values(testCase));
      let expectedOutputs = testCasesOutput();

      let formatOutput =
        language.id == 63
          ? expectedOutputs.map((data) => addSpacesAroundArray(data))
          : expectedOutputs.every((data) => typeof data === 'boolean')
          ? expectedOutputs.map((data) => (data === true ? 'True' : 'False'))
          : expectedOutputs.map((data) => {
              // Check if data is an array
              if (Array.isArray(data)) {
                // Format the array without spaces between elements
                return (
                  '[' + data.map((item) => JSON.stringify(item)).join(',') + ']'
                );
              }
              // Otherwise, convert the data to a JSON string
              return JSON.stringify(data);
            });

      setProcessing(true);

      let executionFnName = extractFunctionName(code, language.id);
      let fnPrintStatement = getPrintFunction(language.id);

      testCases = customTestCases.map((data, index) => {
        let expectedOutput = formatOutput[index];

        let runtimeCode;
        if (language?.id !== 96) {
          runtimeCode = `${
            data?.Prior || ''
          }\n${code}\n${`${fnPrintStatement}(${executionFnName}(${inputs[index]}))`}`;
        } else {
          const index = code.length - 1;

          // Create the new string by slicing and concatenating
          runtimeCode = `\n${code.slice(0, index)}\n\n${
            data.Post
          }\n\n${code.slice(index)}`;
        }
        return {
          language_id: language.id,
          source_code: btoa(runtimeCode), // `${data.Prior}\n${code}\n${data.Post}`
          expected_output: btoa(expectedOutput),
        };
      });
    }

    const formData = testCases;
    const options = {
      method: 'POST',
      url: 'https://judge0-ce.p.rapidapi.com/submissions/batch',
      params: { base64_encoded: 'true', fields: '*' },
      headers: {
        'content-type': 'application/json',
        'Content-Type': 'application/json',
        'X-RapidAPI-Host': 'judge0-ce.p.rapidapi.com',
        'X-RapidAPI-Key': '6c44894697msh73c36640e681dbap1ef69fjsne412ef5cca55',
      },
      data: { submissions: formData },
    };

    axios
      .request(options)
      .then(function (response) {
        let tokens = response.data.map((data) => `${data.token}`).join(',');
        checkStatus(tokens, submission);
      })
      .catch((err) => {
        let error = err.response ? err.response.data : err;
        setProcessing(false);
        console.log(error);
      });
  };

  const checkStatus = async (tokens, submission) => {
    const options = {
      method: 'GET',
      url: 'https://judge0-ce.p.rapidapi.com/submissions/batch',
      params: { base64_encoded: 'true', fields: '*', tokens: tokens },
      headers: {
        'X-RapidAPI-Host': 'judge0-ce.p.rapidapi.com',
        'X-RapidAPI-Key': '6c44894697msh73c36640e681dbap1ef69fjsne412ef5cca55',
      },
    };
    try {
      let response = await axios.request(options);
      let statusIds = response.data.submissions.map((data) => data.status?.id);
      // Processed - we have a result
      if (statusIds.includes(1) || statusIds.includes(2)) {
        // still processing
        setTimeout(() => {
          checkStatus(tokens, submission);
        }, 2000);
        return;
      } else {
        setProcessing(false);
        setNumberOfExecutions((prev) => prev + 1);
        if (submission === true) {
          let averageTime =
            response.data.submissions
              .map((data) => parseFloat(data.time))
              .reduce((a, b) => a + b, 0) / response.data.submissions.length;
          let averageMemory =
            response.data.submissions
              .map((data) => data.memory)
              .reduce((a, b) => a + b, 0) / response.data.submissions.length;
          let payload = {
            AccountId: account._id,
            ProblemId: id,
            LanguageId: response.data.submissions[0].language_id,
            Code: btoa(code),
            TimeToComplete: time.toFixed(3),
            Time: averageTime, // average time of test cases
            Memory: averageMemory, // average memory of test cases
            Token: tokens, // token of the submission
            NumberOfExecutions: numberOfExecutions + 1,
            PassedTestCases: response.data.submissions.filter(
              (data) => data.status.id == 3
            ).length,
            FailedTestCases: response.data.submissions.filter(
              (data) => data.status.id != 3
            ).length,
            Score:
              response.data.submissions.filter((data) => data.status.id == 3)
                .length / response.data.submissions.length,
          };
          await createSolution(payload);
          setDescriptionTab(DescriptionTabs.SOLUTION);
        }
        setOutputDetails(response.data.submissions);
        showSuccessToast(`Compiled Successfully!`);
        setOutputWidowTab(1);
        return;
      }
    } catch (err) {
      console.log('err', err);
      setProcessing(false);
      showErrorToast();
    }
  };

  function handleThemeChange(th) {
    // We will come to the implementation later in the code

    const theme = th;

    if (['light', 'vs-dark'].includes(theme.value)) {
      setTheme(theme);
    } else {
      defineTheme(theme.value).then((_) => setTheme(theme));
    }
  }
  useEffect(() => {
    defineTheme('oceanic-next').then((_) =>
      setTheme({ value: 'oceanic-next', label: 'Oceanic Next' })
    );
  }, []);

  const showSuccessToast = (msg) => {
    toast.success(msg || `Compiled Successfully!`, {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorToast = (msg) => {
    toast.error(msg || `Something went wrong! Please try again.`, {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="md:hidden w-full h-[100%] flex flex-col justify-center items-center text-white p-8">
        <Laptop className="w-16 h-16 mb-4 animate-pulse" />
        <h2 className="text-3xl font-bold mb-2 text-center">
          Connect Your Laptop
        </h2>
        <p className="text-lg text-center max-w-md">
          To get started, please connect your laptop to continue.
        </p>
      </div>
      {/* <div className="h-4 w-full bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500"></div> */}
      <div className="hidden md:grid grid-cols-7 w-[calc(100% - 70px)] ml-[25px]">
        <div className="col-span-2">
          <Description
            currentProblem={currentProblem}
            setDescriptionTab={setDescriptionTab}
            descriptionTab={descriptionTab}
          />
        </div>
        <div className="col-span-5 flex flex-col space-y-2 items-start px-4">
          <div className="flex flex-col w-full h-full justify-start items-end">
            <CodeEditorWindow
              code={code}
              onChange={onChange}
              language={language}
              defaultValue={defaultCode}
              theme={theme.value}
              onSelectChange={onSelectChange}
              role={sessionId ? (isDriver ? 'Driver' : 'Navigator') : ''}
            />
          </div>

          <div className="right-container flex flex-shrink-0 w-[100%] flex-col">
            <OutputWindow
              outputDetails={outputDetails}
              processing={processing}
              code={code}
              handleCompile={handleCompile}
              testCases={testCasesInput}
              isPeerSession={isDriver ? (sessionId ? true : false) : false}
              handleSwitchRole={handleSwitchRole}
              outputWidowTab={outputWidowTab}
              totalInputs={numberOfInputs}
              setCustomTestCases={setCustomTestCases}
              setOutputWidowTab={setOutputWidowTab}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Problem;
