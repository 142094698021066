import React, { useEffect } from 'react';
import { FaRegUser } from 'react-icons/fa6';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { QuizProfileCardType } from '../../config/constants/quizProfileCardType';
import {
  formatTime,
  inMinutes,
  monthsAndDaysFormat,
} from '../../config/helpers/moment-helper';
import { useAuthStore } from '../../store/auth/auth';
import { useQuizPerformanceStore } from '../../store/quizPerformance/quizPerformance';
import { useQuizProfileStore } from '../../store/quizProfile/quizProfile';

const QuizProfileCard = ({ type = QuizProfileCardType.QUICK_BITES }) => {
  let { getQuizProfile, quizProfile } = useQuizProfileStore();
  let { getQuizPerformanceReport, currentWeekReport } =
    useQuizPerformanceStore();
  let { account, user, getAccountInfo, userProfilePicture } = useAuthStore();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    fetchQuizProfile();
  }, []);

  let fetchQuizProfile = async () => {
    try {
      let startDate = searchParams.get('startDate');
      let endDate = searchParams.get('endDate');
      if (type == QuizProfileCardType.REPORT) {
        let duration = {
          StartDate: startDate || '',
          EndDate: endDate || '',
        };
        await getQuizPerformanceReport(duration);
      } else {
        await getQuizProfile();
      }
      // await getQuizProfile();
    } catch (error) {
      console.log(error);
    }
  };
  const calculateEfficiency = (correctAnswers, incorrectAnswers) => {
    const totalAnswers = correctAnswers + incorrectAnswers;
    if (totalAnswers === 0) return '0%'; // To avoid division by zero

    let efficiency = (correctAnswers / totalAnswers) * 100;
    // Check if the efficiency is a whole number
    if (Number.isInteger(efficiency)) {
      return efficiency + '%'; // Return as integer
    } else {
      return efficiency.toFixed(1) + '%'; // Return with one decimal
    }
  };
  // bg-[#21183C]
  return (
    <div className="relative rounded-md bg-[#21183C]  w-full h-full py-3 overflow-hidden pt-10">
      {/* Gradient Border */}
      {/* <div className="absolute inset-0 rounded-xl border-[3px] border-transparent bg-gradient-to-r from-purple-500 via-[#8254F8] to-purple-500 bg-clip-border"></div> */}

      {/* Card Content */}
      <div className="relative flex flex-col justify-between items-center text-white">
        {userProfilePicture ? (
          <div className="w-[120px] h-[120px] rounded-xl mx-auto overflow-hidden border-[3px] bg-gradient-to-r from-purple-500 via-[#8254F8] to-purple-500 bg-clip-border">
            <img className="w-full h-full" src={userProfilePicture} />
          </div>
        ) : (
          <div
            className={`w-[130px] h-[130px] flex justify-center items-center rounded-md bg-[#2A1C50] text-3xl`}
          >
            <FaRegUser className={`text-[50px]`} />
          </div>
        )}
        <div className="text-center mt-2 font-bold capitalize">
          {user?.FirstName}
        </div>
      </div>
      {type == QuizProfileCardType.QUICK_BITES ? (
        <div className="relative px-5 my-5 text-white">
          <div className="flex justify-between">
            <div className="text-sm flex items-center gap-x-1">
              Knockouts{' '}
              <span
                data-tooltip-id="knockout-info"
                data-tooltip-content="Sessions with 100% efficiency"
              >
                <Tooltip id="knockout-info" />
                <IoIosInformationCircleOutline className="text-md cursor-pointer" />
              </span>
            </div>
            <div className="text-xl lg:text-3xl font-bold">
              {quizProfile?.KnockOuts}
            </div>
          </div>
          <div className="flex justify-between my-2">
            <div className="text-sm">Correct Answers</div>
            <div className="text-xl lg:text-3xl font-bold">
              {quizProfile?.CorrectAnswers}
            </div>
          </div>
          <div className="flex justify-between my-2">
            <div className="text-sm">Incorrect Answers</div>
            <div className="text-xl lg:text-3xl font-bold">
              {quizProfile?.InCorrectAnswers}
            </div>
          </div>
          <div className="flex justify-between my-2">
            <div className="text-sm">Efficiency</div>
            <div className="text-xl lg:text-3xl font-bold">
              {quizProfile?.CorrectAnswers && quizProfile?.InCorrectAnswers
                ? calculateEfficiency(
                    quizProfile?.CorrectAnswers,
                    quizProfile?.InCorrectAnswers
                  )
                : '0'}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-sm">Total Time</div>
            <div className="text-xl lg:text-3xl font-bold">
              {quizProfile?.TotalTimeInSeconds &&
                formatTime(quizProfile?.TotalTimeInSeconds)}
            </div>
          </div>
        </div>
      ) : (
        <div className="relative flex flex-col px-5 my-5 text-white">
          <div className="flex justify-center items-end mb-3">
            {currentWeekReport?.StartDate && currentWeekReport?.EndDate ? (
              <div className="font-semibold italic text-[#918D9A]">
                {monthsAndDaysFormat(currentWeekReport.StartDate)} -{' '}
                {monthsAndDaysFormat(currentWeekReport.EndDate)}
              </div>
            ) : null}
          </div>

          <div className="flex flex-col gap-y-3">
            {[
              {
                label: 'Sessions',
                value: currentWeekReport?.TotalSessions,
                tooltip: 'Number of sessions you have planned',
                tooltipId: 'profile-card-total-sessions',
              },
              {
                label: 'Completed',
                value: currentWeekReport?.SessionsCompleted,
                tooltip: 'Number of sessions you have completed',
                tooltipId: 'profile-card-completed-sessions',
              },
              {
                label: 'Knockouts',
                value: currentWeekReport?.KnockOuts,
                tooltip: 'Sessions with 100% efficiency',
                tooltipId: 'knockout-info',
              },
              {
                label: 'Learning Time',
                value: `${inMinutes(
                  currentWeekReport?.TotalTimeOnLearning || 0
                )} Min`,
                tooltip: 'Your learning time',
                tooltipId: 'profile-card-learning-time',
              },
              {
                label: 'Committed',
                value: `${currentWeekReport?.TimeCommittedOnLearning || 0} Min`,
                tooltip: 'Time You have committed',
                tooltipId: 'profile-card-committed-time',
              },
              {
                label: 'Correct',
                value: currentWeekReport?.CorrectAnswers,
                tooltip: 'Correct answers',
                tooltipId: 'profile-card-correct-answers',
              },
              {
                label: 'Incorrect',
                value: currentWeekReport?.InCorrectAnswers,
                tooltip: 'Incorrect answers',
                tooltipId: 'profile-card-incorrect-answers',
              },
              { label: 'Efficiency', value: currentWeekReport?.Efficiency },
            ].map((item, index) => (
              <div key={index} className="flex justify-between items-center">
                <div className="flex items-center gap-x-1 text-[#918D9A] text-xs font-normal lg:text-sm">
                  {item.label}{' '}
                  {item.tooltip && (
                    <span
                      data-tooltip-id={item.tooltipId}
                      data-tooltip-content={item.tooltip}
                    >
                      <Tooltip id={item.tooltipId} />
                      <IoIosInformationCircleOutline className="text-[#918D9A] text-sm cursor-pointer hover:text-gray-200 transition-colors" />
                    </span>
                  )}
                </div>
                <div className="text-lg lg:text-2xl font-bold text-white">
                  {item.value}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

QuizProfileCard.propTypes = {};

export { QuizProfileCard };
