import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { Separator } from "../Separator/Separator";
import { Select } from "../Select/Select";
import { useQuizCategoriesStore } from "../../store/quizCategory/quizCategory";
import { Tag } from "../Tag/Tag";
import { Button } from "../Button/Button";
import { Input } from "../Input/Input";
import { useAuthStore } from "../../store/auth/auth";
import { useQuizPerformanceStore } from "../../store/quizPerformance/quizPerformance";
import { IoClose } from "react-icons/io5";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    background: "#191427",
    border: "none",
    width: "450px",
    maxWidth: "1260px",
    borderRadius: "10px",
    zIndex: 100,
  },
  overlay: {
    background: "rgba(0,0,0,0.5)",
  },
};

const customMobileStyles = {
  content: {
    top: "50%",
    left: "62px",
    right: "auto",
    bottom: "auto",
    transform: "translate(0%, -50%)",
    background: "#191427",
    border: "none",
    width: "calc(100% - 72px)",
    maxWidth: "1260px",
    borderRadius: "10px",
    zIndex: 100,
  },
  overlay: {
    background: "rgba(0,0,0,0.5)",
  },
};

const PlanningModal = ({ isOpen, handleCloseModal }) => {
  let { quizCategoriesDDOptions, fetchQuizCategories } =
    useQuizCategoriesStore();
  let { createQuizChallenge, getQuizChallenges, challengesReport } =
    useQuizPerformanceStore();
  let { account } = useAuthStore();

  let [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [screen, setScreen] = useState(1);
  const [sessionCounts, setSessionCounts] = useState(13);
  const [dailyCommitments, setDailyCommitments] = useState(15);
  const [importanceOfLearning, setImportanceOfLearning] = useState(7);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!quizCategoriesDDOptions.length) {
        await fetchQuizCategories();
      }
    })();
  }, []);

  //   let handleEndSession = async () => {
  //     setLoading(true);
  //     await handleEndMeeting();
  //     handleCloseModal();
  //     setLoading(false);
  //   };

  function getScreenWidth() {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }

  let getScreenStyle = () => {
    if (getScreenWidth() > 768) {
      return customStyles;
    } else {
      return customMobileStyles;
    }
  };

  const filterColors = (inputValue = "") => {
    if (!inputValue.length) {
      return quizCategoriesDDOptions;
    }
    return quizCategoriesDDOptions.filter((data) =>
      data.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptions = (inputValue, callback) => {
    // setTimeout(() => {
    // }, 1000);
    callback(filterColors(inputValue));
  };

  const handleMaintainCategories = (skill) => {
    let isSkillExist = categories.find(
      (data) => data.label.toLowerCase() == skill.label.toLowerCase()
    );
    if (!isSkillExist) {
      setCategories((prev) => [...prev, skill]);
    } else {
      setCategories((prev) =>
        prev.filter((data) => data.label !== skill.label)
      );
    }
  };

  const handleNext = async () => {
    if (screen == 4) {
      let payload = {
        AccountId: account._id,
        Categories: categories.map((data) => data.value),
        NumberOfSessions: sessionCounts,
        TimeYouCommit: dailyCommitments,
        ImportantOfGrowth: importanceOfLearning,
      };
      setLoading(true);
      try {
        await createQuizChallenge(payload);
        if (!challengesReport.length) {
          await getQuizChallenges();
        }
        navigate("/");
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }

      setCategories([]);
      setSessionCounts(13);
      setDailyCommitments(15);
      setImportanceOfLearning(7);
      setScreen(1);

      handleCloseModal(false);
      return;
    }
    setScreen(screen + 1);
  };
  const handlePrevious = () => {
    setScreen(screen - 1);
  };

  const checkIsButtonDisabled = () => {
    if (screen == 1 && categories.length == 0) {
      return true;
    }
    if (screen == 2 && sessionCounts < 13) {
      return true;
    }
    if (screen == 3 && dailyCommitments < 15) {
      return true;
    }
    return false;
  };

  return (
    <ReactModal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      style={getScreenStyle()}
      contentLabel="Example Modal"
      parentSelector={() => document.querySelector("#root")}
    >
      <div className="text-white">
        <div className="flex justify-between items-center">
          <div className="text-lg font-semibold">Set Your Challenge </div>
          <div
            className="text-xl cursor-pointer"
            onClick={() => handleCloseModal(false)}
          >
            <IoClose />
          </div>
        </div>
        <div className="my-2">
          <Separator />
        </div>
        {screen == 1 && (
          <div className="my-3 flex flex-col gap-y-3">
            <div className="text-md leading-3">Select Categories</div>
            <div className="text-xs">
              Which topics are you excited to focus on this week?
            </div>
            <div className="mt-2">
              <Select
                defaultOptions={filterColors("")}
                loadOptions={loadOptions}
                handleAttendees={handleMaintainCategories}
                disabled={categories.length >= 2}
              />
            </div>
            {categories.length > 0 && (
              <div className="text-sm text-gray-400">
                <div className="flex items-center gap-x-2">
                  {categories.map((data) => (
                    <span
                      data-tooltip-id="planning-modal-select-categories"
                      data-tooltip-content="Click To Remove"
                    >
                      <Tooltip id="planning-modal-select-categories" />
                      <Tag
                        text={data.label}
                        selectedTab={""}
                        onClick={() =>
                          setCategories((prev) =>
                            prev.filter((item) => item.label !== data.label)
                          )
                        }
                        classes="text-xs text-white"
                      />
                    </span>
                  ))}
                </div>
              </div>
            )}
            <div className="text-xs">
              Boost your progress by selecting 1-2 key topics to focus on this
              week. Discoursefy tracks your growth, providing valuable insights
              that help you stand out to recruiters. By concentrating on
              specific areas, you’ll showcase dedication and
              adaptability—qualities that employers highly value. Ready to make
              an impact? Pick your top topics and take the next step toward
              success!
            </div>
          </div>
        )}

        {screen == 2 && (
          <div className="my-3 flex flex-col gap-y-3">
            <div className="text-md leading-3">
              Set Your Weekly Session Goal
            </div>
            <div className="text-xs">
              {" "}
              How many sessions will you complete this week?
            </div>
            <div className="mt-2">
              <Input
                type="number"
                focus={true}
                value={sessionCounts}
                onChange={(e) => setSessionCounts(e.target.value)}
              />
              {sessionCounts < 13 && (
                <div className="text-red-600 text-xs italic">
                  Come on, aim for 13!
                </div>
              )}
            </div>
            <div className="text-xs">Suggestions</div>
            <div className="flex justify-start items-center gap-x-2">
              <Tag
                text={"13 sessions"}
                selectedTab={`${sessionCounts} sessions`}
                onClick={() => setSessionCounts(13)}
                classes="text-xs"
              />
              <Tag
                text={"18 sessions"}
                selectedTab={`${sessionCounts} sessions`}
                onClick={() => setSessionCounts(18)}
                classes="text-xs"
              />
            </div>
            <div className="text-xs">
              Consistency is everything. Aim to complete at least 13 sessions
              this week. Discoursefy tracks your progress and delivers insights
              that catch the eye of recruiters. By setting realistic goals, you
              demonstrate your dedication and adaptability. Choose your target
              and stay focused on success!
            </div>
          </div>
        )}

        {screen == 3 && (
          <div className="my-3 flex flex-col gap-y-3">
            <div className="text-md leading-3">Daily Time Commitment</div>
            <div className="text-xs">
              {" "}
              How much time can you commit to learning each day?
            </div>
            <div className="mt-2">
              <Input
                type="number"
                value={dailyCommitments}
                focus={true}
                onChange={(e) => setDailyCommitments(e.target.value)}
              />
              {dailyCommitments < 15 && (
                <div className="text-red-600 text-xs italic">
                  Come on, aim for 15 minutes!
                </div>
              )}
            </div>
            <div className="text-xs">Suggestions</div>
            <div className="flex justify-start items-center gap-x-2">
              <Tag
                text={"15 minutes"}
                selectedTab={`${dailyCommitments} minutes`}
                onClick={() => setDailyCommitments(13)}
                classes="text-xs"
              />
              <Tag
                text={"18 minutes"}
                selectedTab={`${dailyCommitments} minutes`}
                onClick={() => setDailyCommitments(18)}
                classes="text-xs"
              />
            </div>
            <div className="text-xs">
              Consistent learning habits reflect your commitment to personal
              growth. By dedicating at least 15 minutes a day, you showcase to
              recruiters your ability to manage time and stay focused on
              short-term goals—key qualities they value. The more you invest in
              yourself, the faster you'll stand out. Your consistency is a
              powerful metric, proving your dedication to success!
            </div>
          </div>
        )}

        {screen == 4 && (
          <div className="my-3 flex flex-col gap-y-3">
            <div className="text-md leading-3">
              Importance Of Daily Learning
            </div>
            <div className="text-xs">
              {" "}
              How important daily learning is to you?
            </div>
            <div className="mt-2">
              <Input
                type="number"
                value={importanceOfLearning}
                min={1}
                max={10}
                onChange={(e) =>
                  e.target.value > -1 &&
                  e.target.value < 11 &&
                  setImportanceOfLearning(e.target.value)
                }
              />
              <div className="text-white text-xs italic">
                Rate from (1 - 10)
              </div>
            </div>
            <div className="text-xs">
              Your dedication to daily learning reflects how much personal
              growth and self-improvement matter to you. This daily commitment
              demonstrates to recruiters that you value discipline and skill
              enhancement—key soft skills they look for. By consistently
              prioritizing your own development, you show HRs that ongoing
              growth is important to you, making you stand out as a driven and
              self-motivated professional.
            </div>
          </div>
        )}

        <div className="my-2">
          <Separator />
        </div>

        <div className="flex justify-end items-center gap-x-2 mt-3">
          {screen > 1 && (
            <Button
              buttonText={"Previous"}
              classes="text-xs py-1 px-3"
              onClick={handlePrevious}
            />
          )}
          <Button
            disabled={checkIsButtonDisabled() || loading}
            isActive={!checkIsButtonDisabled()}
            buttonText={screen == 4 ? "Submit" : "Next"}
            classes="text-xs py-1 px-3"
            onClick={handleNext}
            isDisabled={loading}
          />
        </div>
      </div>
    </ReactModal>
  );
};

PlanningModal.propTypes = {};

export { PlanningModal };
