import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import { changeFormat } from '../../config/helpers/moment-helper';
import { useQuizReportStore } from '../../store/quizAnswer/quizAnswer';
import StreakCard from '../StreakCard/StreakCard';

let dummyObject = [
  {
    day: '2024-11-02',
    activity: 1,
    timeSpentInMinutes: 1,
    _id: '2024-11-02',
    count: 1,
    totalTimeSpent: 0.3,
  },
  {
    day: '2024-11-14',
    activity: 2,
    timeSpentInMinutes: 14,
    _id: '2024-11-14',
    count: 2,
    totalTimeSpent: 13.7,
  },
];

const streaksInitialState = [
  {
    icon: '🔥',
    title: '',
    count: null,
    isCurrentStreakActive: false,
    todaysResponseCollected: true,
  },
  {
    icon: '🔥',
    title: '',
    count: null,
    isCurrentStreakActive: false,
    todaysResponseCollected: true,
  },
  {
    icon: '🔥',
    title: '',
    count: null,
    isCurrentStreakActive: false,
    todaysResponseCollected: true,
  },
  {
    icon: '🔥',
    title: '',
    count: null,
    isCurrentStreakActive: false,
    todaysResponseCollected: true,
  },
  {
    icon: '🔥',
    title: '',
    count: null,
    isCurrentStreakActive: false,
    todaysResponseCollected: true,
  },
  {
    icon: '🔥',
    title: '',
    count: null,
    isCurrentStreakActive: false,
    todaysResponseCollected: true,
  },
  {
    icon: '🔥',
    title: '',
    count: null,
    isCurrentStreakActive: false,
    todaysResponseCollected: true,
  },
  {
    icon: '🔥',
    title: '',
    count: null,
    isCurrentStreakActive: false,
    todaysResponseCollected: true,
  },
  {
    icon: '🔥',
    title: '',
    count: null,
    isCurrentStreakActive: false,
    todaysResponseCollected: true,
  },
  {
    icon: '🔥',
    title: '',
    count: null,
    isCurrentStreakActive: false,
    todaysResponseCollected: true,
  },
  // ... Add more streaks as needed
];

const StreakCardList = () => {
  let { getStreakCount, getActivityRecord, activityRecord, streak } =
    useQuizReportStore();

  const [streaks, setStreaks] = useState([...streaksInitialState]);

  useEffect(() => {
    fetchStreak();
  }, [activityRecord?.length]);

  let fetchStreak = async () => {
    // let Streak = await getActivityRecord(); // Fetch activity records
    // Ensure Streak is sorted by date ascending
    if (!activityRecord.length) {
      return;
    }
    let Streak = activityRecord;
    const sortedStreaks = Streak.sort(
      (a, b) => new Date(a.day) - new Date(b.day)
    );

    // Find the last active day
    const lastActiveDay = sortedStreaks.length
      ? moment(sortedStreaks[sortedStreaks.length - 1].day)
      : moment(); // Default to today if no streak data

    // Build the final streaks array
    const finalStreaks = [];

    // First six days (backtracking from lastActiveDay)
    for (let i = 6; i > 0; i--) {
      const dayToCheck = lastActiveDay
        .clone()
        .subtract(i, 'days')
        .format('YYYY-MM-DD');
      const foundStreak = sortedStreaks.find(
        (streak) => streak.day === dayToCheck
      );

      finalStreaks.push({
        day: changeFormat(dayToCheck, 'DD'),
        month: changeFormat(dayToCheck, 'MMM'),
        activity: foundStreak ? foundStreak.activity : 0,
        isCurrentStreakActive: !!foundStreak,
        todaysResponseCollected: false,
      });
    }

    // 7th box (last active day)
    finalStreaks.push({
      day: changeFormat(lastActiveDay.format('YYYY-MM-DD'), 'DD'),
      month: changeFormat(lastActiveDay.format('YYYY-MM-DD'), 'MMM'),
      activity: sortedStreaks[sortedStreaks.length - 1]?.activity || 0,
      isCurrentStreakActive: true,
      todaysResponseCollected: true,
    });

    // Next three days (future empty boxes)
    for (let i = 1; i <= 3; i++) {
      const futureDay = lastActiveDay
        .clone()
        .add(i, 'days')
        .format('YYYY-MM-DD');
      finalStreaks.push({
        day: changeFormat(futureDay, 'DD'),
        month: changeFormat(futureDay, 'MMM'),
        activity: 0,
        isCurrentStreakActive: false,
        todaysResponseCollected: false,
      });
    }

    setStreaks(finalStreaks); // Update the state
  };

  return (
    <>
      {streaks.map((streak, index) => (
        <StreakCard
          key={index}
          activity={streak.activity}
          streakIcon={streak.icon}
          streakTitle={streak.day}
          month={streak.month}
          streakCount={streak.count}
          isCurrentStreakActive={streak.isCurrentStreakActive}
          todaysResponseCollected={streak?.todaysResponseCollected}
        />
      ))}
    </>
  );
};
export default StreakCardList;
