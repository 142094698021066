import { create } from 'zustand';
import {
  CreateSolution,
  GetAISuggestion,
  GetProblemsSolutions,
} from '../../service/solution/solution';

export const useSolutionStore = create((set, get) => ({
  currentSolution: null,
  solutionResponse: null,
  aiSuggestion: null,
  fetchingAIFeedback: false,

  resetSolutionResponse: () => {
    set((state) => ({ solutionResponse: null }));
  },

  resetAISuggestionResponse: () => {
    set((state) => ({ aiSuggestion: null }));
  },

  async createSolution(data) {
    try {
      let Solution = await CreateSolution(data);
      set((state) => ({
        solutionResponse: {
          ...data,
          comparison: Solution.performanceComparison,
        },
      }));
      return Solution;
    } catch (error) {
      console.log(error);
    }
  },

  async getProblemsSolutions(problemId) {
    try {
      let Solution = await GetProblemsSolutions(problemId);
      set((state) => ({ currentSolution: Solution }));
      return Solution;
    } catch (error) {
      console.log(error.message);
    }
  },

  async getAISuggestion() {
    try {
      const { solutionResponse, aiSuggestion } = get();
      console.log(aiSuggestion, solutionResponse);
      if (aiSuggestion) {
        return aiSuggestion;
      }
      let data = {
        Code: solutionResponse?.Code,
      };
      set((state) => ({ fetchingAIFeedback: true }));
      let suggestion = await GetAISuggestion(data);

      set((state) => ({ aiSuggestion: suggestion, fetchingAIFeedback: false }));
      return suggestion;
    } catch (error) {
      console.log(error.message);
    }
  },

  async regenerateAISuggestions() {
    try {
      const { solutionResponse } = get();
      let data = {
        Code: solutionResponse?.Code,
      };
      set((state) => ({ fetchingAIFeedback: true }));
      let suggestion = await GetAISuggestion(data);

      console.log(suggestion);
      set((state) => ({ aiSuggestion: suggestion, fetchingAIFeedback: false }));
    } catch (error) {
      console.log(error.message);
    }
  },
}));
