import React from 'react';
import StreakBrightFull from '../../assets/png/StreakBrightFull.png';
import StreakBrightHalf from '../../assets/png/StreakBrightHalf.png';
import StreakBrightLow from '../../assets/png/StreakBrightLow.png';

const StreakCard = ({
  month,
  activity,
  streakIcon,
  streakTitle,
  streakCount,
  isCurrentStreakActive,
  todaysResponseCollected,
}) => {
  // bg-gray-900
  return (
    <div
      className={`md:bg-transparent lg:bg-[#2A1C50] text-white h-[102px] min-w-[60px] rounded-3xl flex flex-col items-center shadow-lg py-3 transform transition-transform `}
    >
      <div className="text-center text-xs">{month}</div>

      <div
        className={`text-xl flex flex-grow justify-center items-center w-[43px] h-[50px] rounded-full ${
          isCurrentStreakActive ? 'text-[#E25822]' : 'text-gray-400'
        }  `}
      >
        {/* <span>{streakIcon}</span> */}
        <img
          className="relative"
          src={
            activity > 2
              ? StreakBrightFull
              : activity > 0
              ? StreakBrightHalf
              : StreakBrightLow
          }
        />
        <span className="absolute text-sm text-white font-semibold bottom-6 flex justify-center items-center">
          {streakTitle}
        </span>
      </div>
    </div>
  );
};

export default StreakCard;
