import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MeetingPreviewModal } from "../../components/MeetingPreviewModal/MeetingPreviewModal";
import { MeetingSession } from "../../components/MeetingSession/MeetingSession";
import { usePeerSessionStore } from "../../store/peerSession/peerSession";
import { useParams } from "react-router-dom";
import { Header } from "../../components/Header/Header";

const PeerSession = (props) => {
  let { getSessionInfo, isSessionExpired } = usePeerSessionStore();
  let [state, setState] = useState(1);

  const { id } = useParams();

  useEffect(() => {
    fetchSessionInfo();
  }, []);

  let fetchSessionInfo = async () => {
    await getSessionInfo(id);
  };

  let handleNext = async () => {
    let resp = await isSessionExpired(id);
    if (!resp.isSessionFailed) {
      setState(2);
    } else {
      setState(3);
    }
  };

  const [sessionFeatures, setSessionFeatures] = useState([
    "video",
    "audio",
    "users",
    "chat",
    "share",
    "settings",
  ]);
  return (
    <div className="h-[100vh] flex justify-center items-center">
      <Header />
      <div className="w-[100%] grid grid-cols-4 my-auto">
        {state === 1 ? (
          <>
            <div className="col-span-1"></div>
            <div className="col-span-2">
              <MeetingPreviewModal handleNext={handleNext} />
            </div>
            <div className="col-span-1"></div>
          </>
        ) : state === 2 ? (
          <>
            <div className="col-span-1"></div>
            <div className="col-span-2">
              <MeetingSession
                handleClose={() => setState(4)}
                features={sessionFeatures}
              />
            </div>
            <div className="col-span-1"></div>
          </>
        ) : state === 3 ? (
          <>
            <div className="col-span-1"></div>
            <div className="col-span-2 flex flex-col items-center bg-[#191427] p-6 md:p-10">
              <h2 className="text-3xl font-bold text-white mb-4">
                Session Full
              </h2>
              <div className="max-w-3xl bg-gradient-to-r from-[#1E1E2F] to-[#3E1E5F] rounded-lg shadow-2xl p-6">
                <p className="text-gray-200 text-lg leading-relaxed">
                  This peer programming session is currently limited to two
                  participants. Please try joining a different session or start
                  a new one with your peer for a focused, collaborative
                  experience.
                </p>
              </div>
            </div>
            <div className="col-span-1"></div>
          </>
        ) : (
          <>
            <div className="col-span-1"></div>
            {/* as meetings get end tell something about Discoursefy */}
            <div className="col-span-2 flex flex-col items-center bg-[#191427] p-6 md:p-10">
              <h2 className="text-3xl font-bold text-white mb-4">
                About Discoursefy
              </h2>
              <div className="max-w-3xl bg-gradient-to-r from-[#1E1E2F] to-[#3E1E5F] rounded-lg shadow-2xl p-6">
                <p className="text-gray-200 text-lg leading-relaxed mb-4">
                  Discoursefy’s mission is to empower students to connect their
                  skills with real-world career opportunities. By offering
                  targeted learning modules, hands-on peer programming sessions,
                  and gamified skill assessments, Discoursefy helps students
                  track their progress and improve continuously.
                </p>
                <p className="text-gray-200 text-lg leading-relaxed mb-4">
                  Our data-driven approach makes it easy for students to
                  highlight their abilities to potential employers, emphasizing
                  consistency, skill growth, and readiness for future roles.
                </p>
                <p className="text-gray-200 text-lg leading-relaxed">
                  Discoursefy isn’t just a platform to learn—it’s a place to
                  build a professional path that aligns with each student’s
                  unique strengths and ambitions.
                </p>
              </div>
              <div className="col-span-1"></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

PeerSession.propTypes = {};

export { PeerSession };
