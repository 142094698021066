import {
  ArrowBigLeft,
  Brain,
  Check,
  CheckCircle,
  ChevronDown,
  ChevronRight,
  Code,
  File,
  FileDown,
  Folder,
} from 'lucide-react';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { IdentifierType } from '../../config/constants/identifiers';
import { RepoEvaluationMapping } from '../../config/constants/repositoryReviewer';
import {
  fetchFileContent,
  fetchFolderContents,
  fetchRootLevelContents,
  fetchUserInfo,
} from '../../config/helpers/github-helper';
import { useIdentifierStore } from '../../store/identifier/identifier';
import { useRepositoriesStore } from '../../store/repositories/repositories';
import ProfileReview from './ProfileReview';

const iconMap = {
  1: FileDown,
  2: Code,
  3: Brain,
  4: CheckCircle,
};
export default function Repository() {
  let { getCreds, accessTokens } = useIdentifierStore();
  let {
    fetchRepositoryId,
    analyzedResponse,
    analyzingCode,
    analyzeCode,
    getReviewRegisterId,
    resetAnalyzedResponse,
  } = useRepositoriesStore();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [openFile, setOpenFile] = useState(null);
  const [expandedFolders, setExpandedFolders] = useState([]);
  const [gitHubFileStructure, setGitHubFileStructure] = useState(null);
  const [repositoryStack, setRepositoryStack] = useState([]);
  const [fileContent, setFileContent] = useState('');
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [loadingFolder, setLoadingFolder] = useState(false);
  const [reviewState, setReviewState] = useState(0);

  let { id } = useParams();

  useEffect(() => {
    resetAnalyzedResponse();
    fetchGithubCreds();
  }, []);

  let fetchGithubCreds = async () => {
    try {
      setLoadingFolder(true);
      let repositoryInfo = await fetchRepositoryId(id);
      let payload = {
        identifier: IdentifierType.GITHUB,
      };
      let creds = await getCreds(payload);
      // let repository = await fetchRepositories(creds);

      let userInfo = await fetchUserInfo(creds);

      let { rootLevelContent, url } = await fetchRootLevelContents(
        userInfo.login,
        repositoryInfo.Name,
        creds
      );
      setRepositoryStack((prev) => [...prev, { Content: url, Label: 'Main' }]);
      let formatStructure = rootLevelContent.map((data) => ({
        name: data.name,
        type: data.type,
        downloadUrl: data.download_url,
        url: data.url,
        sha: data.sha,
      }));
      setGitHubFileStructure(formatStructure);
      setLoadingFolder(false);
    } catch (error) {
      setLoadingFolder(false);
    }
  };

  const toggleFileSelection = (file) => {
    let isFileAlreadyThere = selectedFiles.find(
      (data) => data.downloadUrl === file.downloadUrl
    );

    if (isFileAlreadyThere) {
      setSelectedFiles((prev) =>
        prev.filter((data) => data.downloadUrl !== file.downloadUrl)
      );
    } else {
      setSelectedFiles((prev) => [...prev, file]);
    }
  };

  const openFileContent = async (file) => {
    let fileContent = await fetchFileContent(
      file.downloadUrl,
      accessTokens[IdentifierType.GITHUB]
    );
    setFileContent(fileContent);
    setOpenFile(file.name);
  };

  const toggleFolder = async (url, back = false) => {
    setLoadingFolder(true);
    let Object = gitHubFileStructure.find((data) => data.url == url);

    let folderContent = await fetchFolderContents(
      url,
      accessTokens[IdentifierType.GITHUB]
    );
    setGitHubFileStructure(
      folderContent.map((data) => ({
        name: data.name,
        type: data.type,
        downloadUrl: data.download_url,
        url: data.url,
        sha: data.sha,
      }))
    );
    if (back) {
      setLoadingFolder(false);
      return;
    }
    setRepositoryStack((pre) => [...pre, { Content: url, Label: Object.name }]);
    setLoadingFolder(false);
  };

  async function handleBackFunctionality() {
    if (repositoryStack.length == 1) {
      return;
    }
    let lastElement = repositoryStack[repositoryStack.length - 2];
    repositoryStack.pop();
    await toggleFolder(lastElement.Content, true);
  }

  function handleName(name) {
    if (name.length > 15) {
      return (
        <div data-tooltip-id="repo-file-name" data-tooltip-content={name}>
          <Tooltip id="repo-file-name" />
          {name.slice(0, 15) + '...'}
        </div>
      );
    } else {
      return name;
    }
  }

  const Icon = iconMap[4];

  let fetchResponseInfo = (id) => {
    let status = 1;
    let ResponsePooling = setInterval(async () => {
      let response = await getReviewRegisterId(id);
      setReviewState(response.Status);
      status = response.Status;
      if (status == 4) {
        clearInterval(ResponsePooling);
        setTimeout(() => {
          setReviewState(0);
          setOpenReviewModal(true);
        }, 2000);
      }
    }, 5000);
  };

  let handleCodeAnalyze = async () => {
    try {
      let InitiateCodeReview = await analyzeCode({
        fileUrls: selectedFiles.map((data) => data.downloadUrl),
        repositoryId: id,
      });
      if (!InitiateCodeReview.RegisteredReview) {
        setReviewState(0);
        return;
      }
      setReviewState(1);
      fetchResponseInfo(InitiateCodeReview?.RegisteredReview?._id);
    } catch (error) {
      setReviewState(0);
      console.log(error);
    }
  };

  const renderFileTree = (items, path = '') => {
    if (!items) {
      return;
    }
    return items.map((item) => {
      const itemPath = path;
      if (item.type === 'dir') {
        const isExpanded = expandedFolders.includes(path);
        return (
          <li key={itemPath}>
            <div
              className="flex items-center py-1 px-2 hover:bg-gray-700 cursor-pointer"
              onClick={() => toggleFolder(item.url)}
            >
              {isExpanded ? (
                <ChevronDown size={18} className="mr-1" />
              ) : (
                <ChevronRight size={18} className="mr-1" />
              )}
              <Folder size={18} className="mr-2" />
              <span>{handleName(item.name)}</span>
            </div>
            {isExpanded && item.children && (
              <ul className="ml-4">
                {renderFileTree(item.children, item.url)}
              </ul>
            )}
          </li>
        );
      } else {
        return (
          <li
            key={itemPath}
            className="flex items-center justify-between py-1 px-2 hover:bg-gray-700"
          >
            <div
              className="flex items-center cursor-pointer"
              onClick={() => openFileContent(item)}
            >
              <File size={18} className="mr-2" />
              <span>{handleName(item.name)}</span>
            </div>
            <button
              onClick={() => toggleFileSelection(item)}
              className="p-1 rounded hover:bg-gray-600"
            >
              {selectedFiles.find(
                (data) => data.downloadUrl == item.downloadUrl
              ) ? (
                <Check size={18} />
              ) : (
                <div className="h-[18px] w-[18px]"></div>
              )}
            </button>
          </li>
        );
      }
    });
  };

  let FileStructureLoader = () => {
    return (
      <>
        <Skeleton width={120} height={25} />
        <Skeleton width={180} height={25} />
        <Skeleton width={120} height={25} />
        <Skeleton width={190} height={25} />
        <Skeleton width={110} height={25} />
        <Skeleton width={120} height={25} />
        <Skeleton width={190} height={25} />
        <Skeleton width={110} height={25} />
        <Skeleton width={120} height={25} />
        {/* {renderFileTree(gitHubFileStructure)} */}
      </>
    );
  };

  return (
    <>
      {analyzedResponse ? (
        <ProfileReview
          aggregatedResult={analyzedResponse}
          isOpen={openReviewModal}
          onRequestClose={() => setOpenReviewModal(false)}
        />
      ) : (
        <></>
      )}
      <div className="grid grid-cols-5 h-[calc(100vh-60px)] gap-x-5 mx-5 bg-[#191427] text-white">
        <div className="col-span-1 rounded-md flex flex-col h-full">
          {/* Top container: Repository Files (60% height) */}
          <div className="flex flex-col h-[calc(60vh-60px)]">
            <div className="p-4 flex-shrink-0">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold">Repository Files</h2>
                <ArrowBigLeft
                  onClick={handleBackFunctionality}
                  className={`cursor-pointer ${
                    repositoryStack.length < 2 ? 'text-gray-500' : 'text-white'
                  }`}
                />
              </div>
            </div>
            <div className="flex-grow overflow-hidden">
              <ul className="h-full space-y-1 overflow-y-auto scroll-bar p-4">
                {loadingFolder ? (
                  <FileStructureLoader />
                ) : (
                  <>{renderFileTree(gitHubFileStructure)}</>
                )}
              </ul>
            </div>
          </div>

          {/* Bottom container: Selected Files (40% height) */}
          <div className="flex flex-col h-[calc(40vh-60px)]">
            <div className="p-4 flex-shrink-0">
              <h2 className="text-xl font-semibold">
                Selected Files({selectedFiles.length})
              </h2>
            </div>
            <div className="flex-grow overflow-hidden">
              <ul className="h-full space-y-1 overflow-y-auto scroll-bar p-4">
                {selectedFiles.map((data, index) => (
                  <li
                    key={data.downloadUrl}
                    className="flex items-center justify-between py-1 px-2 hover:bg-gray-700"
                  >
                    {/* onClick={() => openFileContent(item.name)} */}
                    <div
                      onClick={() => openFileContent(data)}
                      className="flex items-center cursor-pointer"
                    >
                      <File size={18} className="mr-2" />
                      <span>{handleName(data.name)}</span>
                    </div>
                    <button
                      onClick={() => toggleFileSelection(data)}
                      className="p-1 rounded hover:bg-gray-600"
                    >
                      {selectedFiles.includes(data) ? (
                        <Check size={18} />
                      ) : (
                        <div className="h-[18px] w-[18px]"></div>
                      )}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Right content area */}
        <div className="col-span-4 rounded-md">
          <div className="px-4">
            {reviewState > 0 ? (
              <div className="flex flex-col items-center justify-center h-[80vh] bg-[#191427] text-[#f6f6f6]">
                <div className="flex items-center gap-4">
                  <Icon className="w-12 h-12 animate-pulse" />
                  <span className="text-4xl font-bold tracking-wide animate-pulse">
                    {RepoEvaluationMapping[reviewState]}
                  </span>
                </div>
                {/* {1 !== 4 && (
                  <div className="mt-8 flex gap-4">
                    {[0, 1, 2].map((i) => (
                      <div
                        key={i}
                        className="w-4 h-4 bg-[#9B75F9] rounded-full animate-bounce"
                        style={{
                          animationDelay: `${i * 0.2}s`,
                          animationDuration: '1s',
                        }}
                      />
                    ))}
                  </div>
                )} */}
                <div className="mt-12 flex gap-4">
                  {Object.keys(RepoEvaluationMapping).map((step) => {
                    const StepIcon = iconMap[step];
                    return (
                      <div
                        key={step}
                        className={`flex flex-col items-center transition-all duration-300 ${
                          Number(step) <= Number(reviewState)
                            ? 'text-[#9B75F9]'
                            : 'text-[#21183C]'
                        }`}
                      >
                        <StepIcon
                          className={`w-8 h-8 ${
                            Number(step) === Number(reviewState)
                              ? 'animate-bounce'
                              : ''
                          }`}
                        />
                        <div
                          className={`mt-2 h-1 w-16 ${
                            Number(step) < Number(reviewState)
                              ? 'bg-[#9B75F9]'
                              : 'bg-[#21183C]'
                          }`}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : openFile ? (
              <div className="relative flex flex-col h-full justify-between">
                <div className="border-[0.5px] border-gray-800 rounded-md flex-grow">
                  <h2 className="text-xl font-semibold m-4">{openFile}</h2>
                  <pre className="rounded-lg px-4 py-4 max-w-4/5 scroll-bar h-[calc(78vh-60px)] overflow-x-auto overflow-y-auto">
                    <code>{fileContent}</code>
                  </pre>
                </div>

                <div className="absolute bottom-[-60px] right-0 flex gap-x-2 justify-end items-center mb-4">
                  {analyzedResponse ? (
                    <button
                      onClick={() => setOpenReviewModal(true)}
                      className={`${
                        analyzedResponse
                          ? 'bg-[#703BF7] text-white hover:scale-105'
                          : 'bg-[#353440] text-[#f6f6f6]'
                      } px-6 h-[37.5px] py-auto rounded-md ease-in-out transition duration-300`}
                    >
                      Response
                    </button>
                  ) : (
                    <></>
                  )}
                  <button
                    onClick={handleCodeAnalyze}
                    disabled={!selectedFiles.length || analyzingCode}
                    className={`
    px-6 h-[37.5px] rounded-md ease-in-out transition duration-300
    flex items-center justify-center
    ${
      selectedFiles.length
        ? 'bg-[#703BF7] text-white hover:scale-105'
        : 'bg-[#353440] text-[#f6f6f6]'
    }
    ${analyzingCode ? 'cursor-not-allowed opacity-70' : ''}
  `}
                  >
                    {analyzingCode ? (
                      <div className="flex items-center">
                        <svg
                          className="animate-spin h-5 w-5 mr-2 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Analyzing...
                      </div>
                    ) : (
                      'Analyze'
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex gap-y-4 flex-col items-center justify-center h-[80vh] text-gray-400">
                <span> Select a file to view its contents </span>
                <button
                  onClick={handleCodeAnalyze}
                  disabled={!selectedFiles.length || analyzingCode}
                  className={`
    w-[250px] h-[37.5px] rounded-md ease-in-out transition duration-300
    flex items-center justify-center
    ${
      selectedFiles.length
        ? 'bg-[#703BF7] text-white hover:scale-105'
        : 'bg-[#353440] text-[#f6f6f6]'
    }
    ${analyzingCode ? 'cursor-not-allowed opacity-70' : ''}
  `}
                >
                  {analyzingCode ? (
                    <div className="flex items-center">
                      <svg
                        className="animate-spin h-5 w-5 mr-2 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Analyzing...
                    </div>
                  ) : (
                    'Analyze'
                  )}
                </button>
                {analyzedResponse ? (
                  <button
                    onClick={() => setOpenReviewModal(true)}
                    className={`${
                      analyzedResponse
                        ? 'bg-[#703BF7] text-white hover:scale-105'
                        : 'bg-[#353440] text-[#f6f6f6]'
                    }  w-[250px]  h-[37.5px] py-auto rounded-md ease-in-out transition duration-300`}
                  >
                    Response
                  </button>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
