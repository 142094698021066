import React from 'react';
import { Repositories as RepositoriesComponent } from '../../components/Repositories/Repositories';
const Repositories = (props) => {
  return (
    <div className="max-w-[1280px] mx-auto">
      <RepositoriesComponent />
    </div>
  );
};

Repositories.propTypes = {};

export { Repositories };
